@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';

.alertContainer {
  width: 100%;
  height: 30px;
  position: fixed;
  z-index: 9999999999;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  display: flex;

  .alert {
    padding: 0 15px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .alertIcon {
    width: 10px;
    margin-right: 10px;
  }

  .alertTitle {
    font-family: $GTRegular;
    color: $black;
    font-size: 13px;
  }
}