@import 'FreelancerItem/index';
@import './src/assets/styles/colors';

// TODO copy to client page
.customSelectDrop{
  height: 40px;
  width: 110px;
  .multiselect-header{
    height: 40px;
  }
  .isOpen{
    width: 200px;
  }
  .input-checkbox{
    z-index: 5;
    cursor: pointer;
  }
}

// TODO copy to client page
.customSelectDropView{
  height: 40px;
  .multiselect-header{
    height: 40px;
  }
  .isOpen{
    width: 200px;
  }
}

.assign-freelancer-container {
  @include width-container(1172px);
  margin-bottom: 100px;


  .freelancer-title {
    line-height: 2;
    font-size: 15px;
    text-align: center;
    margin-bottom: 41px;
    padding-top: 40px;
    color: $gray-black;
    border-top: 1px solid $gray-3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
  }

  .freelancer-item  {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  .custom-dropdown {
    width: 100%;
    border: 1px solid $gray-2;
  }

  .isOpen {
      max-height: 175px;
  }
}

.freelancer-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}
