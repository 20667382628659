.language-tab-container {
    width: 100%;
    background-color: $white;
    padding: 50px 40px 50px 50px;
    margin-top: 10px;

    .languages-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;


        .language-item {
            width: 302px;
            height: 40px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            background-color: $gray-3;
            margin-bottom: 10px;
            margin-right: 10px;

            .language-title {
                color: $black;
                font-size: 15px;
                font-weight: normal;
                font-family: $GTMedium;
            }
            
        }
    }

    .add-new-language-button {
        @include button();
        @extend .gray-button;
        height: 30px;
        width: fit-content;
        min-width: 63px;
        padding: 0 15px;
        background-color: $gray-3;
        color: $gray-black;
        font-size: 13px;
      
        img {
          width: 10px;
          height: 10px;
          margin-right: 9px;
          filter: brightness(50%);
          margin-top: 1px;
        }
    }

    .language-modal-field {
        width: 340px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .language-modal-title {
            color: $gray-black;
            font-size: 15px;
            margin-bottom: 10px;
        }

        .language-modal-input, .custom-dropdown {
            width: 100%;
            height: 40px;
            color: $black;
            border: 1px solid $gray-2;
            padding-left: 15px;
        }

        .custom-items {
            max-height: 132px;

            .custom-checkbox, .form-check, .checkbox-svg {
                max-width: 320px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .additional-elements {
                svg {
                    width: 40px;
                }
            }
        }
        
        .language-modal-error {
            color: $pink;
            font-size: 13px;
            margin-top: 10px;
        }
    }
    
    .modal-error {
        color: $pink;
        font-size: 15px;
        margin-bottom: 15px;
    }
}