@import "ProjectDetailsContent/index";
@import "NotFoundMessage/index";
@import '../../mixins/index';
@import './src/assets/styles/mixins/responsive';

.project-details .close-button-modal-overlay .modal{
  position: relative;
  width: fit-content;
  @include media(mobile){
    padding: 0 20px;
    width: 100%;
  }
}

.project-details .tabs-wrap {
  background-color: $gray-14;
  padding: 0 60px;
}

.project-details,
.brainstorm-details {
  .tab-content {
    @include width-container(1340px);
    padding: 0 40px 74px;
    background-color: $white;
    border-radius: 6px;
    @include media(mobile){
      padding: 0 20px 74px;
    }
  }
  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }
  .design-tab {
    padding-right: 5px;
  }

  .tab-items {
    padding: 19px 38px 20px 39px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 38px;
      right: 0;
      height: 2px;
      background-color: $yellow-1;
      opacity: 0;
      transition: opacity .3s;
      width: calc(100% - 77px);
    }

    &:before {
      background-color: transparent;
    }
  }
}

.brainstorm-details {
  .brainstormButtonsTabsItem {
    margin-top: 0;
  }
}

.evaluation-project-details {
  .tabs-wrap {
    //max-width: 1320px;
    margin: 0 auto;
  }
  .tab-content {
    max-width: unset;
    padding: 0 60px 74px;
    @include media(mobile){
      padding: 0 20px 74px;
    }

    .tab-pane {
      margin: 0 auto;
    }
  }
}

.app-container {
  &__info-block {
    margin: 110px auto;
    padding: 42px;
    width: 1140px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
  }

  &__preloader {
    width: 50px;
    height: 50px;
  }
}

.controls-wrapper {
  display: flex;
  @include media(mobile){
    margin-bottom: 20px;
  }
  &::before {
    content: '';
    height: 40px;
    width: 1px;
    margin: 0 30px;
    background-color: #e7e7e7;
    @include media(mobile){
      display: none;
    }
  }
  button {
    padding: 9px 30px 12px;
    border: none;
    border-radius: 8px;
    margin-right: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $GTMedium;
    font-weight: 500;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }
  .approve-btn {
    color: #000;
    background-color: #82e190;
  }
  .disapprove-btn {
    color: #fff;
    background-color: #e77074;
  }
}

.typeSectionItem > .typeSectionContent {
  display: flex;
  justify-content: space-between;

  &.withTellMore {
    flex-wrap: nowrap;
    column-gap: 10px;
    flex: 1;
  }

  .tellMoreButton {
    flex-shrink: 0;
    height: 40px;
    align-self: flex-start;
    border: none;
    background: none;
    text-decoration: underline;
    font-family: $GTRegular;
    font-size: 13px;
    line-height: 2;
    letter-spacing: -0.26px;
    color: $gray-16;
    cursor: pointer;
  }
}

.Briefing__projectTypeBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &-grayItem {
    @include grayItem;
  }

  &-yellowItem{
    @include yellowItem;
  }
}

.editing-wrapper{
  color: gray;

  & .editing-field{
    border: none;
    padding: 10px;
    font-size: 15px;
    border-radius: 0;
    margin-right: 10px;
    background-color: rgb(245, 245, 245);
    border-radius: 6px;
    margin-bottom: 10px;
  }
}


