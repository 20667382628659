.reviews-filter {
    margin: 30px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-3;

    &__filters-list {
        padding-bottom: 10px;

        display: grid;
        grid-template-columns: repeat(5, 219px) 40px;
        column-gap: 20px;
    }

    &__filter-item {
        width: 100%;
        
        & > div:first-child {
            border-radius: 6px;
            height: 40px;
        }
        & > div:last-child {
            width: 100%;
            max-width: unset;
            max-height: 280px;
        }

        &_date-dropdown {
            & > div:last-child {
                width: 451px;
                max-width: unset;
                overflow: unset;
                overflow-y: unset;
                
                &::before {
                    content: "";
                    display: block;
                    width: 232px;
                    height: 1px;
                    background-color: $gray-2;
                    margin-left: auto;
                }
            }
        }
    }

    .custom-items, &__filter-item .body {
        // TODO use mixins
        top: 50px;
        padding: 10px;
        padding-right: 0;
        border: solid 1px #dfdfdf;
        border-radius: 6px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        
        &::-webkit-scrollbar {
            width: 16px;
            padding: 0 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: $white;
        }
                        
        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: #dfdfdf;
            max-height: 68px;
            min-height: 40px;
            width: 6px;
            border: 5px solid #ffffff;
            cursor: pointer;
        }

        &>div {
            border-radius: 6px;
        }
    }

    &__labels {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        
        .filter-label {
            border-radius: 4px;
            min-height: 30px;
        }
    }

    &__download-button {
        padding: 0;
        background-color: $gray-3;
        border: none;
        border-radius: 6px;  
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        & > svg {
            width: 14px;
            height: 14px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

