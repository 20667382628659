.platform-container {
    @include width-container(1340px);
    background-color: $white;
    padding: 50px 40px 50px 50px;
    margin-top: 10px;

    .platform-rows {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .platform-inputs-row {
        width: 100%;
        display: grid;
        grid-template-columns: 28% 28% 28% 9% 6%;
        grid-template-areas: "title priceType price recurring delete";
        margin-bottom: 10px;

        .platform-input {
            height: 40px;
            width: 330px;
            padding: 0 10px;
            color: $black;
            border: solid 1px $gray-2;
            position: relative;
        }

        .platform-price-type-input {
            height: 40px;
            width: 330px;
            color: $black;
            border: solid 1px $gray-2;
            position: relative;
        }

        .custom-dropdown {
            width: 100%;
        }

        .custom-items {
            width: 330px;
        }

        .platform-price-input {
            height: 40px;
            width: 330px;
            padding: 0 10px;
            color: $black;
            border: solid 1px $gray-2;
            position: relative;

            input {
                height: 100%;
                border: none;
                margin-left: 10px;
                color: $black; 
                width: 300px;                           
            }
        
            &:before {
                position: absolute;
                content: "\20AC";
                height: 5px;
                top: 8px;
                left: 10px;
                color: $gray;
            }
        }

        .platform-input-error {
            color: $pink;
            font-size: 10px;
            margin-top: 3px;
        }
    }

    .add-new-service-button {
        @include button();
        @extend .gray-button;
        height: 30px;
        width: fit-content;
        min-width: 63px;
        padding: 0 11px 0 7px;
        background-color: $gray-3;
        color: $gray-black;
        font-size: 13px;
        margin-top: 6px;
      
        img {
          width: 10px;
          height: 10px;
          margin-right: 9px;
          filter: brightness(50%);
          margin-top: 1px;
        }
    }

    .platform-title {
        grid-area: title;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .platform-price-type {
        grid-area: priceType;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .platform-price {
        grid-area: price;
        width: 100%;
        display: flex;
        flex-direction: column;
    }  
    
    .platform-recurring {
        grid-area: recurring;
        width: 100%;
        display: flex;
        flex-direction: column;

        .service-checkbox {
            margin-bottom: 0;
            margin-top: 8px;
        }
    }
    
    .platform-delete {
        grid-area: delete;
        display: flex;
        margin-top: 10px;
        border: none;
        background-color: transparent;

        &:hover {
            span {
                visibility:visible;
                width: 15px;
                height: 15px;
                margin-left: 40px;
            }
        }

        span {
            visibility: hidden;
            color: $black;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}
