.editor-wrapper {
    border: 1px solid $gray-2;
}
.editor {
    min-height: 100px;
    padding: 0 10px;
}
.rdw-editor-main {
    height: auto;
}
