.summary-container{
  @include width-container(600px);

  .detailed-outline-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .summary-overflow-text {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.fullHeight {
      line-height: normal;
      .summary-value {
        white-space: normal;
        line-height: 2;
      }
    }
  }

  .summary-step-title{
    text-align: center;
    font-size: 15px;
    line-height: 2;
    margin: 0px 0 38px;
    padding-top: 40px;
    color: $gray;
    border-top: 1px solid $gray-3;
  }

  .error-message {
    text-align: center;
    font-size: 14px;
    color: $pink;
    margin-bottom: 10px;
  }

  .border-top {
    border-top: 1px solid $gray-3;
  }

  .briefing-summary{
    border-bottom: 1px solid $gray-3;
    //line-height: 60px;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .detailed-outline-wrap, .flex {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .summary-label {
    width: 184px;
    flex-shrink: 0;
    margin-right: 106px;
    color: $gray-black;
    font-size: 15px;
    line-height: 20px;
  }

  .summary-documents {
    .summary-value-files {
      line-height: 22px;
      width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .summary-value {
    color: $black;
    font-size: 15px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &-input {
      border: none;
      background: transparent;
    }

    a {
      color: inherit;
      text-decoration: none;
      line-height: 2;
    }
  }

  .arrowUp {
    margin-top: 3px;
    transform: rotate(180deg);
  }
  .summary-arrow {
    margin-top: 5px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
  }
  .hideArrow {
    display: none;
  }
  .errors-container {
    margin-bottom: 40px;
  }
}


