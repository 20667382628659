.update-loader-container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(255,255,255,.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    .loader-container {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: $white;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
  
      .loader {
        border: 3px solid  $white;
        border-top: 3px solid $yellow-1;
        border-left: 3px solid $yellow-1;
        border-right: 3px solid $yellow-1;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
      }
    
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      } 
    }
  
    span {
      margin-top: 11px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.67;
      color: $black;
      font-family: $GTMedium;
    }
  }