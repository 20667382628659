$header-height : 70px;

.header {
  background-color: $white;
  height: $header-height;
  border-bottom: 1px solid $gray-3;
  //padding: 0 60px;

  .width-container {
    width: 100%;
    position: relative;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-bottom: 1px solid $gray-17;
    margin-bottom: 20px;
  }

  .logo {
    max-width: 24px;
    left: 0;
    position: absolute;
  }

  .navigation-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: $header-height;
    position: relative;
  }

  .navigation-item {
    position: relative;
    font-size: 18px;
    font-family: $GTMedium;
    color: $black;
    text-transform: lowercase;
    text-decoration: none;
    margin: 0 25px;
    padding: 0;

    &:before {
      transition: opacity, background-color .3s;
      content: "";
      display: block;
      height: 2px;
      margin: auto;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &:hover:before {
      background-color: $yellow-1;
      opacity: 1;
    }

    &.active:before {
      background-color: $yellow-1;
      opacity: 1;
    }

    &-admin {
      position: relative;
      margin-left: auto;
      &-block {
        &:hover {
          cursor: pointer;
        }
      }
      &-dropdown {
        display: none;
        position: absolute;
        right: 0;
        left: 11px;
        width: 220px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border: 1px solid $gray-2;
        border-radius: 6px;
        z-index: 999;
      }
      &:hover > .navigation-item-admin-dropdown {
        display: flex;
      }
    }
  }

  .user-info-and-new-project {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    right: 0;
  }

  .user-drop-down {
    display: none;
    position: absolute;
    top: 54px;
    right: -10px;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $gray-2;
    border-radius: 6px;
    z-index: 1000;
    padding: 10px;
    min-width: 185px;
  }

  .drop-down-item, .admin-drop-down-item {
    text-align: left;
    line-height: 45px;
    text-transform: capitalize;
    text-decoration: none;
    color: $black;
    font-size: 15px;
    transition: color .3s;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 5px;
    border-radius: 6px;

    &:hover {
      color: $black;
      background-color: $gray-3;
    }
  }

  .admin-drop-down-item {
    padding-left: 5px;
    line-height: 45px;
    &:not(:last-child) {
      border-bottom: transparent;
    }
    &:hover {
      color: $black;
      background-color: $gray-3;
    }
    &-disabled {
      pointer-events: none;
      color: $gray-black;
    }
  }

  .new-project {
    @include button(160px, 44px);
    @extend .yellow-button;
    margin-right: 40px;
    line-height: 0;
    img {
      width: 10px;
      height: 10px;
      margin-right: 19px;
      filter: brightness(0);
    }
  }

  .need-help-link{
    margin-right: 30px;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: -0.26px;
    color: $gray-black;
  }

  .user-info {
    position: relative;

    &.active {
      .user-drop-down {
        display: flex;
      }

      .caret-down {
        transform: rotate(180deg);
      }
    }
  }

  .show-drop-down {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    outline: 0;
    font-size: 15px;
    color: $gray-black;
    cursor: pointer;
    padding: 0;
    position: relative;
    line-height: $header-height;

    &:before {
      transition: opacity, background-color .3s;
      content: "";
      display: block;
      height: 3px;
      margin: auto;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &:hover {
      &:before {
        background-color: $gray-5;
        opacity: 1;
      }
      .user-name {
        color: $black;
      }
    }

    &.active {
      &:before {
        background-color: $yellow;
        opacity: 1;
      }
      .user-name {
        color: $black;
      }
    }
  }

  .avatar {
    @include avatar(38px, 38px);
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .user-name {
    text-transform: capitalize;
    margin-right: 15px;
    color: $gray-black;
  }

  .caret-down {
    width: 8px;
    fill: $gray-black;
  }

  .german-lan {
    text-transform: none;
  }
}

.new-header {
  padding: 0 60px;
  background-color: $gray-14;
  width: 100%;
  .width-container {
    max-width: initial;
    margin-bottom: 20px;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      height: 1px;
      width: 100%;
      background-color: $black;
      opacity: 0.6;
      position: absolute;
      bottom: 0;
    }

    .navigation-item {
      padding: 0;
      margin: 0 25px;

      &:before {
        top: auto;
        bottom: 0px;
      }

    }
  }
  .logo {
    position: absolute;
    left: 0;
  }
  .user-info-and-new-project {
    position: absolute;
    right: 0;

    .user-info {
      .avatar {
        margin: 0;
      }
      .user-name {
        display: none;
      }
      .caret-down {
        display: none;
      }
    }

    .new-project {
      margin-right: 10px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
	    align-items: center;
      box-shadow: none;

      img {
        margin: 0;
      }
    }
  }
}
