@import './AdminPlanSettings/index';
@import './RatePlansSettings/index';
@import './FreelancersRatePlans/index';
@import './ClientPlanSettings/index';

.financial-tabs {
  display: flex;

  .financial-tab-items {
    text-decoration: none;
    font-family: $GTMedium;
    cursor: pointer;
    font-size: 15px;
    padding: 0 40px;
    height: 60px;
    color: $gray-black;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    background-color: transparent;
    transition: all .3s;
    z-index: 1;

    &:hover {
      color: $black;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 3px;
      background-color: $yellow;
      opacity: 0;
      transition: opacity .3s;
    }

    &.active{
      &:before {
        opacity: 1;
      }

      background-color: $white;
      color: $black;
    }
  }
}

.project-item, .projects-filter, .user-profile-financials-body {
  .modal .modal-structure-body {
    .info-tooltip-container {
      top: 2px;
      .info-tooltip-icon {
        border-radius: 0;
        padding: 0 2px;
        border: 1px solid #cccccc;
      }
      .info-tooltip-content{
        top:-160px;
        border-color: black transparent transparent transparent;
        background-color: #000000;
        color: #ffffff;
        border-radius: 4px;
        border-width: 5px;
        border-style: solid;
        font-size: 15px;
        overflow-wrap: anywhere;
        left:0 !important;

      }
    }
    .modal-buttons-left {
      display: block;
      .cancel-button{
        display: inline-block;
      }
      .delete-correction{
        vertical-align: bottom;
        color: $gray-black;
        text-decoration: underline;
        margin-left: 15px;
        line-height: 30px;
        background: none;
        border: none;
        cursor: pointer;
      }
    }
    .commitment-euro {
      color: #c6c6c6;
    }
    textarea.full-hight {
      height: 100%;
      border-radius: 6px;
    }
    .multiselect-dropdown{
      width: 100%;
    }
    hr.devider {
      opacity: 0.1;
    }
    .service-item-input {
      &:before {
        border:none;
      }
    }
    .plan-options {
      font-size: 15px;
      font-weight: normal;
    }
    .radio-button input:checked + span {
      background-color: #ffe403;
    }
  }
}

.financial-filtes-graphics-container {
    .projects-filter {
        width: 100%;

      .modal .multiselect-dropdown {
        width: 100%;
      }
      .modal .modal-structure-body textarea.form-controls {
        height: 100%;
      }
        .filter-label {
            border-radius: 6px;
            height: 40px;
        }

        .filters-area {
          display: flex;
          justify-content: space-between;
        }

      .filters-list {
          padding-bottom: 10px;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          column-gap: 20px;
        }

        .multiselect-dropdown {
            border-radius: 6px;
            width: 219px;
            border: none;
        }

        .filter-item {
            min-width: 219px;
            margin-top: 10px;
            border-radius: 6px;

            .body {
                border-radius: 6px;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                top: 100%;
                width: 100%;
                overflow-y: auto;
                overflow: hidden;

                & > hr{
                  margin: 12px 10px;
                  border-top: 1px solid $gray-3;
                }

                &::-webkit-scrollbar {
                    width: 16px;
                    padding: 0 10px;
                }

                &::-webkit-scrollbar-track {
                    background-color: $white;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 20px;
                    background-color: $gray-2;
                    max-height: 68px;
                    min-height: 40px;
                    width: 6px;
                    border: 5px solid $white;
                    cursor: pointer;
                }

                .custom-checkbox:hover {
                    border-radius: 6px;
                }
            }

            .custom-checkbox {
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    border-radius: 6px;
                }
            }

            & > div:first-child {
                height: 40px;
                border-radius: 6px;
            }

            & > div:last-child {
                width: 100%;
                max-width: unset;
                max-height: 225px;
                margin-top: 10px;
                overflow: hidden;
                overflow-y: auto;
            }
      }

      // TODO remove, looks useless
      .date-dropdown-open {
        width: 450px;
      }

      .filter-date-dropdown, .date-selector-container {
          min-width: 219px;
          margin-right: 20px;
          margin-top: 10px;
          border-radius: 6px;

        .multiselect-header {
          height: 40px;
        }

          & > div:last-child {
              width: 450px;
              max-width: unset;
              overflow: unset;
              overflow-y: unset;
              margin-top: 10px;

              &::before {
                  content: "";
                  display: block;
                  width: 232px;
                  height: 1px;
                  background-color: $gray-2;
                  margin-left: auto;
              }
          }
      }

      .filter-labels {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
      }
    }

    .project-graphic-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;
        height: 200px;
        background-color: $gray-3;
        margin-top: 20px;
        border-radius: 6px;

        .project-graphic-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 228px;
            height: 138px;
            background-color: $white;
            max-width: 228px;
            border-radius: 6px;

            .graphic-value {
                font-size: 20px;
                font-weight: bold;
                overflow-x: hidden;
                max-width: 180px;
                max-height: 30px;
                overflow-y: hidden;
                white-space: nowrap;
            }

            .graphic-devider {
                width: 20%;
                border-top: 1px solid cyan;
                margin: 13px 0;
            }

            .graphic-title-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .completion-icon {
                    width: 16px;
                    height: 16px;
                    border: 1px solid $gray-black;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 7px;
                        height: 7px;

                        path {
                            fill: $gray-black;
                        }
                    }
                }

                svg {
                    width: 15px;
                    height: 15px;
                    margin-left: 1px;

                    path {
                        fill: $gray-black;
                    }
                }

                .graphic-title {
                    color: $gray-black;
                    font-size: 14px;
                    margin-left: 7px;
                }
            }
        }
    }
}

.projects-tab {
  width: 100%;
  background-color: $white;
  padding: 30px 50px 50px 50px;
  margin-top: 10px;
  border-radius: 6px;

  .preloader {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .project-item {
        position: relative;
        border-top: 1px solid $gray-3;
        width: 100%;

        .project-item-info {
            min-height: 40px;
        }

        &:hover {
            cursor: pointer;
            background-color: $gray-3;
        }

        .projects-item-table-grid {
          color: $black;
          text-decoration: none;
            display: grid;
            grid-template-columns: repeat(9, 1fr);
            align-items: center;
            position: relative;

          .correction-project-code {
            p,span {
              color: #ff9751;
            }
          }

            .with-marker {
                p {
                    padding-left: 23px;
                }
            }

            .projects-list-item {
                padding: 30px 15px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                font-size: 15px;
                height: 100%;
                overflow-wrap: anywhere;
                position: relative;

                a {
                    color: $black;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
              &-code {
                padding: 30px 10px 30px 0;
              }
              &-marker {
                position: absolute;
                width: 14px;
                img {
                    border-radius: 2px;
                }
                &-tooltip {
                  position: relative;
                  visibility: hidden;
                  &-text {
                    position: absolute;
                    width: 200px;
                    top: -55px;
                    transform: translateX(-46.5%);
                    z-index: 9999;
                    text-align: center;
                    padding: 5px 0;
                    color: $white;
                    background-color: $black;
                    border-radius: 4px;
                    &::after{
                      content: '';
                      position: absolute;
                      top:100%;
                      left: 50%;
                      margin-left: -5px;
                      border-width: 5px;
                      border-style: solid;
                      border-color: black transparent transparent transparent;
                    }
                  }
                }
                &-fee {
                    min-width: 15px;
                }
                &-discount {
                //   top: 50px;
                }
                img {
                  width: 15px;
                  height: 15px;
                }
              }

              .warning-marker {
                margin-left: 6px;

                .warning-icon {
                    height: 13px;
                    width: 13px;
                    background-color: $orange-2;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 9px;
                    border-radius: 2px;
                }

                .warning-tooltip {
                  position: relative;
                  visibility: hidden;

                  .warning-text {
                    position: absolute;
                    width: 208px;
                    top: -20px;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    //top: -53px;
                    //transform: translateX(-46.5%);
                    z-index: 1;
                    text-align: center;
                    padding: 8px;
                    color: $white;
                    background-color: $black;
                    font-size: 13px;
                    border-radius: 4px;
                    line-height: 1.54;

                    &::after{
                      content: '';
                      position: absolute;
                      top: 100%;
                      left: 50%;
                      margin-left: -5px;
                      border-width: 5px;
                      border-style: solid;
                      border-color: black transparent transparent transparent;
                    }
                  }
                }

                &:hover {
                    .warning-tooltip {
                        visibility: visible;
                    }
                }
            }

              .warning-marker.warning-marker-rounded{
                .warning-icon {
                  height: 14px;
                  width: 14px;
                  border-radius: 7px;
                  background-color: $red-2;
                }
                .warning-icon.checked {
                  background-color: $gray-16;
                }
                .warning-tooltip {
                  .warning-text {
                    top: -60px;
                    left: 50%;
                    transform: translateX(-50%);
                    color: $black;
                    background-color: $white;
                    border-radius: 6px;
                    line-height: unset;
                    width: 198px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    .form-check .custom-checkbox {
                      font-size: 13px;
                    }

                    .form-check .input-checkbox {
                      width: 20px;
                      height: 20px;
                      margin: 0;
                    }

                    .form-check .custom-checkbox::before {
                      border-radius: 6px;
                      cursor: pointer;
                      width: 20px;
                      height: 20px;
                    }
                    .form-check .custom-checkbox.checkbox-svg svg {
                      left: 6px;
                    }

                    &::after{
                      border-color: white transparent transparent transparent;
                    }
                  }
                }
              }
            }

            .project-code-with-icon-block {
              display: flex;
              align-items: flex-start;
              column-gap: 6px;

              .minimum-fee-icon {
                margin-top: 3px;
              }
            }

            .projects-list-item-sub-title, a {
              font-size: 15px;
              color: $gray-black;

              a {
                  color: $gray-black;
              }
              display: flex;
              align-items: center;
            //   position: relative;
            }

            .invalid {
                color: $pink;
            }

            .completed {
                color: $green;
            }

            .projects-list-input {
                padding: 14px 10px;
                font-size: 13px;
                display: flex;
                align-items: center;
                //font-size: 15px;
                color: $gray-black;
                border: 1px solid $gray-2;
                height: 40px;
            }

            .first-fixed {
                position: sticky;
                left: 0;
                text-align: end;
            }

            .second-fixed {
                position: sticky;
                left: 140px;
                text-align: end;
            }
        }
    }

    .project-tooltip-triger {
        height: 90%;
        width: 30px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        right: 5px;
        cursor: pointer;

        &:hover {
            .project-tooltip {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .project-tooltip {
        display: none;
        width: 168px;
        height: 60px;
        position: absolute;
        right: 30px;
        top: -3px;
        padding: 10px;
        z-index: 100;
        background-color: $white;
        border: 1px solid $gray-2;

        .project-tooltip-button {
            padding: 12px 14px;
            border: none;
            outline: none;
            background-color: transparent;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 15px;

            & > span {
                width: 14px;
                height: 16px;
                margin-right: 12px;
            }

            &:hover {
                cursor: pointer;
                background-color: $gray-3;
            }
        }
    }

}

.d-flex {
  display: flex;
  .v-center {
    justify-content: center;
    align-items: center;
  }
}
.d-flex.v-center {
  justify-content: center;
  align-items: center;
}
