.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.add-new-button {
  @include button(100%, 42px);
  @extend .yellow-button;
  line-height: 0;
  margin-bottom: 8px;

  .approve-icon {
    width: 10px;
    margin-right: 20px;
  }

  img {
    width: 10px;
    height: 10px;
    margin-right: 19px;
    filter: brightness(0);
    margin-top: 1px;
  }
  &.admin-view {
    width: auto;
    min-width: 148px;
    padding: 0 20px;
    flex-shrink: 0;
    margin: 0 0 0 14px;
    img {
      filter: none;
    }
  }
}
