.form-checkbox-wrap {
  position: relative;
}

.form-check {
  position: relative;

  .custom-checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;

    &.checkbox-svg {
      &:after {
        content: none;
      }

      svg {
        position: absolute;
        width: 10px;
        height: 6px;
        left: 4px;
      }
    }

    &:before,
    &:after {
      transition: color .3s;
      min-width: 24px;
      min-height: 24px;
      box-sizing: border-box;
    }
    &:before{
      content: "";
      display: block;
      margin-right: 10px;
      border: 1px solid $gray;
      background-color: #fff;
      border-radius: 6px;
    }

    &:after {
      content: '✔';
      // content: '>';
      position: absolute;
      top: 50%;
      width: 20px;
      height: 20px;
      font-size: 13px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: none;
      color: $black;
      // transform: translateY(-50%) rotate(90deg);
      transform: translateY(-50%);

      svg {
        fill: $black;
      }
    }

    &:hover:after {
      display: flex;
      color: $gray;
    }
    &.disabled {
      color: $gray-black;
    }


    &.checkbox-svg {
      &:after {
        content: none;
      }

      svg {
        position: absolute;
        width: 15px;
        height: 8px;
        left: 4px;
      }
    }
  }

  .input-checkbox {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 24px;
    height: 24px;
    top: 0;

    &:checked + .custom-checkbox {
      &:before{
        // border: 1px solid $gray;
        background-color: $yellow-1;
      }
      &:after {
        display: flex;
        color: $black;

        svg {
          g {
            fill: $black;
          }
          path {
            fill: $black;
          }
          polygon {
            fill: $black;
          }
          fill: $black;
        }
      }
    }

    &.red {
      &:checked + .custom-checkbox {
        &:before{
          background-color: $pink;
        }
      }
    }
    &.yellow {
      &:checked + .custom-checkbox {
        &:before{
          background-color: $yellow;
        }
      }
    }
    &.green {
      &:checked + .custom-checkbox {
        &:before{
          background-color: $green;
        }
      }
    }
  }
}
