.check-rating-container {
    .filters-dropdown {
        width: 280px;
        height: 40px;
        margin-right: 20px;
        
        .multiselect-header{
            height: 40px;
            text-transform: unset;
            color: $gray-black;
        }
    
        .body {
            max-width: 280px;
            margin-top: 10px;
        }

        .isOpen {
            max-height: 222px
        }
    }  
    
    .check-plan-filter {
        .multiselect-dropdown {
            width: 280px;
            height: 40px;
            
            .multiselect-header{
                height: 40px;
                text-transform: capitalize;
                background-color: $white;
                color: $gray-black;
            }
        
            .body {
                max-width: 280px;
                margin-top: 10px;
            }
        }

        .custom-items {
            left: 0;
        }
    }
}