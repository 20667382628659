.multiselect-input-group{
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 15px;
  line-height: 40px;
  border-radius: 8px;

  & > .current-label{
    color: #ff9751;
  }

  &:hover{
    background-color: $gray-14;
  }

  .disabled {
    color: #c6c6c6;
  }

  &.radio-button {
    span {
      width: 18px;
      height: 18px;
      border-color: #c6c6c6;
      margin-right: 14px;
    }

    input:checked + span {
      background-color: #ffe403;
    }

    input:checked + span:after {
      top: 8px;
      width: 4px;
      height: 4px;
    }

    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .text, .additional-text  {
      display: inline;
    }

    .additional-text {
      margin-left: auto;
      color: #c6c6c6;
    }
  }
}
