.contract-container {
    @include width-container(1340px);
    background-color: $white;
    padding: 40px 50px 70px 50px;

    .contract-title {
        color: $black;
        font-size: 22px;
        line-height: 1.2;
        font-family: $GTMedium;
        display: flex;
        height: min-content;
        margin: 25px 0;
    }

    .contract-information {
        display: flex;
        flex-wrap: wrap;
        height: 300px;
        justify-content: space-between;
        
        .contract-items {
            display: flex;
            flex-direction: column;
            border-top: 1px solid $gray-3;

            .contract-item {
                height: 60px;
                width: 600px;
                border-bottom: 1px solid $gray-3;
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                .contract-item-title {
                    color: $gray-black;
                    font-size: 15px;
                    line-height: 1.2;
                    font-family: $GTRegular;
                }
                
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                
                input[type=number]{
                    -moz-appearance: textfield;
                }

                .contract-item-field {
                    display: flex;
                    flex-direction: column;

                    .DayPickerInput > input,  
                        .contract-item-input {
                            height: 40px;
                            width: 345px;
                            padding: 0 10px;
                            color: $black;
                            border: solid 1px $gray-2;
                            position: relative;
                        }
    
                    .commitment-input {
                        height: 40px;
                        width: 345px;
                        padding: 0 10px;
                        color: $black;
                        border: solid 1px $gray-2;
                        position: relative;
    
                        input {
                            height: 100%;
                            border: none;
                            margin-left: 10px;
                            color: $black;                            
                        }
                    
                        &:before {
                            position: absolute;
                            content: "\20AC";
                            height: 5px;
                            top: 8px;
                            left: 10px;
                            color: $black;
                        }
    
                        &:after {
                            position: absolute;
                            content: "per year";
                            top: 8px;
                            color: $gray-2;
                        }
                    }

                    .contract-item-input-error {
                        color: $pink;
                        font-size: 13px;
                    }
                }
            }
        }

    }
}

.professional-service-container {
    width: 100%;
    background-color: $white;
    padding: 0 50px 50px 50px;

    &-tab-content {
        .tab-content {
            background-color: $white;
        }
        .tab-pane {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    &-tabs {
        background: $white;
        padding: 40px 0;
        border-bottom: 1px solid $gray-3;
        background-color: $white;
        .tab-items {
            padding: 0;
            margin-right: 50px;
            height: auto;
            &:before {
                top: unset;
                bottom: -6px;
                left: 0;
                width: 100%;
            }
        }
    }

    .professional-service-rows {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .professional-service-label-row {
        height: 59px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 130px 130px 120px 50px;
        grid-template-areas: 'name junior medior senior delete';
        border-bottom: 1px solid $gray-3;
        padding-bottom: 10px;
        padding-top: 12px;
        align-items: center;

        &-writer {
            grid-template-columns: 1fr 120px 45px;
            grid-template-areas: 'name junior delete';
            &-view-mode {
                grid-template-columns: 1fr 120px;
                grid-template-areas: 'name junior';
            }
        }
        
        &-header {
            padding: 20px 0;
            border-bottom: 1px solid $gray-3;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 130px 130px 120px 50px;
            grid-template-areas: 'name junior medior senior';
            .professional-service-warning {
                .warning-message {
                    margin-top: 0;
                }
            }
        }
        &-view-mode {
            grid-template-columns: 1fr 130px 130px 120px !important;
            grid-template-areas: 'name junior medior senior';
        }
        
        .professional-service-label {
            color: $black;
            font-size: 13px;
        } 

        .professional-service-input {
            height: 40px;
            width: 376px;
            //padding: 0 10px;
            color: $black;
            font-size: 15px;
            font-family: $GTMedium;
            border: transparent;
            position: relative;
            &:disabled {
                background-color: $white;
            }
        }

        .custom-dropdown {
            width: 100%;
        }

        .custom-items {
            width: 376px;
        }

        .professional-service-price-input {
            height: 40px;
            width: 120px;
            padding: 0 10px;
            color: $black;
            border: solid 1px $gray-2;
            position: relative;

            input {
                height: 100%;
                border: none;
                margin-left: 10px;
                font-size: 15px;
                color: $black; 
                width: 90px;
                &:disabled {
                    background-color: $white;
                }
            }
        
            &:before {
                position: absolute;
                content: "\20AC";
                height: 5px;
                top: 8px;
                left: 10px;
                color: $gray;
            }
        }

        .professional-service-input-error {
            color: $pink;
            font-size: 10px;
            margin-top: 3px;
        }
    }

    .professional-service-modal {
        .title-modal {
            margin: 0 auto 32px;
        }
        &-body {
            &-name {
                width: 380px;
                .form-label {
                    color: $gray-black;
                    font-size: 15px;
                    font-family: $GTRegular;
                }
            }
            &-input {
                height: 40px;
                padding: 0 10px;
                color: $black;
                border: solid 1px $gray-2;
                width: 100%;
                margin-top: 6px;
            }
            &-error {
                color: $red;
                font-size: 10px;
                margin-top: 3px;
            }
        }
        .modal-buttons {
            margin-top: 50px;
        }
        &-delete {
            padding: 0 53px;
            .modal-buttons {
                margin-top: 43px;
            }
        }
    }

    .add-new-service-button {
        @include button();
        @extend .gray-button;
        height: 30px;
        width: fit-content;
        min-width: 63px;
        padding: 0 11px 0 7px;
        background-color: $gray-3;
        color: $gray-black;
        font-size: 13px;
        margin-top: 20px;
      
        img {
          width: 10px;
          height: 10px;
          margin-right: 9px;
          filter: brightness(50%);
          margin-top: 1px;
        }
    }

    .professional-service-name {
        grid-area: name;
        width: 100%;
        display: flex;
        flex-direction: column;

    }
    
    .professional-service-junior {
        grid-area: junior;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .professional-service-medior {
        grid-area: medior;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .professional-service-senior {
        grid-area: senior;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .professional-service-delete {
        grid-area: delete;
        display: flex;
        align-items: center;
        //margin-top: 10px;
        border: none;
        background-color: transparent;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover {
            svg {
                visibility:visible;
            }
        }

        svg {
            visibility: hidden;
            color: $black;
            width: 14px;
            height: 16px;
        }
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }


    .languages-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;


        .language-item {
            width: 302px;
            height: 40px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            background-color: $gray-3;
            margin-bottom: 10px;
            margin-right: 10px;

            .language-title {
                color: $black;
                font-size: 15px;
                font-weight: normal;
                font-family: $GTMedium;
            }
            
        }
    }

    .add-new-language-button {
        @include button(63px, 30px);
        @extend .gray-button;
        background-color: $gray-3;
        color: $gray-black;
        font-size: 13px;
      
        img {
          width: 10px;
          height: 10px;
          margin-right: 9px;
          filter: brightness(50%);
          margin-top: 1px;
        }
    }

    .language-modal-field {
        width: 340px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .language-modal-title {
            color: $gray-black;
            font-size: 15px;
            margin-bottom: 10px;
        }

        .language-modal-input {
            width: 100%;
            height: 40px;
            color: $black;
            border: 1px solid $gray-2;
            padding-left: 15px;
        }
        
        .language-modal-error {
            color: $pink;
            font-size: 13px;
            margin-top: 10px;
        }
    }
    
    .modal-error {
        color: $pink;
        font-size: 15px;
        margin-bottom: 15px;
    }
}

.check-rating-tabs-container {
    @include width-container(1340px);
    background-color: $white;
    margin-top: 10px;
    padding: 0 50px 50px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .message-title {
        margin-top: 50px;
        font-size: 20px;
        color: $gray-black;
    }

    .items-row {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-areas: 'left right';
    }

    .left-items {
        grid-area: left;
        width: 580px;
        display: flex;
    }
    
    .right-items {
        grid-area: right;
        width: 580px;
        display: flex;
        margin-left: 30px;
    }


    .selected-plan-title {
        color: $blue-light;
        font-size: 13px;
        font-weight: normal;
    }

    .service-row {
        width: 100%;
        display: grid;
        grid-template-columns: 40% 25% 25% 25%;
        grid-template-areas: 'data junior medior senior';
    }


    .platform-row {
        width: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-areas: 'data price';
    }

    .product-row {
        width: 100%;
        display: grid;
        grid-template-columns: 40% 60%;
        grid-template-areas: 'data price';
    }


    .data {
        grid-area: data;
        width: 100%;
        display: flex;
    }

    .junior {
        grid-area: junior;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .medior {
        grid-area: medior;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .senior {
        grid-area: senior;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .price-grid {
        grid-area: price;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .item {
        width: 100%;
        display: flex;
        height: 100px;
        border-bottom: 1px solid $gray-3;
        align-items: center;

        .item-data {
            display: flex;
            flex-direction: column;

            .title {
                color: $black;
                font-size: 18px;
                line-height: 40px;
            }

            .price-type {
                color: $black;
                font-size: 15px;
                line-height: 30px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .price-title {
            color: $gray;
            font-size: 13px;
            line-height: 30px;
            margin-right: 8px;
        }

        .price {
            color: $black;
            cursor: grab;
        }
    
        .different-price-block {
            display: flex;
            align-items: center;
            
            .different-price {
                color: $orange-2;
                cursor: grab;
            }

            .different-price:hover {
                text-decoration: underline;
            }
        }


        .difference {
            width: 30px;
            height: 12px;
            background-color: $orange-2;
            color: $white;
            font-size: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 7px;
            cursor: grab;
        }

        .caret{
            path {
                fill: $white;
            }
        }
    
        .caret-up {
            transform: rotate(180deg);
    
            path {
                fill: $white;
            }
        }

        .price:before,
        .different-price-block:before {
            content: "\20AC";
            margin-right: 5px;
            height: 20px;
            color: $gray;
        }
    }
}