@import './src/assets/styles/mixins';
@import './src/assets/styles/mixins/responsive';

.modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999999;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  display: none;

  .scroll-content {
    overflow-y: auto;
  }

  .content {
    background-color: white;
    padding: 42px 57px;
    min-width: 460px;
    min-height: 231px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    @include media(mobile){
      min-width: 100%;
    }
  }

  &.isVisible {
    display: flex;
  }

  .modal-buttons {
    display: flex;
    justify-content: center;

    @include media(mobile){
      gap: 15px;
      width: 100%;
    }

    button.confirm-button,
    .cancel-button {
      @include button();
      min-width: 120px;
      width: fit-content;
      height: 50px;
      padding: 0 15px;
      box-shadow: none;
      border-radius: 6px;
      &:first-child {
        margin-right: 15px;
      }

      .info-area {
        width: 25px;
        position: relative;
        height: 50px;

        .info-tooltip-container {
          top: 14px;
          right: -5px;
        }

        .info-tooltip-content {
          top: -65px;
        }
      }
    }

    .modal-upload-file{
      margin-right: 15px;
      border-radius: 8px;
      border: none;
      box-shadow: none;
      @include media(mobile){
        width: 100%;
      }
      & ~ .confirm-button{
        margin-right: 15px;
        border-radius: 8px;
        box-shadow: none;
        @include media(mobile){
          width: 100%;
        }
      }
    }

    .confirm-button {
      @extend .yellow-button;
    }

    .cancel-button {
      background-color: $gray-3;
      @include media(mobile){
        width: 100%;
        color: $black;
      }
    }
  }
  h2,
  .title-modal {
    font-size: 22px;
    line-height: 1.36;
    margin: 0 auto 30px;
  }

  .title {
    font-size: 22px;
    line-height: 1.36;
    margin: 0 auto 5px;
  }

  h2,
  .title-modal,
  .sub-title-modal {
    font-family: $GTMedium;
    max-width: 350px;
    text-align: center;
  }

  .sub-title-modal {
    font-size: 16px;
    margin: 0 auto 15px;
    line-height: 1.5;
  }

  .sub-title {
    font-size: 15px;
    color: $gray-black;
    margin: 0 auto 30px;
    line-height: 1.5;
  }

  .custom-select {
    width: 196px!important;
    height: 30px;
    cursor: pointer;
    padding: 0 11px;
    font-size: 13px;
    margin-bottom: 15px;
  }

  .custom-select-invalid {
    border: 1px solid $red;
    color: $pink;
  }

  .modal-upload-file {
    cursor: pointer;
    font-family: $GTMedium;
    @extend .yellow-button;
    @include button(120px, 50px);
  }

  .briefing-participants {
    margin-bottom: 15px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .delete-btn{
    padding: 0;
    background-color: transparent;
    width: 26px;
    height: 26px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .delete-icon {
    width: 14px;
    path {
      fill: $gray-black;
    }
  }

  .custom-dropdown {
    border: 1px solid $gray-2;
    margin-bottom: 30px;

    .isOpen {
      max-height: 90px;
    }
  }

}

.side-modal {
  letter-spacing: -0.3px;
  .side-modal-wrapper {
    width: 100%;
    max-width: 720px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: $white;
    overflow: hidden;
    overflow-y: scroll;
    @include media(mobile){
      max-width: 80%;
    }

    .scroll-content {
      min-height: 100%;

      .content {
        height: 100%;
        padding: 60px;
      }
    }
  }
}
