.info-tooltip-container {
    position: absolute;
    top: -10px;
    // right: 200px;

    .info-tooltip-content {
        left: 120px;
        top: -100px;
        font-size: 10px;
        color: $gray-black;
        position: absolute;
        width: 200px;
        transform: translateX(-50%);
        background-color: white;
        padding: 10px;
        box-shadow: 0 0 3px 1px rgba(0,0,0,0.25);
        z-index: 99;
        border-radius: 3px;
        margin-top: 10px;
        transition: all 0.3s;
        display: none;
    }

    .info-tooltip-icon {
        border: 1px solid $gray-black;
        border-radius: 50%;
        width: 20px;
        height: 14px;
        font-size: 10px;
        text-align: center;
        line-height: 12px;
        color: $gray-black;
        cursor: pointer;
        padding: 3px 7px;
    }

    &:hover {
        .info-tooltip-content {
            display: flex;
        }
    }
  }

.black-info-tooltip-container {
    width: 14px;
    height: 14px;
    border: 1px solid $gray-2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    margin-left: 9px;
    cursor: pointer;

    &:hover {
        .black-info-tooltip-content {
            display: flex;
        }
    }
}
