@import './src/assets/styles/fonts';
@import './src/assets/styles/mixins/index';

.dashboard {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: $gray-14;

  .dashboard-content {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 120px;
    background: $white;

    .modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.evaluation-project-content {
      padding: 0;
      max-width: unset;
    }
  }
}
