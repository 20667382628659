.option-tooltip-container {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 6px;
    position: relative;

    &:hover {
      background-color: $gray-3;
    }

    &:hover:not(.open-tooltip-click-only),
    &.open-tooltip-click-only.open-tooltip-by-click {
        .option-item-tooltip {
            visibility:visible;
        }
    }
    
    span {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        
        &:hover {
            cursor: pointer;
        }
    }

    .option-item-tooltip {
        visibility: hidden;
        width: 200px;
        position: absolute;
        right: 0;
        top: 40px;
        padding: 10px;
        z-index: 100;
        background-color: $white;
        border: 1px solid $gray-2;
        border-radius: 6px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
    
        .option-item-tooltip-button {
            padding: 12px 14px;
            border: none;
            outline: none;
            background-color: transparent;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 15px;
            margin-right: 10px;
            display: flex;
            align-items: center; 
            text-transform: capitalize;
            border-radius: 6px;
            cursor: pointer;
            border-radius: 6px;
    
            span {
                width: 14px;
                height: 16px;
                margin-right: 12px;
            }  
            
            &:hover {
                background-color: $gray-3;
            }
        }

        .tooltip-impersonate {
            color: $orange-2;
            text-decoration: underline;
            cursor: pointer;
            
            span {
                margin-right: 7px;
            }

            svg {
                path {
                    fill: $orange-2;
                }
            }
        }
    }
}