.warning-message {
    display: flex;
    margin-top: 15px;
    align-items: center;

    .warning-icon {
        height: 13px;
        width: 13px;
        background-color: $orange-2;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        font-size: 9px;
        border-radius: 2px;
    }

    .warning-title {
        color: $orange-2;
        font-size: 13px;
        line-height: 2.31;

        a {
            color: $orange-2;
        }
    }
}