@import './src/assets/styles/colors';
@import './src/assets/styles/mixins/index';

.messages-tab {
  padding-top: 50px;
  margin-bottom: -24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  @include media(mobile) {
    padding-top: 30px;
  }

  // used in brainstorm and old design tab
  .users-list {
    min-width: 300px;
    border-right: solid 1px $gray-3;

    .user-link {
      display: flex;
      align-items: center;
      color: $black;
      text-decoration: none;
      font-size: 15px;
    }

    .user-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 21px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .delete-message {
    cursor: pointer;
    opacity: 0;
    margin-top: 3px;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: $gray-2;
      }
    }
  }

  .deleted-message {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    path {
      fill: $gray-black;
    }
  }

  .unread-mess {
    z-index: 1;
    position: absolute;
    right: 20px;
    display: flex;
    width: 20px;
    height: 20px;
    background: red !important;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: white;
  }

  .user-item {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    &:hover {
      .delete-message {
        opacity: 1;
      }
    }
  }

  .active-chat {
    background-color: $gray;
    margin-right: 8px;
    border-radius: 30px 0 0 30px;
  }

  .message-user-wrap {
    background-color: $gray-3;
  }

  .message-wrap {
    background-color: $yellow-3;
    margin-right: 20px;
    max-width: 800px;
    overflow: auto;
  }

  .deleted-message-wrap {
    background-color: $white;
    margin-right: 20px;
    max-width: 800px;
    overflow: auto;
    display: flex;
    align-items: center;
    border: 1px solid $gray-2;
  }

  .deleted-message-text {
    text-transform: inherit;
    color: $gray-black;
  }

  .message-gray {
    background-color: $gray-3;
  }

  .message-white {
    background-color: $white;
  }

  .message-user-wrap,
  .message-wrap,
  .deleted-message-wrap {
    padding: 12px 29px 12px 25px;
    margin-bottom: 2px;
    font-size: 15px;
    border-radius: 6px;
    line-height: 1.6;
  }

  .message-time {
    line-height: 2;
    font-size: 12px;
    color: $gray;
    margin-bottom: 13px;
  }
  .user-avatar-img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .messages-container {
    width: 100%;
    height: calc(100vh - 320px);
    padding-left: 70px;
    position: relative;
    display: flex;
    flex-direction: column;

    .user-avatar {
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .message-item {
      margin-bottom: 6px;

      .attachment {
        display: flex;
        color: $black;
        margin-top: 10px;
        text-align: center;
        padding: 10px 15px;
        align-items: center;
        text-decoration: none;
        background-color: $white;

        .icon-file {
          width: 15px;
          margin-right: 10px;
          path {
            fill: $green;
          }
        }
      }
    }

    .draft-message-wrapper {
      position: relative;

      .draft-message-view {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        padding: calc(42px + 12px) 15px 12px; // 42 - height of editor
        font-size: 15px;
        line-height: 24px;
        color: $gray;
        overflow: hidden;

        .draft-message-label {
          color: $orange-2;
        }

        .draft-message-content {
          width: 100%;
          white-space: pre-line;
        }
      }
    }

    .quill {
      min-height: 175px;
    }

    .ql-container {
      overflow-y: auto;
      height: 132px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      border: solid 1px #dfdfdf;
    }

    .ql-toolbar {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      border: solid 1px #dfdfdf;
    }

    .ql-editor {
      height: auto;
    }

    .user-item {
      display: flex;
      align-items: flex-start;

      &.started {
        .user-avatar {
          background-color: $yellow;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-right: 20px;
        }

        .message-user-wrap {
          background-color: $green-2;
        }
      }
      &.left {
        .user-avatar {
          margin-right: 20px;
        }
      }
    }

    .messages,
    .no-messages {
      margin-bottom: 10px;
      overflow-y: auto;
    }

    .no-messages {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      color: #d8d8d8;
    }

    .input-container {
      position: relative;
      height: 50px;
      margin-top: 5px;
      bottom: -20px;
    }

    .message-input {
      height: 50px;
      width: 100%;
      border: 1px solid $gray-2;
      padding: 0 95px 0 71px;
      resize: none;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #c6c6c6;
    }

    .attached-file {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: .75rem;
      left: 0;
      display: flex;
      align-items: center;

      span {
        max-width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }

      .img {
        padding: 5px;
        cursor: pointer;

        img {
          width: 12px;
        }
      }
    }

    .upload-file {
      width: 50px;
      height: 50px;
      cursor: pointer;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      position: absolute;

      &:after {
        content: "";
        position: absolute;
        width: 1px;
        background-color: #dfdfdf;
        height: 30px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .upload-file-icon {
      width: 15px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      fill: $black;
    }

    .upload-file-input {
      display: none;
    }

    .remove-file {
      background-color: transparent;
      border: none;
      padding: 0;
      margin-left: 10px;
      cursor: pointer;
      width: 10px;
    }

    .remove-file-icon {
      fill: $pink;
    }

    .send-message {
      @include button(91px, 40px);
      @extend .yellow-button;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      box-shadow: none;
      border-radius: 6px;

      &:disabled {
        cursor: not-allowed;
      }

      .spinner {
        width: 40px;
      }
    }
  }

  .me {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .error-message {
    font-size: 14px;
    color: $pink;
    margin-top: 5px;
  }
}

