.array-tooltip-wrapper {
    position: relative;
    padding: 10px 0;
}


.array-tooltip-base {
    &:hover {
        +.array-tooltip {
            display: block;
        }
    }
}

.array-tooltip {
    position: absolute;
    bottom: 90%;
    padding: 10px 26px 15px 26px;
    display: none;
    width: 162px;
    border-radius: 6px;
    background: black;
    color: white;
    text-align: center;
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        border-color: $black transparent transparent transparent;
        border-width: 6px;
        border-style: solid;
        transform: translateX(65%);
    }
    div {
        margin-bottom: 3px;
        font-size: 12px;
        line-height: 18px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.light {
        color: black;
        background: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        &::after {
            border-color: white transparent transparent transparent;
        }
    }
}
