$azalea: #efbebf;
$yellow : #fffb51;
$yellow-1: #ffe403;
$yellow-1_16: rgba($yellow-1, 0.16);
$yellow-2 : #f1ed36;
$yellow-3 : #fffecf;
$yellow-4: #F7F01A;
$yellow-5: #c9be61;
$yellow-hover: #f2d800;
$green : #9af08f;
$light-green: #82e190;
$light-green-1: #e6f9e9;
$light-green_15: rgba($light-green, 0.15);
$light-green_20: rgba($light-green, 0.2);
$green-2 : #eafae8;
$green-3 : #82e190;
$green-4 : #82e19033;
$green-5 : #edfbef;
$green-6: #84e19254;
$red: #dc3545;
$red-2: #E77074;
$red-3: #e7707433;
$red-4: #fceaea;
$darkish-pink: #e77074;
$darkish-pink_15: rgba($darkish-pink, 0.15);
$pink : #eb607b;
$pink-light: #ec9dad;
$pink-2 : #fdeff1;
$pink-3: #fcedee;
$gray-black: #6f6f6f;
$gray : #c6c6c6;
$gray-2 : #dfdfdf;
$gray-3 : #f2f2f2;
// TODO remove, as useless
$gray-4 : #f1f1f1;
$gray-5 : #ececec;
$gray-6 : #d3d3d3;
$gray-7 : #a9a9a9;
$gray-8: #bfbfbf;
$gray-9: #f8f8f8;
$gray-10: #979797;
$gray-11: #f6f6f6;
$gray-12: #f3f3f3;
$gray-13: #e7e7e7;
$gray-14: #f4f4f4;
$gray-15: #efefef;
$gray-16: #ababab;
$gray-17: #e5e5e5;
$gray-18: #d8d8d8;
$gray-19: #777777;
$gray-20: #898989;
$gray-21: #f7f7f7;
$gray-22: #666;
$gray-23: #f9f9f9;
$orange : #f2db00;
$orange-2 : #ff9751;
$orange-3 : #ff8d42;
$orange-4: #ff970233;
$orange-5: #fff2ea;
$orange-6: #fff1e9;
$orange-7: #ff8c50;
$black : #000000;
$black-2 : #363636;
$black-3 : #484848;
// TODO remove, as useless
$black-4 : #00000096;
// TODO remove, as useless
$black-5 : #99000000;
$black-6 : #00000040;
$black_5: rgba($black, 0.05);
$black_10: rgba($black, 0.1);
$black_30: rgba($black, 0.3);
$white : #ffffff;
$white_80: rgba($white, 0.8);
$blue : #90c3ff;
$blue-1 : #007ebb;
$blue-2 : #ebf7fd;
$blue-3 : #e5f7ff;
$blue-4 : #ebf8fd;
$malibu: #58C2F0;
$malibu-12: rgba($malibu, 0.12);
$blue-light : #4ae4e1;
$dark-gray: #ababab;
$wild-sand: #eae9e7;
$white-smoke: #f4f4f4;
$white-smoke-2: #efefef;
$bunker: #292D32;
$medium-purple: #a17ee8;
$medium-purple-2: #a17ee81f;
$medium-purple-12: rgba($medium-purple, 0.12);
$lemon-chiffon: #fffacd;
$light-cyan: #e0faf9;

$box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
$box-shadow-2: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-3: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
$box-shadow-4: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
$box-shadow-5: rgba(0, 0, 0, 0.25) 0 1px 2px;
$box-shadow-6: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
