.custom-freelancer-rate {
  padding-bottom: 20px;
  background-color: $white;
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 10px;
    padding: 35px 40px;
    p {
      font-family: $GTMedium;
      font-size: 22px;
      color: $black;
    }
  }
  &-icon {
    width: 22px;
    height: 22px;
    padding: 0 6px;
    border-radius: 20px;
    //background-color: #ff9751;
    margin-right: 20px;
  }

  .hover-button {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  .dot-button {
    cursor: pointer;
    min-width: 40px;
    min-height: 40px;
    transition: background-color .3s;
    background-color: $white;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    visibility: hidden;

    &:hover+.sub-menu {
      visibility: visible;
    }
  }

  .sub-menu {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    visibility: hidden;
    z-index: 1;

    width: 250px;
    padding: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $gray-2;
    background-color: $white;

    &:hover {
      visibility: visible;
    }

    p {
      line-height: 40px;
      padding: 0 12px;
      cursor: default;

      &:hover {
        transition: background-color .3s;
        background: $gray-3;
      }
    }
  }

  &-plans {
    &-list {
      padding: 0 25px;
      margin-bottom: 30px;
      a {
        text-decoration: none;
      }
      &-header {
        flex: 1;
        display: grid;
        /*grid-template-columns: 265px 175px 115px 95px 75px 80px;
        gap: 70px;*/
        grid-template-columns: 25% 180px repeat(7, 1fr);
        column-gap: 4px;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid $gray-3;
        border-top: 1px solid $gray-3;
        font-family: $GTRegular;
        font-size: 15px;
        color: $black;
        //font-weight: bold;
        text-transform: lowercase;
        &-wrap {
          padding: 0 25px;
          display: flex;
          width: 100%;
        }

        &__actions {
          width: 40px;
        }
      }
      &-item {
        display: flex;
        background-color: $white;
        padding: 10px 25px;
        position: relative;
        &:hover {
          background: $gray-3;

          .dot-button {
            visibility: visible;
          }
        }
        &-title {
          font-family: $GTRegular;
          font-size: 15px;
          color: $black;
        }
        &:after {
          content: '';
          position: absolute;
          display: block;
          background-color: $gray-3;
          bottom: 0;
          height: 1px;
          width: 96.3%;
        }
      }
    }

    &-left {
      display: flex;
      align-items: center;
    }
    &-link{
      width: 100%;
      display: grid;
      grid-template-columns: 25% 180px repeat(7, 1fr);
      column-gap: 4px;
      /*grid-template-columns: 290px 200px 140px 121px 100px 115px;
      gap: 45px;*/
      align-items: center;
      //display: flex;
    }
    &-title {
      font-family: $GTMedium;
      font-size: 32px;
      color: $black;
    }
    &-description, &-clients, &-grayClients {
      font-family: $GTRegular;
      font-size: 15px;
    }
    &-description {
      color: $gray-black;
    }
    &-clients {
      color: $black;
    }
    &-grayClients {
      color: $gray-black;
    }
    &-no-clients {
      color: $orange-2;
    }
  }

}
