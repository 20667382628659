.owner-select-container {
    margin-bottom: 30px;
    border-radius: 6px;

    .multiselect-dropdown {
      border-radius: 6px;
    }
    
    .owner-select {
      width: 300px;
      margin-bottom: 7px;
      border-radius: 6px;
      
      .multiselect-header {
        background-color: white;
        height: 40px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        border-radius: 6px;
      }
      
      .body {
        position: absolute;
        top: 100%;
        margin-top: 10px;
        max-height: 100px;
        min-width: 300px;
        border-radius: 6px;
      }
    
    }
  
    .owner-select-invalid {
      .multiselect-header {
        border: 1px solid $red;
        color: $pink;
      }
    }
  
    .error-input {
      color: $pink;
      font-size: 13px;
      margin-left: 5px;
    }
  }