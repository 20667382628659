@import './src/assets/styles/colors';

@mixin grayItem {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: $white-smoke;
  color: black;
}

@mixin yellowItem {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: rgba($yellow-1, 0.2);
  color: black;
}
