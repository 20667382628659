.progressbar {
  margin: 15px 0 0;
  width: 100%;
  height: 2px;
  border-radius: 50px;
  background-color: #ffffff;
  
  &_filler {
    height: 100%;
    border-radius: inherit;
    background-color: #82e190;

    &.warning {
      background-color: #FF9751;
    }

    &.expired {
      background-color: #E77074;
    }
  }
}
