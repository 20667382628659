.eval-param {
  &-wrapper {
    position: relative;
    width: 100%;
    border-top: solid #f2f2f2 1px;
    padding-top: 27px;
    margin-bottom: 30px;

    &:last-of-type {
      padding-bottom: 30px;
      border-bottom: solid #f2f2f2 1px;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  &_title {
    display: flex;
    font-family: $GTMedium;
    font-size: 18px;
    letter-spacing: -0.3px;
    color: #000;
    font-weight: 500;
    & > p::first-letter {
      display: inline;
      text-transform: uppercase;
    }
  }

  &_grades-wrapper {
    position: relative;
    display: flex;
  }

  &_grades-error {
    position: absolute;
    bottom: -15px;
    right: 0;
    font-size: 12px;
    font-weight: 600;
    color: $pink;
  }
  
  &_grade-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f6;
    cursor: pointer;

    &.bad-grade {
      &:hover {
        background: #e77074;
      }

      &.active-grade {
        background: #e77074;
      }
    }

    &.neutral-grade {
      &:hover {
        background: #ffe403;
      }

      &.active-grade {
        background: #ffe403;
      }
    }

    &.good-grade {
      &:hover {
        background: #82e190;
      }

      &.active-grade {
        background: #82e190;
      }
    }

    svg {
      width: 34%;
    }
  
    &:last-of-type {
      margin-right: 0;
    }
  }

  &_subtitle{
    margin-bottom: 5px;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: #6f6f6f;
  }

  &_input {
    margin-top: 15px;
    padding: 8px 18px 12px;
    border-radius: 8px;
    border: solid 1px #dfdfdf;
    width: 100%;
    height: 50px;
    background-color: #fff;

    &.error {
      border-color: $pink;
    }

    &-error {
      position: absolute;
      bottom: -15px;
      left: 0;
      font-size: 12px;
      font-weight: 600;
      color: $pink;
    }
  }
}