.impersonate-container {
  margin-top: 16px;
  margin-bottom: -5px;
  text-decoration: underline;
  cursor: pointer;
  z-index: 999;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: $orange-2;
  display: flex;
  align-items: center;
  &:hover {
    color: $orange-3;
  }
  .impersonate-icon{
    width: 12px;
    margin-right: 5px;

    path {
      fill: $orange-2;
    }
  }
}