$container--mobile: 768px;
$container--tablet-portrait: 1024px;
$container--tablet: 1280px;
$container--landscape: 1440px;
$container--desktop: 1920px;

$mobile: "only screen and (max-width: #{$container--mobile - 1})";
$tablet: "only screen and (min-width: #{$container--mobile}) and (max-width: #{$container--landscape - 1})";
$tabletPortrait: "only screen and (min-width: #{$container--mobile}) and (max-width: #{$container--tablet-portrait - 1})";
$tabletLandscape: "only screen and (min-width: #{$container--tablet-portrait}) and (max-width: #{$container--tablet - 1})";
$portrait: "only screen and (min-width: #{$container--mobile}) and (max-width: #{$container--tablet - 1})";
$landscape: "only screen and (min-width: #{$container--tablet}) and (max-width: #{$container--landscape - 1})";
$desktop-small: "only screen and (min-width: #{$container--landscape}) and (max-width: #{$container--desktop - 1})";
$desktop: "only screen and (min-width: #{$container--desktop})";

@mixin media($type) {
  $media: $mobile;

  @if $type == mobile {
    $media: $mobile;
  } @else if $type == portrait {
    $media: $portrait;
  } @else if $type == tablet {
    $media: $tablet;
  } @else if $type == tabletPortrait {
    $media: $tabletPortrait;
  } @else if $type == tabletLandscape {
    $media: $tabletLandscape;
  } @else if $type == landscape {
    $media: $landscape;
  } @else if $type == desktop-small {
    $media: $desktop-small;
  } @else if $type == desktop {
    $media: $desktop;
  }

  @media #{$media} {
    @content;
  }
}
