.product-container {
    width: 100%;
    background-color: $white;
    padding: 50px;
    margin-top: 10px;

    .products-header {
        border: 1px solid $gray-3;
        border-left: none;
        border-right: none;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .warning-message {
            margin-top: 0;
            align-items: center;
            .warning-icon {
                width: 13px;
                height: 13px;
            }
        }
        .td-rate {
            width: 120px;
            font-size: 13px;
            &.wide {
                width: 164px;
            }
        }
    }

    .product-rows {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .product-inputs-row {
        width: 100%;
        height: 59px;
        margin-bottom: 0;
        // display: grid;
        // grid-template-columns: 31% 31% 31% 10%;
        // grid-template-areas: 'title priceType price delete';
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 10px;
        border-bottom: 1px solid $gray-3;

        .product-input {
            height: 40px;
            width: 376px;
            // padding: 0 10px;
            font-size: 15px;
            color: $black;
            border: none;
            position: relative;
            background-color: transparent;
        }

        .custom-dropdown {
            width: 100%;
        }

        .custom-items {
            width: 376px;
        }

        .product-price-input {
            height: 40px;
            width: 100%;
            padding: 0 10px;
            color: $black;
            border: solid 1px $gray-2;
            position: relative;

            input {
                padding: 3px 0 7px;
                width: 100px;
                line-height: 30px;
                border: none;
                margin-left: 10px;
                font-size: 15px;
                color: $black;
                background-color: transparent;
            }

            &:before {
                position: absolute;
                content: '\20AC';
                height: 5px;
                top: 8px;
                left: 10px;
                color: $gray;
            }
            + .product-input-error {
                margin-top: -1px;
                line-height: 10px;
            }
        }

        .product-input-error {
            color: $pink;
            font-size: 10px;
            margin-top: 7px;
        }
    }

    .add-new-service-button {
        @include button();
        @extend .gray-button;
        height: 30px;
        width: fit-content;
        min-width: 63px;
        padding: 0 11px 0 7px;
        background-color: $gray-3;
        color: $gray-black;
        font-size: 13px;
        margin-top: 20px;

        img {
            width: 7px;
            height: 7px;
            margin-right: 7px;
            filter: brightness(50%);
            margin-top: 1px;
        }
    }

    .product-title {
        // grid-area: title;
        width: 100%;
        font-weight: 500;
        font-family: $GTMedium;
        display: flex;
        flex-direction: column;
    }

    .product-price {
        // grid-area: price;
        height: 40px;
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .product-delete {
        // grid-area: delete;
        width: 56px;
        height: 30px;
        margin-top: 10px;
        border: none;
        display: flex;
        justify-content: space-around;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            div {
                visibility: visible;
            }
        }

        div {
            width: 14px;
            height: 12px;
            // margin-left: 10px;
            visibility: hidden;
            svg {
                stroke: none;
                path {
                    fill: #6f6f6f;
                }
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .title-modal {
        margin-bottom: 24px;
    }
    .product-modal-group {
        margin-bottom: 10px;
        display: flex;
        flex-flow: column;
        .form-label {
            margin-bottom: 6px;
            font-size: 15px;
            line-height: 30px;
            color: #6f6f6f;
        }
        &.price-group {
            position: relative;

            &::before {
                position: absolute;
                content: '\20AC';
                height: 5px;
                top: 45px;
                left: 10px;
                color: $gray;
                z-index: 2;
            }

            .form-controls {
                padding-left: 22px;
            }
        }
        .form-controls {
            padding: 4px 15px 6px;
            height: 40px;
            width: 380px;
            border: solid 1px #dfdfdf;
            position: relative;
            &.is-invalid {
                border-color: red;
            }
        }

        .error-input {
            color: $pink;
            font-size: 13px;
            margin-left: 5px;
        }
    }
    .modal-buttons {
        margin-top: 50px;
    }
    .scroll-content {
        width: 500px;
        min-height: 247px;
    }
}
