.react-month-picker {
    input {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: $gray-black;
        background-color: $white;
        background-clip: padding-box;
        border: 1px solid $gray-2;
        border-radius: .25rem;
    }
}
.react-month-picker .calendar-container {
    max-width: 360px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: $white;
    border: 2px solid $gray-2;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: $gray-black;
}

.react-month-picker .section_mp {
    clear: both;
    padding: 0px;
    margin: 0px;
}

.react-month-picker .col_mp {
    display: block;
    float: left;
    text-align: center;
    height: 50px;
    line-height: 40px;
}
.react-month-picker .col_mp:first-child {
    margin-left: 0;
}

.react-month-picker .group_mp:before, .react-month-picker .group:after {
    content: "";
    display: table;
}
.react-month-picker .group_mp:after {
    clear: both;
}
.react-month-picker .group_mp {
    zoom: 1;
}

.react-month-picker .span_1_of_3_mp {
    width: 33.33%;
}
.react-month-picker .col_mp {
    font-size: 16px;
    padding-bottom: 5px;
    padding-top: 5px;
}
.react-month-picker .col_mp:hover {
    color: $black;
    background-color: $yellow;
    cursor: pointer;
}
.react-month-picker .calendar-container.readonly .col_mp:hover {
    background-color: transparent;
    cursor: default;
    color: inherit;
}
.react-month-picker .selected_date_mp {
    font-size: 16px;
    color: $gray-black;
    font-weight: bold;

    &:hover {
        color: $black;
        background-color: transparent;
        cursor: grab;
    }
}
.react-month-picker .selected_cell {
    background-color: $yellow;
    color: $black;
}
.react-month-picker .arrows_mp {
    font-weight: bold;
    font-size: 14px;

    &:hover {
        color: $black;
        font-size: 18px;
        background-color: transparent;
    }
}
.react-month-picker .month-input.readonly {
    background-color: $gray-2;
}

@media only screen and (max-width: 360px) {
    .react-month-picker .col_mp {
        font-size: 14px;
    }
}
