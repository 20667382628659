.rate-plan-contaiter {
  width: 100%;
  background-color: $white;
  padding: 0 60px;

  .rate-plan-tabs-and-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 30px 0;
    padding: 36px 0;
    border-bottom: 1px solid $gray-3;

    .rate-plan-tabs {
      display: flex;
      align-items: center;
      
      .rate-plan-tab {
        font-family: $GTMedium;
        cursor: pointer;
        font-size: 15px;
        padding-bottom: 7px;
        margin-left: 37px;
        height: max-content;
        color: $gray-black;
        text-transform: lowercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: none;
        background-color: transparent;
        transition: all .3s;
        z-index: 1;

        &:first-child {
          margin-left: 0;
        }
    
        &:hover {
          color: $black;
        }
    
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: $yellow-1;
          opacity: 0;
          transition: opacity .3s;
        }
    
        &.active{
          &:before {
            opacity: 1;
          }
    
          color: $black;
        }
      }
    }

    // TODO Check
    .rate-plan-save-btn {
      @extend .yellow-button;
        border: none;
        width: auto;
        min-width: 148px;
        padding: 14px 10px;
        cursor: pointer;
        font-weight: bold;
        color: $gray-black;
        text-transform: lowercase;
  
        &.active, 
          &:hover {
          background-color: $yellow-2;
          color: $black;
       }    
     }
  }
}
