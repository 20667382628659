.filter-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    margin: 5px 0;
    background-color: $yellow-3;
    border-radius: 20px;
    max-width: 300px;
    overflow: hidden;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &__icon {
        height: 9px;
        width: 9px;
        margin-right: 8px;
    }

    &__text {
        font-size: 13px;
        margin-right: 10px;
        @include textOverflowOneLine;
    }

    &__button {
        outline: none;
        border: none;
        padding: 0;
        width: 8px;
        height: 8px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &.keyword {
        background-color: $blue-2;

        .filter-label__icon {
            fill: $malibu;
        }
    }
}
