@import './Filter/index';
@import './ContentBlocks/index';

.content-service-wrap {
    width: 100%;
    background-color: $white;
}

.content-service-preloader {        
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;       
}

.content-service-plans {
    @include width-container(1340px);
    background-color: $white;
    display: flex;
    
    .content-service-plan {
        width: 100%;
    }
}