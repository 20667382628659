.draft-chat-container {
    margin-left: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.design-draft-chat {
    display: flex;
    width: 100%;
    height: 400px;
    padding-top: 0;
    margin-bottom: 0;

    svg {
        margin: auto;
        fill: $gray;
    }

    .empty-history {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .chat-content {
        background-color: $white;
        width: 100%;
        height: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .chat-history {
        flex: 1;
        max-height: 240px;
        overflow-y: auto;
        margin-bottom: 0;
        padding-top: 10px;
        border-top: 1px solid $gray-3;

        .user-item {
            margin-bottom: 10px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-color: #f2f2f2;
        }

        &::-webkit-scrollbar {
            width: 4px;
            background-color: #f2f2f2;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #c6c6c6;
        }
    }

    .editor, .rdw-emoji-modal {
        height: 60px;
        min-height: 60px;
        margin-bottom: 50px;
        padding-right: 5px;

        &::-webkit-scrollbar-track {
            border-radius: 4px;
            background-color: #f2f2f2;
        }

        &::-webkit-scrollbar {
            width: 4px;
            background-color: #f2f2f2;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #c6c6c6;
        }

        .DraftEditor-editorContainer {
            max-width: 570px;
        }
    }

    .editor-toolbar {
        .rdw-option-wrapper {
            height: auto;
        }
        .rdw-image-modal{
            left: -43px;
        }
        .rdw-emoji-modal {
            height: 200px;
        }
        .rdw-embedded-modal,
        .rdw-link-modal {
            height: auto;
        }
        .rdw-image-modal,
        .rdw-emoji-modal,
        .rdw-embedded-modal,
        .rdw-link-modal {
            box-shadow: none;
        }
    }

    .messages-container {
        .input-container {
            margin-top: -50px;
            margin-right: 5px;
        }
    }
}
