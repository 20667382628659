// used in old design tab and in brainstorms
.google-draft-container {

  .google-doc-draft {
    margin: 0 -25px;
    width: 100%;
    .document {
      height: 157px;
    }
  }

  .drafts-item-wrap {
    width: 276px;
    margin: 0 25px 50px;
  }

  .draft-name {
    margin-bottom: 25px;
    margin-top: 10px;
    width: 100%;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .buttons-container {
    margin-top: 30px;
    position: relative;
  }

  .approve-btn {
    @include button(212px, 44px);
    @extend .yellow-button;
    margin-bottom: 10px;

    &:disabled {
      color: $gray-10;
      background-color: $gray-2;
      cursor: default;
    }
  }

  .review-ideas-btn {
    font-size: 15px;
    line-height: 2;
    color: $black;
    text-decoration: underline;
    text-align: center;
    text-transform: lowercase;
    display: block;
    cursor: pointer;
  }

}
