@import './src/assets/styles/colors';

.client-profile-body {
  border-top: 1px solid $gray-3;

  .client-profile-tabs-financial,
  .notes-tab {
    background-color: $white;
  }
}

.client-profile-tabs {
  .client-profile-tabs-wrapper {
    background-color: $gray-14;
  }

  .tab-items {
    text-decoration: none;
    border-radius: 6px 6px 0 0;

    &:before {
      height: 2px;
      top: unset;
      bottom: 0;
      background-color:#ffe403;
    }
  }
}

.user-body {
  background-color: $gray-3;
}

.user-profile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  padding: 26px 49px;

  .cancel-button, .confirm-button {
    border-radius: 6px !important;
    box-shadow: none !important;
  }

  &-financials {
    background-color: $gray-3;

    .title-modal {
      margin-bottom: 4px;
    }

    .modal-buttons {
      .confirm-button,
      .cancel-button {
        &:first-child {
          margin-right: 20px;
          height: 50px;
        }
      }
    }

    .tab-items {
      padding: 19px 38px 20px 39px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 38px;
        right: 0;
        height: 2px;
        background-color: $yellow-1;
        opacity: 0;
        transition: opacity .3s;
        width: calc(100% - 77px);
      }

      &:before {
        background-color: transparent;
      }
    }

    .tab-items {
      border-radius: 6px 6px 0 0;

      &:before {
        height: 2px;
        width: 53px;
        margin: 0 auto;
        top: unset;
        bottom: 0;
        // background-color:#ffe403;
      }
    }

    &-body {
      .tab-pane:not(.active) {
        display: none;
      }
      &-overview {
        @include width-container(1340px);

        &-filters {
          background-color: $white;
          border-radius: 6px;
          border-top-left-radius: 0;
          padding: 30px 60px;
          margin-bottom: 10px;

            .preloader {
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .projects-filter {
                width: 100%;

                .filter-label {
                    border-radius: 6px;
                    height: 40px;
                }

                .filters-area {
                  display: flex;
                  justify-content: space-between;
                }

              .filters-list {
                  padding-bottom: 10px;
                  display: flex;
                  width: 945px;
                  flex-wrap: wrap;
                }

                .multiselect-dropdown {
                    border-radius: 6px;
                    width: 295px;
                    border: none;
                }

                .filter-item {
                    min-width: 295px;
                    // margin-top: 10px;
                    border-radius: 6px;

                    .body {
                        border-radius: 6px;
                        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                        top: 100%;
                        width: 100%;
                        overflow-y: auto;
                        overflow: hidden;

                        &::-webkit-scrollbar {
                            width: 16px;
                            padding: 0 10px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: $white;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 20px;
                            background-color: $gray-2;
                            max-height: 68px;
                            min-height: 40px;
                            width: 6px;
                            border: 5px solid $white;
                            cursor: pointer;
                        }

                        .custom-checkbox:hover {
                            border-radius: 6px;
                        }
                    }

                    .custom-checkbox {
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:hover {
                            border-radius: 6px;
                        }
                    }

                    &:not(:nth-child(5n)) {
                        margin-right: 20px;
                    }

                    & > div:first-child {
                        height: 40px;
                        border-radius: 6px;
                    }

                    & > div:last-child {
                        width: 100%;
                        max-width: unset;
                        max-height: 225px;
                        margin-top: 10px;
                        overflow: hidden;
                        overflow-y: auto;
                    }
              }

              .date-dropdown-open {
                width: 450px;
              }

              .filter-date-dropdown, .date-selector-container {
                  min-width: 219px;
                  margin-right: 20px;
                  margin-top: 10px;
                  border-radius: 6px;

                .multiselect-header {
                  height: 40px;
                }

                  & > div:last-child {
                      width: 450px;
                      max-width: unset;
                      overflow: unset;
                      overflow-y: unset;
                      margin-top: 10px;

                      &::before {
                        content: '';
                          display: block;
                          width: 232px;
                          height: 1px;
                          background-color: $gray-2;
                          margin-left: auto;
                      }
                  }
              }

              .filter-labels-warnings {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                min-height: 30px;

                .filter-labels {
                  max-width: calc(100% - 200px);
                }

                .warning-message {
                  margin-top: 0;

                  .warning-title {
                    line-height: 1.54;
                    white-space: nowrap;
                  }
                }
              }

              .green {
                .warning-message {
                  .warning-icon {
                    background-color: $green;
                  }

                  .warning-title {
                    color: $green;
                  }
                }
              }

              .filter-labels {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;

                  .filter-label {
                    height: 30px;
                  }
                }

              .filter-close-month-button {
                width: 245px;
                height: 40px;
                background-color: $yellow-1;
                border-radius: 6px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-top: 10pxж
                margin-left: 10px;

              span {
                font-size: 15px;
                line-height: 2;
                color: $black;
              }

              &:hover {
                cursor: pointer;
              }
            }

            .filter-closed-button {
              width: 245px;
                height: 40px;
                background-color: $gray-3;
                border-radius: 6px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;

              span {
                font-size: 15px;
                line-height: 2;
                color: $black;
                opacity: 0.5;
              }

              &:hover {
                cursor: default;
              }
            }

            .disabled {
              opacity: 0.3;

              &:hover {
                cursor: default;
              }
            }

              .OverviewTab__actionBlock {
                display: flex;
                column-gap: 10px;

                .filter-closed-button, .filter-close-month-button {
                  width: 195px;
                  margin-left: 0;
                }
              }

              .menu-button {
                  padding: 0;
                  background-color: $gray-3;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  // margin-top: 10px;
                  border-radius: 6px;
                  position: relative;

                  & > svg {
                      width: 14px;
                      height: 14px;
                  }

                  &:hover {
                      cursor: pointer;
                  }

                  .dot-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;
                  }

                  .sub-menu {
                    padding: 9px 10px 10px 10px;
                    border-radius: 6px;
                    background-color: $white;
                    width: 228px;
                    visibility: hidden;
                    position: absolute;
                    margin-top: 5px;
                    top: 90%;
                    right: calc(100% - 40px);
                    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

                    .sub-menu-item {
                      width: 208px;
                      height: 40px;
                      padding: 11px;
                      border-radius: 6px;
                      background-color: $white;
                      display: flex;
                      align-items: center;
                      border: none;
                      cursor: pointer;

                      &:disabled {
                        cursor: not-allowed;

                        &.isLoading {
                          cursor: progress;
                        }
                      }

                      & > svg {
                        width: 11px;
                        height: 11px;
                        margin-right: 12px;
                      }

                      span {
                        font-size: 24px;
                        width: 11px;
                        margin-right: 12px;
                      }

                      p {
                        font-size: 15px;
                        color: $black;
                      }

                      &:hover{
                        background-color: $gray-3;
                      }
                    }

                  }
                }

                .menu-button:hover {
                  .sub-menu {
                    visibility: visible;
                  }
                }
            }

            .project-graphic-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px;
                height: 200px;
                background-color: $gray-3;
                margin-top: 20px;
                border-radius: 6px;
                column-gap: 10px;

                .project-graphic-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    min-width: 228px;
                    height: 138px;
                    background-color: $white;
                    //max-width: 228px;
                    border-radius: 6px;

                    .graphic-value {
                        font-size: 20px;
                        font-weight: bold;
                        overflow-x: hidden;
                        max-width: 180px;
                        max-height: 30px;
                        overflow-y: hidden;
                        white-space: nowrap;
                    }

                    .graphic-devider {
                        width: 20%;
                        border-top: 1px solid cyan;
                        margin: 13px 0;
                    }

                    .graphic-title-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .completion-icon {
                            width: 16px;
                            height: 16px;
                            border: 1px solid $gray-black;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                width: 7px;
                                height: 7px;

                                path {
                                    fill: $gray-black;
                                }
                            }
                        }

                        svg {
                            width: 15px;
                            height: 15px;
                            margin-left: 1px;

                            path {
                                fill: $gray-black;
                            }
                        }

                        .graphic-title {
                            color: $gray-black;
                            font-size: 14px;
                            margin-left: 7px;
                        }
                    }
                }
            }
        }

        &-container {
          background-color: $white;
          border-radius: 6px;
          padding: 40px 60px 50px 60px;

          .projects-list-container {
            .projects-list-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 40px;

                & > p {
                    font-size: 22px;
                    line-height: 22px;
                    font-weight: 600;
                }

                & > div {
                    display: grid;
                    grid-template-columns: auto auto;
                    align-items: center;
                    column-gap: 15px;
                }

                .header-buttons {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: min-content;

                    .custom-dropdown {
                        width: 219px;
                        height: 40px;
                        border: solid 1px $gray-2;
                        border-radius: 6px;
                    }

                    .custom-items {
                        border-radius: 6px;
                        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                        top: 100%;
                        width: 100%;
                        overflow-y: auto;
                        overflow: hidden;
                        margin-top: 10px;
                    }

                    .button-title {
                        font-size: 16px;
                        color: $gray-black;
                        width: max-content
                    }
                }

                .save-button {
                    @extend .yellow-button;
                        border: none;
                        width: auto;
                        min-width: 148px;
                        padding: 8px 14px 10px 14px;
                        height: 40px;
                        cursor: pointer;
                        font-weight: bold;
                        color: $gray-black;
                        text-transform: lowercase;

                        &.active,
                            &:hover {
                            background-color: $yellow-2;
                            color: $black;
                        }
                }

                .filter-button {
                    padding: 0;
                    background-color: $gray-3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    margin-top: 5px;
                    border: none;

                    & > svg {
                        width: 25px;
                        height: 25px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .projects-list-table {
                display: flex;
                flex-direction: column;
                //overflow-y: auto;
                margin-bottom: 15px;
                position: relative;
                border-bottom: 1px solid $gray-3;
                border-top: 1px solid $gray-3;

                & > div:last-child {
                    margin-bottom: 5px;
                }

                .projects-list-content {
                    width: 100%;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #f2f2f2;
                }

                &::-webkit-scrollbar {
                    height: 10px;
                    background-color: #f2f2f2;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #c6c6c6;
                    cursor: pointer;
                }
            }


            .projects-list-table-grid {
                display: grid;
                grid-template-columns: repeat(9, 1fr);
                align-items: center;
                position: relative;
            }

            .client-projects-list-table-grid {
              display: grid;
              grid-template-columns: repeat(7, 1fr);
              align-items: center;
              position: relative;

              .projects-list-titles {
                padding-left: 0;
              }
            }
                .client-block {
                    display: flex;
                    align-items: center;
                    background-color: $white;
                    height: 100%;
                    padding-left: 15px;

                    &:hover {
                        cursor: pointer;
                    }

                    .arrow {
                        width: 10px;
                        height: 6px;
                        margin-left: 7px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 0.3s;

                        svg {
                            width: 10px;
                            height: 6px;
                        }
                    }
                    .reversed {
                        transition: 0.3s;
                        transform: rotate(180deg);
                    }
                }

                .projects-list-titles {
                    padding: 14px 15px;
                    font-size: 13px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    text-transform: lowercase;
                    background-color: $white;
                    height: 100%;
                    justify-content: center;

                    &:first-child {
                        padding-left: 0;
                    }
                }

                .projects-list-sub-title {
                  font-size: 13px;
                  color: $gray-black;
                }

                .first-fixed {
                    position: sticky;
                    left: 0;
                }

                .second-fixed {
                    position: sticky;
                    left: 140px;
                }

                .projects-list-client-user-dropdown {
                    width: 159px;
                }
            }

            .project-item {
                position: relative;
                border-top: 1px solid $gray-3;

                .project-item-info {
                    min-height: 40px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $gray-3;
                }

                .projects-item-table-grid {
                  color: $black;
                  text-decoration: none;
                    display: grid;
                    grid-template-columns: repeat(9, 1fr);
                    align-items: center;
                    position: relative;
                }

                .client-projects-item-table-grid {
                  color: $black;
                  text-decoration: none;
                  display: grid;
                  grid-template-columns: repeat(7, 1fr);
                  align-items: center;
                  position: relative;

                  .projects-list-item {
                    padding-left: 0;
                  }
                }

                    .with-marker {
                        p {
                            padding-left: 23px;
                        }
                    }

                    .projects-list-item {
                        padding: 30px 15px;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        font-size: 15px;
                        height: 100%;
                        overflow-wrap: anywhere;
                        position: relative;

                        a {
                            color: $black;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                      &-code {
                        padding: 30px 10px 30px 0;
                      }
                      &-marker {
                        position: absolute;
                        width: 14px;
                        img {
                            border-radius: 2px;
                        }
                        &-tooltip {
                          position: relative;
                          visibility: hidden;
                          &-text {
                            position: absolute;
                            width: 200px;
                            top: -55px;
                            transform: translateX(-46.5%);
                            z-index: 9999;
                            text-align: center;
                            padding: 5px 0;
                            color: $white;
                            background-color: $black;
                            border-radius: 4px;
                            &::after{
                              content: '';
                              position: absolute;
                              top:100%;
                              left: 50%;
                              margin-left: -5px;
                              border-width: 5px;
                              border-style: solid;
                              border-color: black transparent transparent transparent;
                            }
                          }
                        }
                        &-fee {
                            min-width: 15px;
                        }
                        &-discount {
                        //   top: 50px;
                        }
                        img {
                          width: 15px;
                          height: 15px;
                        }
                      }

                      .warning-marker {
                        margin-left: 6px;

                        .warning-icon {
                            height: 13px;
                            width: 13px;
                            background-color: $orange-2;
                            color: $white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 9px;
                            border-radius: 2px;
                        }

                        .warning-tooltip {
                          position: relative;
                          visibility: hidden;

                          .warning-text {
                            position: absolute;
                            width: 208px;
                            top: -53px;
                            transform: translateX(-46.5%);
                            z-index: 1;
                            text-align: center;
                            padding: 5px;
                            color: $white;
                            background-color: $black;
                            font-size: 13px;
                            border-radius: 4px;
                            line-height: 1.54;

                            &::after{
                              content: '';
                              position: absolute;
                              top: 100%;
                              left: 50%;
                              margin-left: -5px;
                              border-width: 5px;
                              border-style: solid;
                              border-color: black transparent transparent transparent;
                            }
                          }
                        }

                        &:hover {
                            .warning-tooltip {
                                visibility: visible;
                            }
                        }
                    }
                    }

                    .project-code-with-icon-block {
                      display: flex;
                      align-items: flex-start;
                      column-gap: 6px;

                      .minimum-fee-icon {
                        margin-top: 3px;
                      }
                    }

                    .projects-list-item-sub-title, a {
                      font-size: 15px;
                      color: $gray-black;

                      a {
                          color: $gray-black;
                      }
                      display: flex;
                      align-items: center;
                    //   position: relative;
                    }

                    .invalid {
                        color: $pink;
                    }

                    .completed {
                        color: $green;
                    }

                    .projects-list-input {
                        padding: 14px 10px;
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        //font-size: 15px;
                        color: $gray-black;
                        border: 1px solid $gray-2;
                        height: 40px;
                    }

                    .first-fixed {
                        position: sticky;
                        left: 0;
                        text-align: end;
                    }

                    .second-fixed {
                        position: sticky;
                        left: 140px;
                        text-align: end;
                    }
            }

            .project-tooltip-triger {
                height: 90%;
                width: 30px;
                background-color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                position: sticky;
                right: 5px;
                cursor: pointer;

                &:hover {
                    .project-tooltip {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .project-tooltip {
                display: none;
                width: 168px;
                height: 60px;
                position: absolute;
                right: 30px;
                top: -3px;
                padding: 10px;
                z-index: 100;
                background-color: $white;
                border: 1px solid $gray-2;

                .project-tooltip-button {
                    padding: 12px 14px;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    font-size: 15px;

                    & > span {
                        width: 14px;
                        height: 16px;
                        margin-right: 12px;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: $gray-3;
                    }
                }
            }
        }
        border-radius: 6px;
        background-color: $white;
      }
      &-departments {
        border-radius: 6px;
        &-header {
          margin-bottom: 10px;
          padding: 30px 50px;
          border-radius: 6px;
          background-color: $white;
          &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 22px;
              color: $black;
              font-family: $GTMedium;
            }
            .add-department-btn {
              padding: 9px 20px 12px;
              border-radius: 6px;
              border: none;
              font-size: 15px;
              background-color: #f2f2f2;
              text-transform: lowercase;
              cursor: pointer;
              color: $black;
              font-family: $GTMedium;

              svg {
                margin-right: 20px;
              }
            }
          }
        }

        .department-item {
          margin-bottom: 10px;
          padding: 50px 50px 52px;
          border-radius: 6px;
          width: 100%;
          display: flex;
          background-color: $white;
          &-title {
            margin-right: 50px;
            padding: 14px 0;
            border: 1px solid #f2f2f2;
            border-left: none;
            border-right: none;
            height: fit-content;
            min-height: 59px;

            display: flex;
            flex-flow: row;
            align-items: center;
            flex-basis: 360px;

            &-image {
              margin-right: 12px;
              width: 30px;
              height: 30px;
              border-radius: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ffe403;
            }
            span {
              font-family: $GTMedium;
              font-size: 18px;
            }
          }
          &-info {
            margin-right: 50px;
            border-top: 1px solid #f2f2f2;
            flex-basis: 740px;

            &-field {
              height: 59px;
              padding: 15px 0 10px;
              border-bottom: 1px solid #f2f2f2;
              display: flex;
              flex-flow: row;
              align-items: baseline;

              &-label {
                width: 36%;
                margin-bottom: 6px;
                font-size: 15px;
                line-height: 2;
                text-transform: capitalize;
                color: #6f6f6f;
                flex-shrink: 0;
              }

              &-value {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                grid-gap: 10px;
                font-size: 15px;
                line-height: 24px;
                color: #000000;
              }
            }
          }
          &-tools {
            margin-left: auto;
          }
        }

        .title-modal {
          margin-bottom: 4px;
        }

        .sub-title-modal {
          margin-bottom: 41px;
          font-size: 15px;
          color: #6f6f6f;
        }

        .confirm-button, .cancel-button {
          border-radius: 6px;
          box-shadow: none !important;
        }
      }
      &-contract {
        &-billing {
          padding: 30px 50px 65px 50px;
          border-radius: 6px;
          background-color: $white;
          display: flex;
          justify-content: space-between;
          &-company, &-contract {
            width: 50%;
          }
          &-company {
            margin-right: 50px;
            &-header {
              display: flex;
              align-items: center;
              border-bottom: 1px solid $gray-3;
              span {
                color: $black;
                font-size: 22px;
                font-family: $GTMedium;
                line-height: 60px;
              }
            }
            .form-group {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;
              border-bottom: 1px solid $gray-3;
              .error-input {
                width: 340px;
                color: $red;
                font-size: 13px;
                margin-left: 5px;
              }
              .bordered-input {
                border: 1px solid $gray-2;
                border-radius: 6px;
              }
              input, label {
                font-size: 15px;
                font-family: $GTRegular;
              }
              label {
                color: $gray-black;
              }
              input {
                width: 345px;
                padding: 15px;
                display: flex;
                align-items: center;
                border: transparent;
                height: 40px;
                color: $black;
                &:disabled {
                  background-color: $white;
                }
              }
            }
          }
          &-contract {
            &-header {
              display: flex;
              align-items: center;
              span {
                color: $black;
                font-size: 22px;
                font-family: $GTMedium;
                line-height: 60px;
              }
            }
            &-body {
              padding-top: 15px;
              border-radius: 6px;
              background-color: $gray-3;
              .form-group {
                padding: 20px 30px 0 30px;
              }
              label {
                color: $gray-black;
                font-size: 15px;
                font-family: $GTRegular;
              }
              .contract {
                &-block {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .custom-dropdown {
                    border-radius: 6px;
                    width: 315px;
                    background-color: $white;

                    .custom-header {
                      height: 40px;
                      text-transform: capitalize;
                      font-size: 15px;
                      font-family: $GTRegular;
                      padding-left: 5px;
                      .custom-arrow-down {
                        width: 8px;
                        height: 8px;
                        right: 10px;
                        color: $gray-black;
                      }
                    }

                    .custom-items {
                      margin-top: 0;
                      border: solid 1px #dfdfdf;
                      border-radius: 6px;
                      max-width: 100%;
                      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                      .custom-checkbox {
                        color: $black;
                        &:after, &:before {
                          display: none;
                        }
                      }

                      // to avoid horizontal scroll
                      input {
                        width: 100%;
                      }
                    }
                  }

                  .multiselect {
                    &-dropdown {
                      width: 315px;
                      background-color: $white;
                      .body {
                        top: 40px;
                        max-width: 100%;
                        .custom-checkbox {
                          color: $black;
                          &:after, &:before {
                            display: none;
                          }
                        }
                      }
                    }

                    &-header {
                      height: 40px;
                      text-transform: capitalize;
                      font-size: 15px;
                      font-family: $GTRegular;
                      .arrow-down,
                      .arrow-up {
                        width: 8px;
                        height: 8px;
                        right: 10px;
                        color: $gray-black;
                      }
                    }
                  }

                  input {
                    width: 315px;
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    // border: 1px solid $gray-2;
                    border: none;
                    border-radius: 6px;
                    height: 40px;
                    color: $black;
                    font-size: 15px;
                    font-family: $GTRegular;
                    &:disabled {
                      background-color: $white;
                    }
                  }
                  &-commitment,
                  &-platform-fee {
                    display: flex;
                    gap: 20px;
                    position: relative;
                    input, .multiselect-dropdown, .custom-dropdown {
                      width: 147px;
                    }
                    &-viewBlock {
                      justify-content: flex-start;
                    }
                    &-viewMode {
                      flex: 0.81;
                    }
                    &-view {
                      gap: 0;
                      input {
                        width: 110px;
                      }
                      .multiselect-header {
                        padding: 0 10px;
                      }
                    }
                    .commitment-euro {
                      position: absolute;
                      font-size: 15px;
                      top: 10.5px;
                      left: 10px;
                    }
                    .commitment-price,
                    .platform-fee {
                      padding: 15px 15px 15px 20px;
                    }
                  }
                  &-hide {
                    .custom-dropdown {
                      background-color: transparent;
                      cursor: default;
                      .custom-header {
                        border: transparent;
                        padding-left: 5px;
                        .custom-arrow-down {
                          background-color: transparent;
                          color: transparent;
                          display: none;
                        }
                      }
                    }
                    .multiselect {
                      &-dropdown {
                        background-color: transparent;
                      }
                      &-header {
                        border: transparent;
                        .arrow-down,
                        .arrow-up {
                          fill: transparent;
                        }
                      }
                    }
                    input {
                      background-color: transparent;
                      border: transparent;
                      &:disabled {
                        background-color: transparent;
                      }
                    }
                  }
                }
                &-file {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 20px;
                  border-top: 1px solid $white;
                  .documents-container {
                    margin-bottom: 0;
                    border-radius: 6px;
                    width: 100%;
                    .documets-header {
                      margin-bottom: 5px;
                      .document-title {
                        font-family: $GTRegular;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &-client {
          margin-top: 10px;
          padding: 30px 0;
          border-radius: 6px;
          background-color: $white;
          &-heading {
            font-size: 22px;
            color: $black;
            font-family: $GTMedium;
          }
          &-empty {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 50px;
            p {
              display: flex;
              align-items: center;
              justify-content: center;
              .warning-message {
                margin-top: 0;
                margin-left: 30px;
              }
            }
          }
          .change-plan-modal-form {
            .plan-options {
              display: flex;
              margin-top: 10px;
              &-item {
                display: flex;
                align-items: center;
                &:first-child {
                  margin-right: 40px;
                }
                .radio-button {
                  margin-right: 10px;
                }
              }
            }
            .default-rate-item {
              width: 380px;
              //margin-right: 15px;
              margin-top: 10px;

              & > div:first-child {
                height: 40px;
              }
              & > div:last-child {
                width: 100%;
                max-width: unset;
                max-height: 280px;
                margin-top: 10px;
              }
            }
            .custom-select {
              border-radius: 6px;
            }
            .multiselect-dropdown {
              .multiselect-header {
                border-radius: 6px;
              }
              .body {
                top: 40px;
                border-radius: 6px;
                border: solid 1px #dfdfdf;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
              }
            }
            .cancel-button, .confirm-button {
              border-radius: 6px;
              box-shadow: none;
            }
          }
          &-plan {
            margin-top: 10px;
            &-link {
              grid-template-columns: 576px 125px 110px 95px 115px; //455px 135px 125px 100px 115px old value
            }
            &-list {
              padding: 0 25px;
              margin-bottom: 17px;
              &-top {
                padding: 0 25px;
                margin-bottom: 0;
              }
              &-header {
                grid-template-columns: 620px 170px 155px 140px 80px; //500px 180px 170px 145px 80px old value
                font-weight: normal;
                margin-top: 20px;
                height: 59px;

                span {
                  font-size: 13px;
                }
              }
              &-item {
                border-bottom: none;
                position: relative;
                &:after {
                  content: '';
                  display: block;
                  background-color: $gray-3;
                  width: 96%;
                  height: 1px;
                  position: absolute;
                  bottom: 0;
                }
                .hover-button {
                  position: relative;
                  display: flex;
                  justify-content: flex-end;
                }

                .dot-button {
                  border-radius: 6px;
                  cursor: pointer;
                  min-width: 40px;
                  min-height: 40px;
                  transition: background-color .3s;
                  background-color: $white;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 32px;
                  text-align: center;
                  display: inline-block;
                  visibility: hidden;

                  &:hover+.sub-menu {
                    visibility: visible;
                  }
                }

                .sub-menu {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  border-radius: 6px;
                  transform: translateY(100%);
                  display: flex;
                  flex-direction: column;
                  visibility: hidden;
                  z-index: 1;

                  width: 240px;
                  padding: 10px;
                  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                  border: solid 1px $gray-2;
                  background-color: $white;

                  &:hover {
                    visibility: visible;
                  }

                  p {
                    line-height: 40px;
                    padding: 0 12px;
                    cursor: default;

                    &:hover {
                      transition: background-color .3s;
                      background: $gray-3;
                    }
                  }
                }
              }
            }
          }
        }
        &-manager {
          margin-top: 10px;
          padding: 26px 25px 30px;
          border-radius: 6px;
          background-color: $white;
          &-heading {
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-title {
              margin-bottom: 9px;
              margin-right: 20px;
              font-size: 22px;
              font-family: $GTMedium;
              line-height: 60px;
            }
            .warning-message {
              margin-top: 0;
              margin-right: auto;
            }
            .financial-add-btn {
              border-radius: 6px;
              width: 92px !important;
              min-width: 92px !important;
              align-self: baseline;
              // height: 40px;
            }
          }
          &-content {
            &-table {
              .content-service-preloader {
                height: 200px;
              }

              &-head {
                margin: 0 25px;
                border: 1px solid #f2f2f2;
                border-right: none;
                border-left: none;
                height: 59px;
                display: grid;
                grid-template-columns: 34% 17% 40% 9%;

                &-th {
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                  text-transform: lowercase;
                }
              }
              &-body {
                // border-bottom: 1px solid #f2f2f2;

                &-row {
                  position: relative;
                  padding: 0 25px;
                  display: grid;
                  grid-template-columns: 34% 17% 40% 9%;
                  height: 59px;
                  &:after {
                    content: '';
                    margin-left: 25px;
                    display: block;
                    background-color: #f2f2f2;
                    width: 96%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                  }

                  &-td {
                    display: flex;
                    align-items: center;

                    &.td-phone,
                    &.td-email {
                      color: #6f6f6f;
                    }

                    .avatar {
                      margin-right: 10px;
                      width: 20px;
                      height: 20px;
                      img {
                        border-radius: 50%;
                      }
                    }
                    .form-check .custom-checkbox:before {
                      border-radius: 50%
                    }
                    .form-check .input-checkbox:checked + .custom-checkbox:before {
                      border: none;
                    }
                    .dots {
                      position: relative;
                      border-radius: 6px;
                      visibility: hidden;
                      .review-item-tooltip {
                        height: auto;
                      }
                    }
                  }
                  &:hover {
                    background-color: #f2f2f2;
                    .dots {
                      background-color: $white;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }

          .add-manager {
            width: 100%;

            h3 {
              font-family: $GTMedium;
              text-align: center;
              margin-bottom: 44px;
            }
            &-multiselect {
              width: 100%;
              margin-bottom: 50px;
              .multiselect-header {
                padding: 4px 16px;
                border-radius: 6px;
                width: 100%;
                height: auto;
                max-width: 340px;
                font-size: 15px;
                line-height: 30px;
                text-transform: none;

                span {
                  white-space: normal;
                }
              }
              .body {
                top: 45px;
                border: solid 1px #dfdfdf;
                border-radius: 6px;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                max-width: 100%
              }
            }
            .cancel-button, .confirm-button {
              border-radius: 6px;
              box-shadow: none ;
            }
          }
        }
      }
      &-tools {
        margin-top: 10px;
        padding: 26px 50px 17px 50px;
        border-radius: 6px;
        background-color: $white;
        &-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &-title {
            margin-bottom: 9px;
            margin-right: 20px;
            font-size: 22px;
            font-family: $GTMedium;
            line-height: 60px;
          }
          .warning-message {
            margin-top: 0;
            margin-right: auto;
          }

          .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .form-edit-btn {
              opacity: 1;
            }
          }
        }

        .tools-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .tool-item {
            width: 287px;
            padding: 20px;
            min-height: 196px;
            background-color: $white;
            border-radius: 6px;
            box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.1);
            border: solid 1px $gray-2;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .switcher-container {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              .icon {
                width: 60px;
                height: 60px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  width: 35px;
                  height: 33px;

                    path {
                      fill: $black;
                  }
                }
              }

              .first {
                background-color: #ffe403;
              }

              .second {
                background-color: #ff9751;
              }
            }

            .name {
              font-family: $GTMedium;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.67;
              color: #000;
              margin: 12px 0;
              display: block;
            }

            .price {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                font-size: 15px;
                line-height: 1.47;
                color: #6f6f6f;
              }

              .price-input {
                width: 88px;
                height: 40px;
                padding: 0 11px;
                border-radius: 6px;
                border: solid 1px #dfdfdf;
                background-color: #fff;
                position: relative;

                input {
                  height: 100%;
                  width: 80%;
                  border: none;
                  margin-left: 10px;
                  color: $black;
                }

                &:before {
                    position: absolute;
                  content: '\20AC';
                    height: 3px;
                    top: 9px;
                    left: 10px;
                    color: $black;
                }
              }

              .custom-dropdown {
                width: 150px;
                height: 40px;
                border: solid 1px $gray-2;
                border-radius: 6px;
              }

              .custom-items {
                border-radius: 6px;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                top: 100%;
                width: 100%;
                overflow-y: auto;
                overflow: hidden;
                margin-top: 10px;
              }
            }
          }

          .tool-item:not(:nth-child(4n)) {
            margin-right: 30px;
          }
        }
      }
    }

    .financial-add-btn {
      @include button();
      min-width: 140px;
      width: fit-content;
      padding: 0 15px;
      height: 40px;
      @extend .gray-button;

      .plus-icon {
        width: 10px;
        display: block;
        margin-right: 19px;

        path {
          fill: $black;
        }
      }

      .spinner_icon {
        width: 20px;
        height: 20px;
        margin-right: 9px;
        flex-shrink: 0;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .info-item.bottom-mines{
    border-bottom: none
  }
  .full-width{
    width: 100%
  }
  .full-width > div{
    max-width: 100%;
  }
  .general-information,
  .briefing-documents {
    flex-grow: 1;
    max-width: 595px;
  }

  .briefing-documents {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .client-documents-block {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  .general-information {
    margin-right: 50px;

    .info-item {
      padding: 9px 0;
      border-bottom: 1px solid $gray-3;
      display: flex;
      flex-flow: row;
      align-items: baseline;
      &.border-top {
        border-top: 1px solid $gray-3;
      }
    }
  }

  .user-profile-title {
    font-size: 22px;
    font-family: $GTMedium;
    margin-right: 20px;
  }

  .user-profile-cancel-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .general-information-header:hover {
    .user-profile-edit-btn {
      border-radius: 6px;
      opacity: 1;
    }
  }

  .info-item {
    max-width: 550px;
    display: flex;
    line-height: 65px;
    justify-content: flex-start;
    border-bottom: 1px solid $gray-2;
    padding: 5px 0;

    .checkbox {
      display: flex;
      align-items: center;
      height: 65px;

      .form-check {
        padding-top: 7px;
        border-radius: 2px;
      }
    }

    .label {
      width: 196px;
      flex-shrink: 0;
      font-size: 15px;
      margin-right: 56px;
      color: $gray-black;
    }

    .short {
      width: 55px;
    }

    .value {
      position: relative;
      width: 100%;
      color: $black;
      font-size: 15px;
      display: flex;
      align-items: center;
      line-height: 24px;

      .frame {
        width: 100%;
        padding: 7px;
        padding-left: 15px;
        border: 1px solid transparent;
      }

      .info-textarea {
        padding: 15px 40px 15px 15px;
        border: 1px solid $gray-2;
        width: 100%;
        display: flex;
        align-items: center;
        height: 100px;
        font-size: 15px;
        line-height: 24px;
        color: $black;
        background-color: $gray;
        resize: none;


        &:not([type]):disabled {
          background-color: transparent;
        }
      }

      .info-input {
        padding: 15px;
        border: 1px solid $gray-2;
        border-radius: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 15px;
        line-height: 24px;
        color: $black;
        background-color: $gray;

        &:not([type]):disabled {
          background-color: transparent;
        }
      }

      .edit-button {
        position: absolute;
        right: 0;
        top: 55%;
        border: none;
        width: 50px;
        display: none;
        background-color: transparent;
        transform: translateY(-50%);
        cursor: pointer;

        .edit-icon {
          width: 16px;
        }
      }

      .textarea {
        top: 25px;
        right: 5px;
      }

      &:hover {
        .edit-button {
          display: block;
        }
      }
    }

    a {
      color: inherit;
    }

    p {
      margin: 0;
      font-size: 12px;
      line-height: 12px;
      color: $pink-light;
      position: absolute;
      bottom: -3px;
    }
    .error-text {
      bottom: -12px;
    }
  }

  .designer-about {
    width: 100%;
    max-width: 100%;
    padding: 15px 0;

    .label {
      width: auto;
      line-height: initial;
    }
  }

  .border-top {
    border-top: 1px solid $gray-3;
  }

  .briefing-documents-wrap {
    .document-name {
      width: 100%;
    }
  }
}

.documents-container {
  margin-bottom: 1px;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: $gray-3;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &>div:nth-child(2) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  &>div:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px
  }

  .document {
    gap: 10px;
  }
}
.documets-header {
  display: flex;
  justify-content: space-between;
  &.with-margin {
    margin-bottom: 21px;
  }

  .add-file {
    padding: 0 14px;
    border-radius: 6px;
    height: unset;
    min-width: unset;
    font-size: 13px;
    color: $gray-black;
    text-transform: lowercase;
    background-color: $white;
    border: transparent;

    &.white {
      padding: 4px 14px 6px 11px;
      border-radius: 6px;
      width: unset;
      height: unset;
      background-color: #FFFFFF;
      svg {
        margin-top: 2px;
        margin-right: 7px;
        width: 7px;
        height: 7px;
      }
    }

  }
}

.document-title {
  color: $gray-black;
  font-size: 15px;
  line-height: 2;
  margin-bottom: 6px;
}

.download-icon,
.document-icon {
  flex-shrink: 0;
}

.upload-client-document-icon-loader {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.document-icon {
  width: 20px;
}

.download-icon,
.delete-icon {
  width: 14px;
  path {
    fill: $gray-black;
  }
}

.document-icon {
  margin-right: 15px;

  path {
    fill: $green;
  }
}

.document {
  display: flex;
  line-height: 56px;
  padding-left: 17px;
  align-items: center;
  background-color: $white;
  justify-content: flex-start;
  border-bottom: 1px solid $gray-3;
  // border-radius: 6px;
}

.document-name {
  color: $black;
  font-size: 15px;
  flex-basis: 350px;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: lowercase;
  @media screen and (max-width: 1240px) {
    flex-shrink: 1;
  }
}


.add-file{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 97px;
  height: 40px;
  background-color: $gray-2;
  color: $gray-black;
  cursor: pointer;
  width: fit-content;
  padding: 0 15px;

  &:not(:nth-child(2)){
    margin-top: 10px;
  }

  svg{
    width: 10px;
    height: 10px;
    margin-right: 13px;
  }

  path{
    fill: $gray-black;
  }
}

.buttons-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  .download-btn,
  .delete-project {
    padding: 0;
    width: 50px;
    border: none;
    height: 56px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    &:first-child {
      border-left: 1px solid $gray-3;
      border-right: 1px solid $gray-3;
    }
  }
}

.users-information {
  width: 100%;
  padding-top: 12px;
  &-header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 22px;
      font-weight: 500;
    }
    .add-user-btn {
      padding: 9px 21px 12px 20px;
      border-radius: 6px;
      font-size: 15px;
      background-color: $gray-3;
      text-transform: lowercase;
      cursor: pointer;

      svg {
        margin-right: 20px;
      }
    }
  }

  table {
    width: 100%;
    border-top: 1px solid $gray-3;

    .table-users-col {
      &-name {
        width: 23%;
      }
      &-phone {
        width: 15%;
      }
      &-email {
        width: 30%;
      }
      &-departments {
        width: 17%;
      }
      &-admin {
        width: 3%;
      }
      &-impersonate {
        width: 8%;
      }
      &-tooltip {
        width: 5%;
      }
    }
    tr {
      height: 59px;
      border-bottom: 1px solid $gray-3;
    }
    tbody tr:hover {
      background-color: $gray-3;
      transition: .3s all;
      .dots {
        border-radius: 6px;
        visibility: visible;
        transition: .3s all;
      }
    }
    th {
      padding: 20px 0;
      font-size: 13px;
      text-align: start;
      text-transform: lowercase;
    }
    td {
      padding: 9px 0;
      // display: flex;
      // align-items: center;
      vertical-align: middle;
      font-size: 15px;
      &.td-name {
        div {
          display: flex;
          align-items: center;

        }
      }
      &.td-phone,
      &.td-email {
        font-size: 15px;
        color: $gray-black;
      }
      &.td-phone {
        line-break: anywhere;
      }
      &.td-departments {
        position: relative;
        &:hover {
          .tooltip {
            display: block;
          }
        }

        .tooltip {
          position: absolute;
          width: 162px;
          background: $white;
          color: $black;
          padding: 10px 26px 15px 28px;
          bottom: 75%;
          display: none;
          border-radius: 6px;
          font-size: 13px;
          box-shadow: $box-shadow-2;

          &::after {
            content: '';
            position: absolute;
            top: 100%;
            border-color: $white transparent transparent transparent;
            border-width: 6px;
            border-style: solid;
            transform: translateX(65%);
          }
          div {
            margin-bottom: 3px;
            font-size: 12px;
            line-height: 18px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
      &.td-admin {
        position: relative;
        align-items: center;
      }
      &.td-impersonate {
        position: relative;
        align-items: center;

        .impersonate-container {
          margin-top: 0;
          height: fit-content;
          margin-bottom: 0;
        }
      }
      &.td-params {
        position: relative;
        align-items: center;
        padding-right: 20px;
        .option-tooltip-container:hover {
          background-color: $white;
        }

      }

      .avatar {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .create-user-error {
    font-size: 16px;
    color: red;
    margin-bottom: 16px;
  }
}
.avatar-placeholder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f0f0f0;
  background-image: url('../../../../assets/images/default-avatar-with-background.png');
  background-position: center;
  background-size: contain;
}
.dots {
  margin-left: auto;
  padding-bottom: 10px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  background-color: $white;
  cursor: pointer;
  .review-item-tooltip {
    width: 120px;
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    &__button {
      border-radius: 6px;

      &> span {
        display: none;
        border-radius: 6px;
      }
    }
  }

  &:hover {
    background-color: #f2f2f2;
    +.review-item-tooltip {
      display: flex;
      flex-direction: column;
    }
  }
}
.client-user-modal {
  width: 100%;
  .client-user-title {
    font-family: $GTMedium;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 34px;
  }
  .client-user-form {
    .form-group {
      margin-bottom: 19px;
      .label {
        display: inline-block;
        margin-bottom: 6px;
        line-height: 2;
        font-size: 15px;
        color: $gray-black;
        &-checkbox {
          color: $black;
        }
      }
      .form-control {
        width: 100%;
        height: 40px;
        padding: 0 15px;
        border: 1px solid $gray-2;
        border-radius: 6px;
      }
      .input-error {
        margin-top: 3px;
        max-width: 380px;
        font-size: 10px;
        color: $pink;
      }
    }
    .form-group-checkbox {
      margin-top: 23px;
      display: flex;
      align-items: center;
    }
  }

  .devider {
    width: 100%;
    border-top: 1px solid $gray-3;
    margin: 30px 0 19px;
  }

  .multiselect-dropdown {
    width: 100%;
    .multiselect-header {
      height: 40px;
      width: 100%;
      border-radius: 6px;
      span {
        text-transform: none;
        max-width: 300px;
      }
    }
    .multiselect-input-group {
      width: 100%;
    }
    .body {
      min-width: 100%;
      top: 50px;
      border-radius: 6px;
      border: solid 1px #dfdfdf;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .modal-buttons {
    margin-top: 40px;
  }
}

.user-profile-edit-btn, .form-edit-btn {
  //margin-right: 13px;
  padding: 6px 14px;
  border: none;
  border-radius: 6px;
  width: 55px;
  font-size: 13px;
  line-height: 20px;
  color: $gray-black;
  background-color: $gray-3;
  transition: all .3s;
  opacity: 1;
  cursor: pointer;

  &.active {
    padding: 6px 14px;
    border-radius: 6px;
    color: $black;
    background-color: $yellow-1;
    // box-shadow: inset 0 -1px 0 0 $black;
    opacity: 1;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.availabilityEditButton {
  outline: none;
  display: flex;
  border: 0;
  cursor: pointer;
  font-family: $GTRegular;
  align-items: center;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  text-transform: lowercase;
  transition: background-color .3s;
  width: fit-content;
  height: 30px;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 13px;
  color: $black;
  background-color: $gray-3;
}

.availabilityCancelButton {
  padding: 0;
  outline: none;
  height: 30px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  text-transform: lowercase;
  font-size: 13px;
  line-height: 1.54;
  border-radius: 6px;
  color: $gray-black;
  background-color: $gray-3;
}

.form-edit-btn {
  margin-left: 20px;
}

.form-edit-cancel {
  border: none;
  background-color: transparent;
  color:#6f6f6f;
  cursor: pointer;
}

.copywriter-profile {
  flex-wrap: wrap;

  .user-profile-title {
    width: 100%;
  }
}

.contentoo-clients {
  @include width-container();
  background-color: $white;
  padding: 0 49px 26px 49px;

  h2 {
    margin-bottom: 30px;
  }

  .inner {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $gray-3;
    border-bottom: 1px solid $gray-3;
    padding-top: 50px;
  }

  .inner > div {
    width: 16.5%;
    margin-bottom: 50px;
  }

  .inner > div > a,
  .inner > div > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $black;
    text-align: center;
  }

  .logo {
    width: 80px;
    height: 80px;
    overflow: hidden;
    background-color: transparent;
    border: 1px solid $gray-2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  span {
    font-weight: bold;
    font-size: 16px;
  }
}

.about-container {
  @include width-container();
  background-color: $white;
  padding: 26px 49px;

  h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  label {
    min-width: 200px;
    margin-right: 56px;
  }

  p {
    color: $black;
    font-size: 15px;
    line-height: 24px;
    padding: 10px 0;
  }

  & > div {
    border-bottom: 1px solid $gray-3;
    min-height: 70px;
    display: flex;
    align-items: center;
  }

  .border-top {
    border-top: 1px solid $gray-3;
  }
}

.copywriter-portfolio {
  padding: 50px 50px 0;
  background-color: $white;
  @include width-container();

  .copywriter-head {
    height: 60px;
    display: flex;
    margin-bottom: 9px;
    align-items: center;
    justify-content: space-between;
  }

  .user-profile-title {
    font-size: 22px;
    line-height: 2.73;
    font-family: $GTMedium;
  }

  .portfolio-items-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;

    .document-icon {
      width: 46px;
      margin: auto auto 0;

      path {
        fill: $green;
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $white;
      border-top: 1px solid $gray-3;
      justify-content: space-between;
    }

    .delete-btn,
    .download-btn {
      border: none;
      flex-grow: 1;
      height: 40px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      &:first-child {
        border-right: 1px solid $gray-3;
      }
    }

    .delete-icon,
    .download-icon {
      width: 14px;
      path {
        fill: $gray-black;
      }
    }

    .project-name {
      line-height: 2;
      font-size: 15px;
      margin: 10px 0 17px;
      text-transform: lowercase;
      width: 165px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .item-wrap {
      width: 272px;
      padding: 30px;
      min-height: 237px;
      position: relative;
      margin: 0 25px 50px;
      background-color: $gray-3;
    }

    .item {
      height: 100%;
      display: flex;
      text-align: center;
      flex-direction: column;
      background-color: $white;
    }

    .document-preview {
      width: 100%;
      height: 100%;
      background-color: rgba(111, 111, 111, 0.70);
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .small-icon {
        width: 36px;
        height: 24px;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        path {
          fill: $white;
        }
      }
    }

    .document {
      position: relative;
      height: 136px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;



      &:hover {
        .document-preview {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .add-client {
      top: 50%;
      left: 0;
      right: 0;
      min-width: 97px;
      width: fit-content;
      padding: 0 15px;
      height: 40px;
      margin: auto;
      display: flex;
      cursor: pointer;
      color: $gray-black;
      position: absolute;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      background-color: $gray-2;
    }

    .add-client-icon {
      margin-right: 15px;
      path {
        fill: $gray-black;
      }
    }

  }

}

