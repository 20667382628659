.content-container {
    width: 100%;

    &-wrap {
        padding: 0 30px;
    }

    .content-block-container {
        display: grid;
        grid-template-columns: 26% 49% 25%;
        grid-template-areas: 'title inputs buttons';
        align-items: center;
        border-top: 1px solid $gray-3;
        padding: 15px 0;        
    }  

    .content-block-title {
        grid-area: title;
        width: 100%;
        color: $black;
        font-size: 16px;
        line-height: 1.2;
        font-family: $GTMedium;
        display: flex;
        height: min-content;
    }

    .content-block-buttons {
        grid-area: buttons;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        padding-right: 10px;
    }

    .content-checkbox {
        display: flex;
        align-items: center;
        line-height: 40px;

        .form-check {
            display: flex;
        }

        .input-checkbox {
            margin-right: 20px;
            background-color: $white;
        }
    
        .content-item-label {
            margin-bottom: 0;
            color: $gray-black;
        }
    }

    .content-block-button {
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-left: 20px;
        border: none;
        background-color: $white;

        &:hover {
            background-color: $gray-3;
            cursor: pointer;
        }

        path {
            fill: $black-2;
        }
        
        .caret-down {
            transform: rotate(180deg);
        }
    }
    
    .content-blocks-container {
        display: grid;
        grid-template-columns: 28% 62% 10%;
        grid-template-areas: 'title prices buttons';
        align-items: center;
        border-top: 1px solid $gray-3;
        padding: 15px 0;
        height: 100%;
    }

    .content-block-prices {
        grid-area: prices;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.plan-languages-container {
    display: grid;
    grid-template-columns: 28% 72%;
    grid-template-areas: 'title prices';
    align-items: center;
    height: 40px;
}

.language-container {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;

    .content-block-language-container {
        display: grid;
        //grid-template-columns: 26% 23.2% 50.5%;
        grid-template-columns: 25.1% 23.2% 51.5%;
        grid-template-areas: 'title inputs buttons';
        align-items: center;
        border-left: 1px solid $gray-2;
        padding-left: 30px;
        padding-bottom: 10px;
    }  

    .content-block-language {
        color: $gray-black;
        font-size: 14px;
        line-height: 1.2;
        font-family: $GTMedium;
        display: flex;
        height: min-content;
    }

    .content-block-first-language {
        color: $gray-black;
        font-size: 14px;
        line-height: 1.2;
        font-family: $GTMedium;
        display: flex;
        height: min-content;

        &:before {
            content: 'to';
            margin-right: 28px;
        }
    }

    .content-block-language-translation {
        color: $gray-black;
        font-size: 14px;
        line-height: 1.2;
        font-family: $GTMedium;
        display: flex;
        height: min-content;
        margin-left: 40px;
    }
    
    .content-block-languages-container {
        display: grid;
        grid-template-columns: 28% 62% 10%;
        grid-template-areas: 'title prices buttons';
        align-items: center;
        border-left: 1px solid $gray-2;
        padding-left: 30px;
        height: 40px;

    }

    .content-block-language-prices {
        grid-area: prices;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.language-block-inputs-container {
    grid-area: inputs;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .language-block-inputs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        width: max-content;
        align-items: center;

        // TODO Check
        .language-input {
            height: 40px;
            width: 280px;
            border: solid 1px $gray-2;
            margin-right: 20px;

            .multiselect-dropdown {
                width: 280px;
                height: 40px;
                
                .multiselect-header{
                    height: 40px;
                    text-transform: capitalize;
                    background-color: $white;
                    color: $gray-black;
                }
            
                .body {
                    max-width: 280px;
                    margin-top: 10px;
                }
            }
        }
    }
}

.content-block-inputs {
    grid-area: inputs;
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: max-content;
    align-items: center;
}