.plans-filter {
    width: 100%;
    padding: 30px 50px;  
    background-color: $white;

    .plans-filters-row {
        display: flex;
        justify-content: space-between;
        align-items: center; 
    
        .plans-filters-list {
            width: 600px;  
            display: flex;  
        
            .filters-dropdown {
                width: 280px;
                height: 40px;
                margin-right: 20px;
                
                .multiselect-header{
                    height: 40px;
                    text-transform: unset;
                    color: $gray-black;
                }
            
                .body {
                    max-width: 280px;
                    margin-top: 10px;
                }

                .isOpen {
                    max-height: 222px
                }
            }        
        }

        .add-new-service-button {
            @include button(127px, 40px);
            @extend .gray-button;
            min-width: fit-content;
            min-width: 63px;
            padding: 0 11px 0 7px;
            background-color: $gray-3;
            color: $black-2;
          
            .approve-icon {
              width: 10px;
              margin-right: 20px;
            }
          
            img {
              width: 10px;
              height: 10px;
              margin-right: 19px;
              filter: brightness(0);
              margin-top: 1px;
            }
        }
    }

    .plans-filter-labels {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
}





