@import './FinancialTab/index';
@import './Tooltips/index';
@import './RatePlansComponents/index';

.setting-tabs {
    margin: 20px 0 0;

    .settings-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        // dashboard merge
        //padding: 0 60px;

        .setting-tab-items {
            text-decoration: none;
            font-family: $GTMedium;
            cursor: pointer;
            font-size: 15px;
            padding: 0 30px;
            height: 50px;
            color: $gray-black;
            text-transform: lowercase;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border: none;
            background-color: transparent;
            transition: all .3s;
            z-index: 1;

            &:hover {
                color: $black;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $yellow-1;
                opacity: 0;
                transition: opacity .3s;
                margin: 0 30px;
            }

            &.active {
                &:before {
                    opacity: 1;
                }

                color: $black;
                background: $white;
                border-radius: 6px 6px 0 0;
            }
        }
    }
}


