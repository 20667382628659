@import './ServiceSettingsBlock/index';

.service-block-container {
    background-color: $white;
    height: 100px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    
    .service-block-content {
        width: 100%;
        height: 100%;
        margin: 0 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;        
        border-bottom: 1px solid $gray-3;

        .service-block-title {
            color: $black;
            font-size: 18px;
            line-height: 1.2;
            font-family: $GTMedium;
            display: flex;
            height: min-content;
        }

        .service-block-input {
            height: 50px;
            width: 400px;
            border: solid 1px $gray-2;
            position: relative;
            background-color: $white;
            color: $black;
            font-size: 18px;
            padding-left: 15px;
            font-family: $GTMedium;
        }
    
        .service-block-buttons {
            display: flex;
            justify-content: space-between;
            width: max-content;
            position: relative;
    
            .service-block-button {
                font-size: 13px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border: none;
                background-color: $white;
        
                &:hover {
                    background-color: $gray-3;
                    cursor: pointer;
                }
    
                path {
                    fill: $black-2;
                }
                
                .caret-down {
                    transform: rotate(180deg);
                }
            }

        }
    }
}