.all-plans-container {
    width: 100%;

    .plans-list-container {
        @include width-container(1340px);
        background-color: $white;
        padding: 0 50px 90px 50px;
        margin-top: 10px; 

        .plans-list-content {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
        }

        .plans-list-titles {
            padding: 20px 0;
            border-bottom: 1px solid $gray-3;
            display: grid;
            grid-template-columns: 550px 530px 40px;
            align-items: center;
            color: $gray-black;
            
            .plans-list-title {
                text-transform: lowercase;
                font-size: 13px;
                display: flex;
                align-items: center;

                .arrow-down,
                .arrow-up {
                    width: 6px;
                    height: 6px;
                    margin-left: 10px;
                    margin-top: 7px;
                    transform: translateY(-50%);
                }
              
                .arrow-up {
                  transform: translateY(-3px) rotate(180deg);
                }

            }
        }

        .plan-row {
            position: relative;
            height: 60px;
            border-bottom: 1px solid $gray-3;
            display: grid;
            grid-template-columns: 550px 530px 40px;
            align-items: center;

            .plan-column {
                font-size: 13px;
                display: flex;
                align-items: center;
                width: max-content;
                font-size: 15px;
                color: $gray-black;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
        
            &:hover {
                .view-rate-plan-button {
                    visibility: visible;
                }
            }
        
            .view-rate-plan-button {
                @include button(155px, 40px);
                background-color: $gray-3;
                color: $black;
                visibility: hidden;
            }
        }
    }
}