.paginator-container{
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;

  .pagination-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: $black;
    border-radius: 8px;
    overflow: hidden;


    &:not(:last-child) {
      margin-right: 10px;
    }

    a, span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-14;
    }

    &:hover a, span {
      background-color: $gray-2;
      color: $black;
    }

    &.arrow a, span {
      background-color: transparent;
    }

    .current-page a, span{
      color: $gray-16;
      background-color: transparent;
    }

    .active-item {
      background-color: #f4f4f4;
    }

    &.arrow .active-item {
      background-color: transparent;
    }
  }
}
