.edit-block {
  &-alert {
    width: 100%;
    padding: 18px 50px 18px 40px;
    background-color: $yellow-1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    &-title {
      font-family: $GTMedium;
      font-size: 18px;
    }
    &-buttons {
      display: flex;
    }
    &-cancel, &-save {
      @include button(109px, 44px);
    }
    &-cancel {
      margin-right: 20px;
      background-color: $white;
    }
    &-save {
      background-color: $black;
      color: $white;
    }
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}