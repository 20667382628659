.DayPicker-TodayButton {
  border: none;
  box-shadow: none;
  cursor: pointer;
  background-color: rgba(255, 251, 81, 0.50);
  color: black;
}

/* Default modifiers */

.DayPicker-Day--today {
  background-color: rgb(255, 252, 81);
  color: black;
  font-weight: 700;
  border-radius: 0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: rgba(255, 251, 81, 0.50);
  color: #000;
  border-radius: 0 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: rgba(255, 251, 81, 0.50);
  color: $black !important;
  border-radius: 0 !important;
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgb(255, 252, 81) !important;
  color: #000 !important;
  border-radius: 0 !important;
}

.DayPicker-NavButton {
  width: 30px!important;
  height: 30px!important;
}

.DayPicker-NavButton:hover {
  opacity: 1;
}
