// used in old designs tab
#nfs-modal-window {
    .scroll-content {
        border-radius: 6px;
    }
    .content{
        align-items: normal;
        justify-content: normal;
        width: 640px;
        padding: 55px;
    }

    h1 {
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 0.8px;
        margin-bottom: 30px;
        text-align: center;
    }
    p {
        text-align: left;

        span {
            color: $gray;
        }
    }

    .smiles {
        display: flex;
        margin-top: 20px;
        margin-bottom: 50px;

        & > div {
            display: flex;
            flex-direction: column;
            margin-right: 12px;
            align-items: center;
            color: $gray;
        }
        & > div:hover, .active {
            color: $black;
        }
        & > div:last-of-type {
            margin-right: 0;
        }
        & > div:nth-child(1):hover, div:nth-child(1).active,
        & > div:nth-child(2):hover, div:nth-child(2).active,
        & > div:nth-child(3):hover, div:nth-child(3).active,
        & > div:nth-child(4):hover, div:nth-child(4).active,
        & > div:nth-child(5):hover, div:nth-child(5).active {
            span {
                background-color: $pink;
            }
        }
        & > div:nth-child(6):hover, div:nth-child(6).active,
        & > div:nth-child(7):hover, div:nth-child(7).active {
            span {
                background-color: $yellow;
            }
        }
        & > div:nth-child(8):hover, div:nth-child(8).active,
        & > div:nth-child(9):hover, div:nth-child(9).active,
        & > div:nth-child(10):hover, div:nth-child(10).active {
            span {
                background-color: $green;
            }
        }
        span {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: $gray-5;
            margin-bottom: 5px;
            transition: background-color 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 20px;
            }
        }
    }

    input {
        margin-top: 10px;
        border-color: $gray-6;
        padding: 12px;
        width: 100%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    textarea {
        margin-top: 10px;
        border-color: $gray-6;
        resize: vertical;
        padding: 12px;
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        button:nth-child(n) {
            @include button();
            width: 120px;
            height: 45px;
            text-transform: lowercase;
            border-radius: 6px;
            box-shadow: none;

            &:disabled, &:disabled:hover {
                background-color: $gray;
            }
        }
        button:nth-child(1) {
            @extend .gray-button;
            height: 45px;
        }
        button:nth-child(2) {
            @extend .yellow-button;
            margin-left: 15px;
            box-shadow: none;
            height: 45px;
        }
    }

    .yellow-modal-button {
        @include button();
        @extend .yellow-button;
        width: 120px;
        height: 45px;
        text-transform: lowercase;
        align-self: center;
        margin-top: 25px;
    }

    .submitted-content {
        .result-icon {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: $green;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 10px auto;

            svg {
                width: 15px;

                path {
                    fill: white;
                }
            }
        }
        .result-icon.has-error {
            background-color: $pink;
        }

        p {
            text-align: center;
        }
    }

    .error-message {
        color: $pink;
        font-size: 14px;
        margin-top: 10px;
    }
}
