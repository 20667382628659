@import "fonts";
@import "colors";
@import "mixins";
@import "mixins/index";
@import "presets";
@import "shared/index";
@import "component/index";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
}

*:not(strong) {
  font-weight: inherit;
}

button {
  margin: 0;
}

html,
body {
  font-family: $GTRegular;
}

img {
  max-width: 100%;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: transparent url("../../assets/svg/down-arrow.svg") no-repeat right 10px center;
  background-size: 6px;
}

.m-b-sm{
  margin-bottom: 8px;
}

#drift-widget {
  bottom: 35px!important;
}

iframe[style*="z-index: 2147483647;"]:not([id]) {
  // eslint frame
  //z-index: -1 !important;
}
.dayPickerWrapper{
  .DayPickerInput{
    width: 100%;
  }
  input{
    height: 50px;
    width: 100%;
    margin: 6px 0 5px;
    padding: 4px 0 6px 16px;
    border-radius: 8px;
    border: solid 1px #dfdfdf;
    background-color: #fff;
    cursor: pointer;
    resize: none;
  }

  .DayPickerInput-OverlayWrapper{
    .DayPickerInput-Overlay {
      top: calc(0px - 50px - 6px - 6px); // 0 - (input height + margin top + margin bottom)
      transform: translateY(-100%);
    }
  }
}
