.user-availability {
    position: relative;
    width: 100%;

    div {
        line-height: 24px;
    }

    .info-section {
        padding: 15px;
        background-color: $yellow;

        .info-section-text {
            font-size: 13px;
            text-decoration: none;
            cursor: default;
            line-height: 1.5;
            display: flex;
            text-align: center;
        }
    }

    .availability-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
        width: 100%;

        button {
            width: 100px;
            border: none;
            height: 40px;
            cursor: pointer;
        }
    }

    button {
        width: 100%;
        border: none;
        height: 40px;
        cursor: pointer;
    }

    button.save-changes {
        background-color: $yellow;
        color: $black;
        box-shadow: inset 0 -1px 0 0 #000000;
    }

    p {
        position: absolute;
        bottom: -3px;
        margin: 0!important;
    }

    
    .day-picker {
        width: 100%;
        border: 1px solid $gray-2;
        position: absolute;
        z-index: 2;
        background-color: $white;
        top: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .reset {
            height: max-content;
            display: flex;
            justify-content: center;
            margin-right: 15px;
        }

        span {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        .DayPicker-Day--selected,
        .DayPicker-Day:hover {
            background-color: $pink!important;
            border-radius: 0 !important;
        }
    }
}