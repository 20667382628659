.freelancer-additional-info {
  background-color: $gray-3;
  padding: 50px 0;

  .tabs-and-search {
    @include width-container();

    .tab-items {
      text-transform: lowercase;

      &.active {
        background: $white;
      }
    }
  }

  &-content-wrap {
    @include width-container(1340px);
  }

  &__body-overview-container {
    background-color: $white;
    border-radius: 6px;
    //padding: 40px 50px 50px 50px;
    display: flex;
    flex-direction: column;
    row-gap: 22px;
  }

  .profile-information-header__editButton {
    @include button(53px, 30px, 0, 13px);
    font-family: "GTWalsheimRegular", sans-serif;
    border-radius: 6px;
    color: $gray-black;
    opacity: 1;
    transition: all .3s;
  }
}