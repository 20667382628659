@import './src/assets/styles/mixins/index';

.notes-tab-wrap {
    padding: 25px 50px 60px 50px;
    border-radius: 6px;
    background-color: $white;
    @include width-container();

    .notes-title {
        font-size: 24px;
        font-family: $GTMedium;
        line-height: 60px;
        margin-right: 20px;
    }
    
    .notes-wrap {
        margin: 0 auto;
        max-width: 760px;
        width: 100%;
    }
    
    .add-note {
        width: 100%;
        position: relative;
        margin: 10px 0 40px;
    }
    
    .add-note-button {
        width: 90px;
        border-radius: 6px;
        box-shadow: none;
    }
    
    .horizontal-line {
        width: 100%;
        height: 1px;
        margin: 40px 0;
        background-color: $gray-3;
    }

    .ql-toolbar {
        border-radius: 6px 6px 0 0;
    }
    .ql-container {
        border-radius: 0 0 6px 6px;
        min-height: 100px;
    }

    .input-container {
        position: relative;
        height: 50px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .attached-file {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-left: .75rem;
        left: 0;
        display: flex;
        align-items: center;
  
        span {
          max-width: 500px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
        }
  
        .img {
          padding: 5px;
          cursor: pointer;
  
          img {
            width: 12px;
          }
        }
      }
  
      .upload-file {
        width: 50px;
        height: 50px;
        cursor: pointer;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        position: absolute;
  
        &:after {
          content: '';
          position: absolute;
          width: 1px;
          background-color: #dfdfdf;
          height: 30px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
  
      .upload-file-icon {
        width: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        fill: $black;
      }
  
      .upload-file-input {
        display: none;
      }
  
      .remove-file {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-left: 10px;
        cursor: pointer;
        width: 10px;
      }
  
      .remove-file-icon {
        fill: $pink;
      }
}

.note-modal {
    .edit-note-button {
        width: 90px;
        border-radius: 6px;
        box-shadow: none;
    }

    .note-editor {
        width: 100%;
        padding: 30px 0 0 0;
        margin: 30px 0 0 0;
        border-top: 1px solid $gray-3;
    }

    textarea {
        width: 100%;
        min-height: 160px;
        resize: none;
        border-radius: 6px;
        border: solid 1px $gray-2;
        padding: 15px;
        font-size: 15px;
    }

    .ql-toolbar {
        border-radius: 6px 6px 0 0;
    }
    .ql-container {
        border-radius: 0 0 6px 6px;
        min-height: 100px;
    }

    .input-container {
        position: relative;
        height: 50px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
      }
  
      .message-input {
        height: 50px;
        width: 100%;
        border: 1px solid $gray-2;
        padding: 0 95px 0 71px;
        resize: none;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        color: #c6c6c6;
      }
  
      .attached-file {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-left: .75rem;
        left: 0;
        display: flex;
        align-items: center;
  
        span {
          max-width: 200px;
          @include textOverflowOneLine;
          display: inline-block;
        }
  
        .img {
          padding: 5px;
          cursor: pointer;
  
          img {
            width: 12px;
          }
        }
      }
  
      .upload-file {
        width: 50px;
        height: 50px;
        cursor: pointer;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        position: absolute;
  
        &:after {
          content: '';
          position: absolute;
          width: 1px;
          background-color: #dfdfdf;
          height: 30px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
  
      .upload-file-icon {
        width: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        fill: $black;
      }
  
      .upload-file-input {
        display: none;
      }
  
      .remove-file {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-left: 10px;
        cursor: pointer;
        width: 10px;
      }
  
      .remove-file-icon {
        fill: $pink;
      }
}

.note-block {
    display: flex;
    margin-bottom: 30px;
    
    .avatar {
        width: 50px;
        height: 50px;
        margin: 0 20px 0 0;
        border-radius: 50%;
    }

    .note {
        flex-grow: 1;
        min-height: 136px;
        padding: 15px 10px 30px 30px;
        border-radius: 6px;
        border: solid 1px $gray-2;

        .note-header {
            font-size: 15px;
            font-family: $GTMedium;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            .name {
                font-weight: 500;
                
            }

            .right-side {
                display: flex;
                align-items: center;
                align-content: center;

                .date {
                    color: $gray;
                    margin: 0 15px 0 20px;
                }
            }
            
        }

        .content {
            margin-right: 20px;
            font-weight: normal;
            color: $gray-black;
        }

        .attachment {
          display: flex;
          color: $black;
          margin-top: 10px;
          text-align: center;
          padding: 10px 15px;
          align-items: center;
          text-decoration: none;
          background-color: $white;
  
          .icon-file {
            width: 15px;
            margin-right: 10px;
            path {
              fill: $green;
            }
          }
        }
    }
}