@import "./src/assets/styles/mixins/responsive";
.custom-tabs {
  .card-content {
    background-color: $white;
    padding: 0 30px 15px;
    width: 100%;
  }

  // TODO remove
  .table-item {
    cursor: pointer;
    font-size: 15px;
    color: $gray-black;
    transition: background-color .3s;
    border-top: 1px solid $gray-3;

    td:first-child{
      padding-left: 10px;
    }

    &:hover {
      background-color: $gray-3;
      color: $black;

      .date {
        color: $black;
      }
    }
  }

  .deadline {
    position: relative;
    line-height: 1.5;
    height: fit-content;
    padding-bottom: 20px;
  }

  .arrow-down,
  .arrow-up {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
    width: 8px;
  }

  .arrow-up {
    transform: translateY(-3px) rotate(180deg);
  }

  .paginator-container {
    padding: 30px 0;
    background-color: $white;
  }

}

.tabs-and-search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0 0;
  position: relative;
  background: $gray-14;

  .search-container {
    margin-left: auto;
    right: 60px;
    position: absolute;
    top: 40px;
    height: 40px;
    @include media(mobile){
      display: none;
    }
  }
  a {
    text-decoration: none;
  }
}

.messages {
  padding-right: 10px;
  position: relative;
}
