@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/mixins';

.freelancer-item {
  width: 278px;
  border: 1px solid $gray-2;
  box-shadow: $box-shadow;
  border-radius: 8px;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 230px;

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 27px;
    top: -43px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      text-align: center;
      font-size: 11px;
    }
  }

  .freelancer-info {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    height: auto;
    align-items: center;
    border: 0;
    background: transparent;

    &.extra-padding {
      padding: 8px 8px 0 0;
    }
  }

  .freelancer-name {
    color: $black;
    text-decoration: none;
    font-size: 17px;
    font-family: $GTMedium;
    line-height: 1.76;
    margin: 20px 0 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  .freelancer-slogan {
    font-size: 15px;
    line-height: 1.47;
    color: $gray;
    font-style: oblique;
    min-height: 60px;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-3;
    width: 100%;
    overflow-y: auto;
  }

  .card {
    width: 307px;
    height: 298px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    padding: 27px;
    border: solid 3px transparent;
    border-radius: 8px;

    // TODO check
    .badge {
      line-height: 24px;
      height: 24px;
      border-radius: 20px;
      background-color: $gray-3;
      padding: 0 15px;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: $black;
      margin: 0 2.5px 5px;
      &-circle {
        margin: 5px;
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: $white;
        border: solid 1px $gray-2;
        &:before {
          content: "";
          position: absolute;
          visibility: hidden;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $white;
          left: 50%;
          z-index: 100;
          top: 0;
          transform: translateX(-50%);
        }
        &:hover {
          &:before, .badge-tooltip {
            visibility: visible;
          }
        }

        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          width: 14px;
          height: 14px;
          transform: translateX(-50%) translateY(-50%);
        }

        &.Translating {
          svg{
            width: 18px;
            height: 18px;
          }
        }
      }
      &-tooltip {
        position: absolute;
        visibility: hidden;
        border-radius: 20px;
        font-size: 13px;
        background-color: $white;
        padding: 9px 15.5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        top: -30px;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        text-transform: lowercase;
      }
    }

    .option-tooltip-container {
      margin: 0;
      .option-item-tooltip {
        width: 175px;
        .option-item-tooltip-button {
          letter-spacing: -0.3px;
        }
      }
    }
  }
  .active-freelancer{
    border-color: $yellow;
    transition: all 0.3s ease;
  }

  .button-container{
    width: 100%;
    img{
      width: 10px;
      margin-right: 10px;
      filter: brightness(0);
    }
    button{
      height: 30px;
      padding: 4px 22px 6px 20px;
      border-radius: 6px;
      background-color: $yellow-1;
      line-height: 1.54;
      letter-spacing: -0.3px;
      font-size: 13px;
      border: 0;
      &:hover {
        background-color: $yellow-2;
      }
    }
  }
}

.inactive-freelancer {
  opacity: 0.5;
  .disabled{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 998;
  }
}

.chosen-freelancer{
  .card{
    border-color: $yellow;
  }
}
