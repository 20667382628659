@import './src/assets/styles/colors';

.get-started-page {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
}

.get-started-content {
    @include width-container();
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    & > svg{
        display: none;
    }

    .text-block {
        @include width-container(500px, 50px 0 0 140px);
    }

    .logo {
        height: 50px;
        width: auto;
        margin-bottom: 27px;
    }
    .title {
        margin-bottom: 44px;
        font-weight: 500;
        font-size: 50px;
        line-height: 50px;
        font-family: $GTMedium;
        letter-spacing: -2px;
    }

    .text {
        font-size: 17px;
        line-height: 30px;
        margin-bottom: 29px;

        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    ul {
        list-style: none;
        li::before {
            content: '✓';
            padding-right: 8px;
          }
    }

    .warning-message {
        align-items: baseline;

        .warning-icon {
            flex-shrink: 0;
        }

        .warning-title {
            font-size: 15px;
            line-height: 22px;
        }
    }

  .warning-title {
    font-size: 15px;
    line-height: 22px;
  }

    .go-btn {
        @include button(157px, 60px);
        border-radius: 8px;
        margin-top: 52px;
        background-color: #ffe403;
        box-shadow: none;
        text-transform: none;
    }

    a {
        text-decoration: none;
    }

    .slider-wrapper {
        margin-top: 136px;
        margin-right: 60px;
        width: 340px;
        position: relative;

        .arrow {
            position: absolute;
            width: 40px;
            height: 40px;
            border: 1px solid #eaeaea;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
                content: none;
            }

            svg {
                fill: #eaeaea;
            }

            &.slick-next {
                top: unset;
                bottom: -80px;
                right: 125px;
                background-color: #FFF;
            }

            &.slick-prev {
                top: unset;
                bottom: -80px;
                left: 125px;
                background-color: #FFF;
            }
        }

        .slick-list {
            background: $white;
        }
    }
    .testimonial {
        margin-top: 45px;
        width: 340px;
        height: 356px;
        height: 410px;
        padding: 0 40px 31px;
        border: solid 1px #ebebeb;
        background-color: #FFF;

        .autor-avatar {
            position: absolute;
            top: 0;
            left: calc(340px / 2 - 90px / 2);
            width: 90px;
            height: 90px;
            border-radius: 50%;
            border: 5px solid white;
            background-color: khaki;
            box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.1);
            // transform: translate(88px, -50px);
        }

        .text-block {
            margin: 0;
            margin-top: 60px;
            display: flex;
            flex-flow: column;

            .fullname {
                font-family: $GTMedium;
                font-size: 17px;
                line-height: 18px;
                letter-spacing: -0.34px;
                text-align: center;
            }

            .role {
                margin-bottom: 10px;
                font-size: 15px;
                line-height: 30px;
                letter-spacing: -0.3px;
                text-align: center;
            }

            .text {
                font-size: 15px;
                line-height: 30px;
                line-height: 2;
                letter-spacing: -0.3px;
                text-align: center;
                margin-bottom: 15px;
                height: 180px; // max height of 3 elements
            }
        }

        .clients {
            text-align: center;
            .clients-title {
                font-size: 14px;
                color: #9e9e9e;
                text-align: center;
            }
            .clients-list {
                margin-top: 10px;
                display: flex;
                justify-content: center;
            }

            .clients-list-item {
                height: 50px;
            }
        }
        .client-palceholder {
            width: 40px;
            height: 40px;
            border: 1px solid #ebebeb;
            border-radius: 50%;
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0px;
            }
        }
    }
}
