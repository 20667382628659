.project-progress-bar {
  display: flex;
  flex-direction: column;

  .bar-wrapper {
    display: flex;
    .bar-item {
      margin-right: 5px;
      width: 10px;
      height: 3px;
      border-radius: 1px;
      display: block;
      background-color: #dfdfdf;
      &.active {
        background-color: #82e190;
      }

      &.orangeBg {
        background-color: $orange-2;
      }
    }
  }
}
