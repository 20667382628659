.freelancer-details-tab-wrap {
  padding: 25px 50px 60px 50px;
  border-radius: 6px;
  background-color: $white;
  @include width-container();
}

.freelancer-details {
  border-bottom: 1px solid $gray-3;
  height: 61px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  // padding: 5px 0;
  &-top {
    border-top: 1px solid $gray-3;
  }

  &-tab {
    &-wrap {
      display: flex;
      justify-content: space-between;

      &.evaluation-tab {
        justify-content: space-between;
        flex-direction: column;
        
        &_list {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .header-block {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .warning-message {
        margin-top: 0;
        margin-left: 12px;
      }
    }

    .personal-title, .company-title {
      font-size: 24px;
      line-height: 2.5;
      color: $black;
      font-weight: 500;
      margin-right: 10px;
      font-family: "GTWalsheimMedium", sans-serif;
    }

    .left-block {
      margin-right: 50px;
    }

    .left-block, .right-block {
      width: 50%;

      &_withGap {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
      }
    }

    .input {
      padding: 15px;
      border: 1px solid $gray-2;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 24px;
      color: $black;

      .custom-header {
        width: 100%;
        justify-content: space-between;
      }

      &.error {
        border-color: $pink;
      }
    }
  }

  &-personal-title {
    color: $gray-black;
    width: 30%;
    margin-right: 32px;
    font-size: 15px;
  }

  &-title {
    color: $gray-black;
    width: 60%;
    margin-right: 32px;
    font-size: 15px;
  }

  &-description {
    color: $black;
    line-height: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow-wrap: anywhere;
  }
}