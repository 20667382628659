@font-face {
  font-family: GTWalsheimRegular;
  src: url("../fonts/GTWalsheim/GTWalsheimRegular.otf");
  font-weight: normal;
}

@font-face {
  font-family: GTWalsheimMedium;
  src: url("../fonts/GTWalsheim/GTWalsheimMedium.otf");
  font-weight: normal;
}

@font-face {
  font-family: GTWalsheimRegularOblique;
  src: url("../fonts/GTWalsheim/GTWalsheimRegularOblique.otf");
  font-weight: normal;
}

@font-face {
  font-family: GTWalsheimLightOblique;
  src: url("../fonts/GTWalsheim/GTWalsheimLightOblique.otf");
  font-weight: normal;
}

$GTRegular: "GTWalsheimRegular", sans-serif;
$GTMedium: "GTWalsheimMedium", sans-serif;
$GTRegularOblique: "GTWalsheimRegularOblique", sans-serif;
$GTLightOblique: "GTWalsheimLightOblique", sans-serif;