.financial-tab-price {
  .modal-structure {
    width: inherit;

    &-header {
      &-subTitle {
        color: $gray-black;
        font-size: 15px;
        span {
          color: $black;
        }
      }
    }

    &-discount {
      color: $orange-2;
    }

    &-body {
      width: 500px;
      background-color: $gray-3;
      //padding: 25px 0;

      &-table {
        //margin-bottom: 25px;
        border-top: 1px solid $gray-2;
        &-row {
          display: flex;
          padding: 15px 23px;
          justify-content: space-between;
          border-bottom: 1px solid #dfdfdf;
        }
        &-cell {
          //flex: 1 1 150px;
          &-center {
            text-align: center;
          }
          &-end {
            text-align: end;
          }
        }
        &-label {
          color: $gray-black;
          font-size: 15px;
        }
      }

      &-totalPrice {
        font-size: 18px;
      }
      &-subTotal {
        font-size: 16px;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 22px 15px 25px;
        border-top: 1px solid $gray;
        &-label {
          color: $gray-black;
          font-size: 14px;
          &-input {
            border: none;
            background-color: transparent;
            color: $black;
            font-size: 15px;
            width: min-content;
            text-align: end;
          }
          &-bold {
            color: $black;
            font-size: 18px;
          }
        }
        &:last-child {
          border-top: 1px solid $black;
        }
      }
    }
  }
}