.evaluating-block {
  width: 100%;

    &_header {
      width: 100%;
      padding: 15px 0;
      display: grid;
      grid-template-columns: auto 150px 1fr;
      align-items: center;

      &_icon {
        margin-right: 15px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #C6C6C6;
        background-color: #F4F4F4;

        &.positive {
          background-color: #82E190;
          svg {
            width: 10px;

            path {
              fill: #FFFFFF;
            }
          }
        }

        &.negative {
          background-color: #E77074;
          svg {
            width: 8px;

            path {
              fill: #FFFFFF;
            }
          }
        }
      }
      &_title {
        font-size: 19px;
        line-height: 30px;
        letter-spacing: -0.285px;
      }
      &_subtitle {
        margin-right: auto;
        width: 100%;
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: #ABABAB;
        word-wrap: anywhere;
      }
      .warning-message {
        margin: 0 23px 0 auto;
        grid-column: 4/5;
      }
      &_action-btn {
        margin-left: auto;
        padding: 9px 30px 12px;
        border: none;
        cursor: pointer;
        border-radius: 8px;
        grid-column: 5 / 6;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFE403;
      }
    }

  &_body {
    margin-left: 55px;
    padding: 15px 0;
    border-top: solid 1px #EFEFEF;
    display: grid;
    grid-template-columns: 3% 3% 13% auto;
    column-gap: 10px;
    align-items: center;

    &_icon {
      margin-right: 6px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #82E190;
      &.positive {
        background-color: #82E190;
      }
      &.neutral {
        background-color: #FFE403;
      }
      &.negative {
        background-color: #FF9751;
      }

      svg {
        width: 7px;
      }
    }
    &_value {
      margin-right: 15px;
      font-size: 13px;
      line-height: 30px;
      &.positive {
        color: #82E190;
      }
      &.neutral {
        color: #FFE403;
      }
      &.negative {
        color: #FF9751;
      }
    }
    &_title {
      margin-right: 74px;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.3px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
    &_comment {
      font-weight: 800;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: #ABABAB;
    }
  }
}
