@import './src/assets/styles/mixins';
@import './src/assets/styles/mixins/responsive';
.close-button-modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    display: none;

  &:not(.with-visible-scroll) {
    .scroll-content {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

    .modal {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 10px 0;
    }

    .close-button {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-black;
        border-radius: 6px;
        cursor: pointer;
        @include media(mobile){
          width: 40px;
          min-height: 40px;
          max-height: 40px;
          margin: 10px 0;
        }
        flex-shrink: 0;
      }

      .close-icon {
        width: 12px;
        fill: $white;
      }

      .scroll-content {
        background-color: white;
        border-radius: 8px;
        height: max-content;
        overflow: auto;
        @include media(mobile){
          width: 100%;
        }
      }

      .custom-select {
        border-radius: 6px;
        width: 100%;
        height: 40px;
        border: 1px solid $gray-2;
        padding: 0 15px;
        margin-bottom: 15px;
      }

      .multiselect-dropdown {
        border-radius: 6px;
      }

      .custom-dropdown {
        border: 1px solid $gray-2;
        margin-bottom: 15px;
        border-radius: 6px;
      }

      .multiselect-header,
      .custom-header {
        border-radius: 6px;
      }

      .body, .custom-items {
        margin-top: 10px;
        position: absolute;
        top: 100%;
        border-radius: 6px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

        &::-webkit-scrollbar {
					width: 16px;
					padding: 0 10px;
          border-radius: 6px;
				}

				&::-webkit-scrollbar-track {
					background-color: $white;
          border-radius: 6px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 20px;
					background-color: $gray-2;
					max-height: 68px;
					min-height: 40px;
					width: 6px;
					border: 5px solid $white;
					cursor: pointer;
				}

        // TODO Check this styles
        .multiselect-search {
          max-width: 340px;
        }
      }

      .content {
        margin: 45px 60px 20px 60px;
        max-width: 500px;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .description-modal{
          font-family: $GTRegular;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #898989;
          margin-top: 20px;
        }
      }

      &.isVisible {
        display: flex;
      }

      .modal-buttons {
        display: flex;
        justify-content: center;
        button.confirm-button,
        .cancel-button {
          @include button(120px, 50px);
          border-radius: 6px;
          box-shadow: none;
          &:first-child {
            margin-right: 15px;
          }
        }

        .modal-upload-file{
          margin-right: 15px;

          & ~ .confirm-button{
            margin-right: 15px;
          }
        }

        .confirm-button {
          @extend .yellow-button;
          box-shadow: none;
          border-radius: 6px;
        }
        .cancel-button {
          background-color: $gray-3;
          border-radius: 6px;
        }
      }
      h2,
      .title-modal {
        font-size: 22px;
        line-height: 1.36;
        margin: 0 auto 30px;
      }

      h2,
      .title-modal,
      .sub-title-modal {
        font-family: $GTMedium;
        max-width: 350px;
        text-align: center;
      }

      .sub-title-modal {
        font-size: 16px;
        margin: 0 auto 15px;
        line-height: 1.5;
      }

      .sub-title-gray {
        font-size: 15px;
        line-height: 2;
        color: $gray-black;
        margin-bottom: 41px;
      }

      .multi-step {
        width: 100%;
      }

      input, .custom-dropdown,
        .multiselect-dropdown, .filter-label {
        border-radius: 6px;
      }

      .form-check {
        .custom-checkbox {
          &:before {
            border-radius: 4px;
          }
        }
      }

      .warning-title {
        white-space: nowrap;
      }
  }
