.search-container {
  position: absolute;
  right: 0;
  max-width: 345px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.search-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 15px;
  transition: width .3s;
  margin-left: 16px;

  &.active {
    margin-right: 0;
    max-width: 350px;
    min-width: 200px;

    .show-search {
      position: absolute;
      margin: auto;
      border: none;
    }
    .search-icon {
      fill: $black;
    }
    .search-input {
      display: block;
    }
  }

  .show-search {
    background-color: transparent;
    cursor: pointer;
    border: none;
    width: 15px;
    height: 15px;
    z-index: 2;
    right: 16px;
    top: 0;
    bottom: 0;
    padding: 0;
    outline: none;
    flex-shrink: 0;
    &:hover {
      .search-icon {
        fill: $black;
      }
    }

  }

  .search-input {
    display: none;
    height: 40px;
    width: 100%;
    padding: 6px 16px;
    letter-spacing: 1px;
    outline: none;
    border: 1px solid $gray-2;
    background-color: transparent;

    &::-webkit-input-placeholder {
      color: $black;
    }
    &::-moz-placeholder {
      color: $black;
    }
    &:-ms-input-placeholder {
      color: $black;
    }
    &:-moz-placeholder {
      color: $black;
    }
  }

  .search-icon {
    fill: $black-3;
  }
}
