.archived-plans {
  background-color: $white;
  padding-bottom: 25px;
  width: 100%;
  &-header {
    padding: 35px 40px;
    span {
      font-size: 22px;
      font-family: $GTMedium;
    }
  }
  &-icon {
    width: 22px;
    height: 22px;
    padding: 0 6px;
    border-radius: 20px;
    //background-color: #ff9751;
    background-color: $gray;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-link {
    width: 95%;
    display: flex;
    text-decoration: none;
  }

  &-list {
    padding: 0 35px;
    margin-bottom: 30px;
    &-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray-3;
      border-top: 1px solid $gray-3;
      padding: 20px 65px 20px 25px;
      span {
        font-size: 15px;
        font-family: $GTRegular;
        //font-weight: bold;
        &:first-child {
          width: 65%;
        }
        &:last-child {
          margin-left: 78px;
        }
      }
    }
    &-item {
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 10px 25px;
      border-bottom: 1px solid $gray-3;
      cursor: pointer;
      &:hover {
        background-color: $gray-3;
        .dot-button {
          visibility: visible;
        }
      }
      &-title {
        font-family: $GTRegular;
        font-size: 15px;
        color: $black;
      }
      .hover-button {
        position: relative;
        display: flex;
        justify-content: flex-end;
      }
      .dot-button {
        cursor: default;
        min-width: 40px;
        min-height: 40px;
        transition: background-color .3s;
        background-color: $white;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        visibility: hidden;

        &:hover+.sub-menu {
          visibility: visible;
        }
      }
      .sub-menu {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        visibility: hidden;
        z-index: 1;

        width: 240px;
        padding: 10px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px $gray-2;
        background-color: $white;

        &:hover {
          visibility: visible;
        }
        &-item {
          display: flex;
          align-items: center;
          padding: 0 15px;
          width: 100%;
          cursor: pointer;
          &:hover {
            transition: background-color .3s;
            background: $gray-3;
          }
          span {
            width: 14px;
            height: 14px;
            transform: translate(0, -2px);
          }
          p {
            line-height: 40px;
            font-size: 15px;
            margin-left: 15px;
            font-family: $GTRegular;
            color: $black;
          }
        }
      }
    }
  }
  &-left, &-right {
    position: relative;
    display: flex;
    align-items: center;
  }
  &-left{
    width: 65%;
  }
  &-right {
    width: 35%;
    justify-content: space-between;
    &-date {
      span {
        font-size: 15px;
        font-family: $GTRegular;
        color: $gray-black;
        &:first-child {
          margin-right: 75px;
        }
      }
    }
  }
}
