@import './src/assets/styles/colors';

.basic-tooltip {
    position: relative;
    z-index: 6;

    .content {
        visibility: hidden;
        position: absolute;
        box-shadow: $box-shadow-6;
        border-radius: 8px;
        background: $white;
        padding: 10px 15px;
        min-width: 150px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        left: 50%;
        transform: translate(-50%, -100%);
        top: -10px;

        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
          }

        .title {
            color: $gray;
        }

        .text {
            color: $black;
        }
    }

    &:hover {
        cursor: default;
        .content {
            visibility: visible;
        }
    }
}
