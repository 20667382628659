#root .df {
  display: flex;
}
#root .jcsb {
  justify-content: space-between;
}
#root .jsfe {
  justify-content: flex-end;
}
#root .whp {
  white-space: pre;
}
#root .tou {
  text-overflow: unset;
}
#root .cp {
  cursor: pointer;
}
#root .german-lan {
  *, *:before, *:after {
    text-transform: none;
  }

  a {
    text-transform: none;
  }
}

#roof {
  position: relative;
}