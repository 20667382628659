.accordion {
  width: 100%;
  border-top: solid 1px #EFEFEF;

  &:last-of-type {
    border-bottom: solid 1px #EFEFEF;
  }

  &_header {
    width: 100%;
    display: flex;
    align-items: center;

    &-btn {
      margin-left: 36px;
      border: none;
      border-radius: 8px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F4F4F4;

      &-arrow {
        transition: all .3s;

        svg {
          width: 40%;
        }

        &.reverted {
          margin-top: -2px;
          transform: rotate(180deg);
          transition: all .3s;
        }
      }
    }
  }

  &_body {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease 0.4s;

    &.expanded {
      max-height: fit-content;
      transition: max-height ease 0.4s;
    }
  }
}
