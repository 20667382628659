.custom-dropdown {
    height: 40px;
    position: relative;
    color: $black;
    padding-left: 10px;
    font-size: 13px;

    .custom-checkbox {
        width: 100%;
    }

    label {
        font-size: 13.3px;
    }

    &:hover {
        cursor: pointer;
    }

    .custom-header {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
    }

    .custom-arrow-down {
        width: 8px;
    }

    .custom-items {
        background-color: $white;
        display: none;
        border-radius: 8px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        left: auto;
        margin-top: 10px;
        max-width: 100%;
        min-width: 190px;
        padding: 15px;
        right: 0;
        position: absolute;
    }

    .isOpen {
        display: block;
        overflow-y: auto;
        max-height: 200px;
        z-index: 9999; // same as multiselect dropdown
        width: 100%;
        min-width: max-content;
    }

    .single-select-search{
        width: calc(100% - 15px);
        margin: 10px;
        padding-left: 13px;
        height: 40px;
        border: 1px solid $gray-2;
        color: #000;
        font-size: 15px;
        border-radius: 8px;
    }
}
