@import './Filter/index';

.reviews-tab {
    margin-bottom: 73px;

    &__preloader {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;       
    }
    
    .reviews-container {
        padding: 30px 25px 73px;

        .reviews_filters__preloader {
            // 40px - dropdown height
            // 10px - filters list padding-bottom
            // 20px - padding-bottom all filters block
            // 30px * 2 - margin all filters block
            $preloader-height: calc(
                    40px + 10px + 20px + 30px * 2
            );
            height: $preloader-height;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}