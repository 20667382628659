@import './src/assets/styles/mixins/responsive';
.registration-steplist-page {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
}

.registration-steplist-content {
    @include width-container(1340px);
    position: relative;
    margin-bottom: 80px;

}
.logout-btn {
    @include button(87px, 44px);
    border-radius: 8px;
    position: absolute;
    text-transform: none;
    top: 77px;
    right: -10px;
    color: #6f6f6f;
    z-index: 1;
}

.stepper {
    @include width-container(500px, 80px 0 0 140px);

    .divider {
        margin: 52px 0 40px;
        border: none;
        width: 100%;
        height: 1px;
        background-color: #f2f2f2;
    }

    .nav-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .submit-btn {
        @include button(163px, 63px);
        background-color: #ffe403;
        box-shadow: none;
        text-transform: none;
        border-radius: 8px;

        &:disabled {
            opacity: 0.3;
        }
    }

    .prev-btn {
        border: none;
        height: fit-content;
        width: fit-content;
        font-size: 15px;
        font-family: $GTMedium;
        text-decoration: underline;
        color: #6f6f6f;
        background: none;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }

        &_notVisible{
            display: none;
        }
    }

    &_background {
        position: fixed;
        right: 50px;
        transform: translate(50px, -50%);
        top: 50%;
        width: 550px;
        @include media(mobile){
            display: none;
        }
    }
}

.stepper-nav-bar {
    display: flex;
    margin-bottom: 50px;
}

.stepper-nav-item {
    margin-right: 15px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    color: #c6c6c6;
    font-size: 15px;
    font-size: $GTMedium;
    cursor: default;


    &.substep,
    &.substep-main {
        display: none;
        width: 10px;
        height: 10px;
        align-self: center;
    }
}

.stepper-nav-item-group {
    display: flex;
    &.active {
        div {
            background-color: $black;
            color: $white;
        }
        .substep-main {
            display: block;
        }
    }

    &.active + &.substep {
        .substep {
            display: block;
        }
    }

    &.substep ~ &.active ~ &.substep {
        .substep {
            display: block;
        }
    }
    &.completed {
        div {
            background-color: #ffe403;
            cursor: pointer;

            svg {
                fill: $black;
            }
        }
    }

    svg {
        width: 12px;
    }
}

.step-title {
    margin-bottom: 24px;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -2px;
    font-family: $GTMedium;
    color: $black;
}

.step-text {
    margin-bottom: 22px;
    font-size: 17px;
    line-height: 30px;
    color: $black;
}

.add-another-button{
    padding: 5px 10px;
    font-family: "GTWalsheimRegular", sans-serif;
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: -0.3px;
    color: $black;
    border: none;
    border-radius: 6px;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    & > svg{
        margin-right: 7px;
    }
}

.profile-pic-step {
    .form-group {
        display: flex;
        // align-items: center;
    }
    .user-pic {
        position: relative;
        border-radius: 50%;
        width: 160px;
        height: 160px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-color: #f2f2f2;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            text-align: center;
            font-size: 11px;
        }
    }

    .upload-block {
        margin-top: 28px;

        .pic-btns {
            margin-bottom: 18px;
            display: flex;
            align-items: center;

            .pic-btn {
                @include button(auto, 44px);
                margin-right: 20px;
                padding: 0 20px;
                border-radius: 8px;

                svg {
                    width: 9px;
                    height: 9px;
                    margin-right: 11px;
                    path {
                        fill: #ffffff;
                    }
                }
            }

            .pic-del-btn {
                font-family: $GTMedium;
                font-size: 15px;
                color: #6f6f6f;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .warning-message {
            margin-bottom: 12px;
        }

        .max-size {
            font-size: 13px;
            line-height: 22px;
            color: #c6c6c6;
        }
    }
}

.showcase-step {
    .language-pairs-title{
        margin-bottom: 10px;
    }
    .upload-btn-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 11px;
    }
    .upload-btn {
        width: 100%;
        height: 50px;
        text-align: center;
        background: $gray-3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        line-height: 30px;
        font-family: $GTMedium;
        cursor: pointer;
        border-radius: 8px;

        svg {
            width: 9px;
            height: 9px;
            margin-right: 10px;
            path {
                fill: #6f6f6f;
            }
        }
    }
    .divider {
        margin: 32px 0 42px;
    }

    .sub-title {
        margin: 31px 0 23px;
        font-size: 20px;
        line-height: 30px;
        font-family: $GTMedium;
    }

    .uploaded-item {
        min-height: 50px;
        display: flex;
        align-items: center;
        border: 1px solid #dfdfdf;
        padding: 8px 20px 10px 18px;
        border-radius: 8px;
        margin-bottom: 10px;

        .title {
            max-width: 80%;
            line-height: 30px;
            font-size: 15px;
            word-break: break-all;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
        }

        .document-svg {
            width: 20px;
            height: 26px;
            margin-right: 15px;

            path {
                fill: #ffe403;
            }
        }

        .cross-svg {
            width: 10px;
            height: 10px;
            margin-left: auto;
            cursor: pointer;
        }

        .download-svg {
            margin-left: auto;
            width: 14px;
            height: 19px;
            cursor: pointer;
        }
    }

    .form-group {
        margin-top: 40px;
    }

    .upload-assessment {
        @include button(100%, 50px);
        margin-bottom: 10px;
        background-color: #f2f2f2;
        text-transform: capitalize;

        svg {
            margin-right: 10px;
            width: 9px;
            height: 9px;
            path {
                fill: #6f6f6f;
            }
        }
    }

    .content {
        margin: 45px 60px 50px;
        max-width: 500px;

        .add-link-form {
            width: 340px;

            .error-message {
                color: $pink;
                font-size: 14px;
                margin-top: 10px;
            }
        }

        .form-group {
            margin-top: 0;
            margin-bottom: 30px;

            .modal-input {
                margin-top: 6px;
                padding: 0 10px;
                border: 1px solid #dfdfdf;
                height: 40px;
                width: 100%;
                color: #000;

                &.error {
                    text-align: left;
                    border-color: $pink;
                }
            }
        }
    }
    .showcase-toggle-block {
        margin: 31px 0 31px;
        padding: 40px 0;
        border: 1px solid #f2f2f2;
        border-left: none;
        border-right: none;
        display: flex;
        align-items: center;
        &>.label {
            margin-right: 91px;
            font-size: 15px;
            font-family: $GTMedium;
        }
        &>.form-group {
            margin-top: 0;
            margin-right: 29px;
            display: flex;
            align-items: center;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .radio-button {
        input:checked + span {
            border-color: none;
            background-color: #ffe403;
        }
        input:checked + span:after {
            top: 11px;
            left: 11px;
            background-color: $black;
        }

        span {
            margin-right: 10px;
            width: 24px;
            height: 24px;
        }
    }
    .error {
        color: $pink;
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: center;
    }
}

.confirmation-page-wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;

    .confirmation-page-content {
        position: relative;
        @include width-container();
        display: flex;
        justify-content: space-between;
        margin-bottom: 142px;

        .reviews-tab__preloader {
            margin: auto;
        }

        .text-block {
            @include width-container(500px, 180px 0 0 140px);
        }
    }

    .avatar {
        width: 100px;
        height: 100px;
        clip-path: circle(50%);
    }

    .avatar-placeholder {
        margin-bottom: 32px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: coral;
    }

    .verify-logo{
        width: 40px;
        margin-bottom: 32px;
    }

    .step-title {
        letter-spacing: 0;
    }

    .step-text {
        margin-bottom: 31px;
    }

    .divider {
        margin-top: 40px;
        margin-bottom: 40px;
        border: none;
        height: 1px;
        background-color: #f2f2f2;
    }

    .view-btn {
        @include button(181px, 60px);
        background-color: #ffe403;
        box-shadow: none;
        text-transform: none;
        border-radius: 8px;
    }

    .contacts-list {
        display: flex;

        .contact-item {
            @include button(240px, 40px);
            color: $black;
            margin-right: 20px;
            background-color: #f2f2f2;
            border-radius: 8px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
