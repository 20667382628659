.impersonate-notification{
  width: 100%;
  font-size: 15px;
  background-color: $orange-2;

  .container {
    height: 60px;
    padding: 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user-icon {
    width: 12px;
    path {
      fill: $white;
    }
    margin-right: 11px;
  }

  .first-text {
    color: $white;
    font-family: $GTRegular;
  }
  .unimpersonate-button {
    cursor: pointer;
    color: $white;
    background-color: $black;
    border-radius: 6px;
    border: none;
    padding: 8px 10px;
  }
}
.hide{
  display: none;
}