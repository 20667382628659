@import "./src/assets/styles/mixins/responsive";
.project-header {
  width: 100%;
  background-color: $gray-14;
  overflow: hidden;


  .width-container {
    width: 100%;
    padding: 0 60px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(tablet){
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
      height: auto;
    }
    @include media(mobile){
      flex-direction: column;
      padding: 0 20px;
      align-items: flex-start;
      height: auto;
    }
  }

  .project-info-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $GTMedium;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $black;
    @include media(mobile){
      align-items: flex-start;
      margin-top: 20px;
    }
  }

  .link-back {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    text-decoration: none;
    margin-right: 30px;
    border: none;
    cursor: pointer;
    background-color: $white;
    border-radius: 8px;
    @include media(mobile){
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
  }

  .arrow-back-icon {
    width: 8px;
    fill: $black;
  }

  .title {
    display: flex;
    margin-bottom: 7px;
  }

  .client-info-wrap {
    display: flex;
    align-items: flex-start;
  }

  .title-avatar {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
    border: 1px solid $gray-2;
  }

  .title-info {
    color: $black;
    font-size: 27px;
    line-height: 1.2;
    font-family: $GTMedium;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 650px;
    white-space: nowrap;
    @include media(mobile){
      font-size: 25px;
      white-space: break-spaces;
    }
  }

  .description-code-info {
    color: $gray-7;
    font-size: 13px;
    margin-top: 5px;
    @include media(mobile){
      margin-top: 10px;
      .code-type{
        display: none;
      }
    }

    .code {
      color: $gray-black;
    }
  }

  .urgent-label {
    font-size: 14px;
    background-color: $orange-2;
    padding: 3px 14px;
    border-radius: 10px;
    margin-top: 10px;
    display: inline-block;
    font-family: $GTRegular;
    height: 24px;
    line-height: 16px;
    margin-left: 10px;
    font-size: 14px;
    color: black;
  }

  &.transparent-header {
    background-color: unset;

    .width-container {
      max-width: 1320px;
    }

    .link-back {
      border-radius: 8px;
      background-color: #fff;
    }

    .project-info {
      display: flex;
      flex-direction: column;

      .title-info {
        margin-right: 20px;
        width: 100%;
      }

      .code-type {
        display: none;
      }

      .code {
        letter-spacing: -0.3px;
        color: #a9a9a9;
        text-transform: uppercase;
      }
    }
  }

  .editProjectTitleLabel{
    font-family: $GTRegular;
    font-size: 15px;
    letter-spacing: -0.3px;
    color: $gray-black;
    display: block;
    margin: 0 auto 0 0;
  }

  .editProjectTitleInput{
    width: 380px;
    height: 40px;
    padding: 7px 15px;
    border-radius: 8px;
    border: solid 1px $gray-2;
    margin: 7px 0 40px 0;
  }

  .title-info-wrapper{
    display: flex;
    gap: 20px;

    .title-info-edit-button {
      background-color: $white;
      border: none;
      border-radius: 6px;
      font-family: $GTRegular;
      font-size: 13px;
      color: $black;
      text-transform: lowercase;
      cursor: pointer;
      height: 30px;
      padding: 0 10px;
      opacity: 0;
      transition: all .3s ease;
      transform: translateY(2px);
    }
  }

  .project-info:hover .title-info-edit-button{
    opacity: 1;
  }
}
