.modal-overlay .scroll-content {
	overflow: visible;
	.content {
		position: relative;
		padding: 45px 60px;

		.change-plan-modal {
			display: flex;
			flex-direction: column;
			text-align: center;
			width: 100%;
			//padding: 40px 60px 50px;

			.month-options {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;

				.month-options-item {
					display: flex;
					align-items: center;
					width: 170px;
				}

				.month-options-label {
					color: $black;
					font-size: 14px;
					margin-left: 10px;
				}

				.radio-button {
					cursor: pointer;

					input:checked + span:after {
						width: 3px;
        				height: 3px;
						background-color: $black;
						top: 50%;
						bottom: 50%;
						left: 50%;
						right: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			.custom-input {
				width: 380px;
				height: 40px;
				border: 1px solid $gray-2;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				margin-bottom: 15px;
			}

			.custom-select {
				width: 380px !important;
				height: 40px;
				border: none;
				margin-bottom: 0;
			}

			.arrow-down,
			.arrow-up {
				position: absolute;
				width: 8px;
				height: 8px;
				right: 10px;
				color: $gray-black;
			}

			&>h1 {
				font-family: "GTWalsheimMedium", sans-serif;
				font-size: 22px;
				font-weight: 500;
				line-height: 30px;
				color: $black;
			}

			&>p {
				font-family: "GTWalsheimMedium", sans-serif;
				font-size: 15px;
				line-height: 1.6;
				color: $gray-black;
				margin-top: 7px;
			}

			.change-plan-modal-form {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 30px;

				.month-group {
					margin-top: 15px;
				}

				.form-group {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					/*&>* {
						width: 100%;
					}*/

					.error-input {
						margin-top: 0;
						font-size: 14px;
						color: $red;
						text-align: left;
					}
					.custom-input {
						&-invalid {
							border-color: $red;
							.custom-select {
								color: $red;
							}
						}
					}
				}
				.plan-options {
					display: flex;
					margin-top: 10px;
					&-item {
					  display: flex;
					  align-items: center;
					  &:first-child {
						margin-right: 40px;
					  }
					  .radio-button {
						margin-right: 10px;
					  }
					}
				}
				.default-rate-item {
					width: 380px;
					//margin-right: 15px;
					margin-top: 10px;

					& > div:first-child {
					  height: 40px;
					}
					& > div:last-child {
					  width: 100%;
					  max-width: unset;
					  max-height: 280px;
					  margin-top: 10px;
					}
				  }

				.in-month-dropdown {
					width: 380px;
					height: 40px;
					margin-top: 10px;

					.multiselect-header {
						height: 100%;

						& > span:first-letter {
							text-transform: uppercase;
						}
					}

					.body {
						max-width: 100%;
					}
				}

				label {
					font-family: "GTWalsheimMedium", sans-serif;
					font-size: 15px;
					line-height: 2;
					color: $gray-black;
					margin-bottom: 6px;
					text-align: left;
				}
			}

			.modal-buttons {
				margin-top: 20px;
			}

			.close-btn {
				cursor: pointer;
				position: absolute;
				top: 0;
				right: 0;
				width: 50px;
				height: 50px;
				background: $gray-7;
				transform: translateY(calc(-100% - 20px));

				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					width: 13px;
					height: 13px;
				}
			}

			.warning-wrapper {
				max-width: 380px;
				text-align: left;
				.warning-icon {
					height: 13px;
    				width: 19px;
				}
			}

			&.assign-freelancer-plan-modal {
				// multiselect-dropdown. Maybe need to set style global
				.assign-freelancer-plan-dropdown {
					.multiselect-header {
						border-radius: 6px;
					}
				}

				// warning-message. Maybe need to set style global
				.warning-wrapper {
					.warning-message {
						align-items: baseline;
					}

					.warning-title {
						line-height: 1.5em;
					}
				}

				// close-button
				.close-btn {
					border-radius: 6px;
				}
			}
		}
	}
}
