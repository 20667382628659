.evaluation {
  margin-top: 81px;
  margin-bottom: 127px;
  width: 600px;
  &-title {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 46px;
    font-weight: 500;
    letter-spacing: -0.4px;
    text-align: center;
  }
  &-summary {
    padding: 41px 50px 50px;
    background-color: #f6f6f6;
    border-radius: 8px;

    &-title {
      margin-bottom: 23px;
      font-family: $GTMedium;
      letter-spacing: -0.3px;
      font-size: 20px;
      font-weight: 500;
    }
    &-checbox-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .form-check {
        margin-right: 6px;
        border-radius: 6px;
        padding: 10px;
        width: 152px;
        height: 50px;
        display: flex;
        font-family: $GTMedium;
        background: white;
        .custom-checkbox:before {
          margin-right: 37px;
          border-radius: 6px;
          background-color: #fff;
        }
        .input-checkbox:checked + .custom-checkbox:before {
          border-radius: 6px;
          border: solid 1px #dfdfdf;

        }
        @at-root .checkbox-yes {
          .input-checkbox:checked + .custom-checkbox:before {
            background-color: #9af08f;
          }
        }
        @at-root .checkbox-no {
          .input-checkbox:checked + .custom-checkbox:before {
            background-color: #e77074;
          }
        }
        .custom-checkbox:before, .form-check .custom-checkbox:after {
          min-width: 24px;
          min-height: 24px;
        }
        .custom-checkbox.checkbox-svg svg {
          //left: 7px;
        }
      }
    }
    &-textarea-wrapper {
      position: relative;
    }
    &-textarea {
      border: solid 1px #dfdfdf;
      border-radius: 8px;
      padding: 8px 18px;
      width: 100%;
      min-height: 80px;
      resize: none;

      &.error {
        border-color: $pink;
      }
      &-error {
        position: absolute;
        bottom: -15px;
        left: 0;
        font-size: 12px;
        font-weight: 600;
        color: $pink;
      }
    }
  }
  &-submit {
    &-block {
      padding-top: 30px;
      margin-top: 30px;
      border-top: solid #dfdfdf 1px;
      display: flex;
      align-items: center;
    }
    &-text {
      margin-right: 30px;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.3px;
      color: #6f6f6f;
    }

    &-btn {
      padding: 19px 41px 22px 40px;
      border-radius: 8px;
      background-color: #ffe403;
      border: none;
      font-family: $GTMedium;
      font-size: 15px;
      letter-spacing: -0.3px;
      cursor: pointer;
    }
  }
}