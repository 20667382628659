.ideas-container {
  .drafts-container .active-draft .draft-item-number,
  .drafts-container.isActiveGoogleDraft .draft-item-number {
    background-color: $yellow;
  }
  .row-container {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-3;
    }
  }

  .remove-or-download-buttons {
    .upload {
      .upload-file {
        transform: rotate(180deg);
      }
    }
  }
}

