.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }
  
  .switch input {
      display:none;
    }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dfdfdf;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 6px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 4px;
    z-index: 999;
  }
  
  input:checked + .slider {
    background-color: #9af08f;
  }
  
  input:focus + .slider {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(30px);
  }
  
  .slider:after {
    content:'Off';
    color: #6f6f6f;
    display: block;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 42px;
    font-size: 12px;
    line-height: 1.33;
    transition: .3s;
    z-index: 99;
  }

  input:checked + .slider:after {
    content:'On';
    color: #000;
    left: 19px;
  }

  .disabled-switch {
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.33;
}

.on {    
    background-color: #9af08f;
    color: #000;
  }

.off {
    background-color: #dfdfdf;
    color: #6f6f6f;
}