@import './src/assets/styles/colors';

.financials {
  .financials-content-wrap {
    max-width: 100%;
    .freelancer-plan {
      &-contaiter {
        background-color: $white;
        padding: 0 50px;

        .freelancer-plan-tabs {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 40px 0;

          &-buttons {
            display: flex;
            align-items: center;

            &-btn {
              font-family: $GTMedium;
              cursor: pointer;
              font-size: 15px;
              padding-bottom: 6px;
              padding-top: 0;
              margin-left: 50px;
              height: max-content;
              color: $gray-black;
              text-transform: lowercase;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              border: none;
              background-color: transparent;
              transition: all 0.3s;
              z-index: 1;

              &:first-child {
                margin-left: 0;
              }

              &:hover {
                color: $black;
              }

              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 3px;
                background-color: $yellow;
                opacity: 0;
                transition: opacity 0.3s;
              }

              &.active {
                &:before {
                  opacity: 1;
                }

                color: $black;
              }
            }
          }
        }
      }
      &-content {
        background-color: #fff;
        // padding: 0px 50px 38px 50px;

        .warning-message {
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 1.54;
          margin: 0;
        }

        &-empty {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 40px 50px;
        }
        .freelancer-clients-tab {
          &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $gray-3;
            border-top: 1px solid $gray-3;
            padding: 35px 0;
            height: 99px;
            margin: 0px 50px;
            & > h3 {
              margin-right: 24px;
              font-size: 22px;
              font-weight: 500;
              line-height: 1;
              text-transform: lowercase;
            }
            &-filter {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .filter-item {
                min-width: 280px;
                & > div:first-child {
                  height: 40px;
                }
                & > div:last-child {
                  width: 100%;
                  max-width: unset;
                  max-height: 280px;
                  margin-top: 10px;
                }
              }
              .filter-labels {
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
              }
            }
          }
          &-container {
            .freelancer-clients-tab-list {
              margin-bottom: 30px;
              padding: 0 25px 25px;

              .change-plan-modal-form {
                .error-input {
                  margin-top: 0;
                }
                .custom-input {
                  &-invalid {
                    border-color: $red;
                    .custom-select {
                      color: $red;
                    }
                  }
                }
              }

              &-item {
                &-list {
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px solid $gray-3;
                  border-top: 1px solid $gray-3;
                  font-family: $GTRegular;
                  font-size: 13px;
                  line-height: 4.62;
                  text-transform: lowercase;
                  span {
                    &:first-child,
                    &:nth-child(2) {
                      width: 35%;
                    }
                  }
                }
              }
              &-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $gray-3;
                border-top: 1px solid $gray-3;
                font-family: $GTRegular;
                font-size: 13px;
                line-height: 4.62;
                text-transform: lowercase;
                span {
                  &:first-child,
                  &:nth-child(2) {
                    width: 35%;
                  }

                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    width: 16%;
                  }
                }
              }
              .freelancer-clients-tab-item {
                font-family: $GTRegular;
                font-size: 15px;
                line-height: 4;
                color: #000;
                padding: 0 25px;

                &:hover {
                  background: $gray-3;
                  .dot-button {
                    visibility: visible;
                  }
                }
                .hover-button {
                  position: relative;
                  display: flex;
                  justify-content: flex-end;
                  &:hover .sub-menu {
                    visibility: visible;
                  }
                }

                .dot-button {
                  cursor: pointer;
                  min-width: 40px;
                  min-height: 40px;
                  transition: background-color 0.3s;
                  background-color: $white;
                  line-height: 32px;
                  text-align: center;
                  display: inline-block;
                  visibility: hidden;
                  margin: 10px 0px 10px 10px;
                  &:hover + .sub-menu {
                    visibility: visible;
                  }
                }

                .sub-menu {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  transform: translateY(100%);
                  display: flex;
                  flex-direction: column;
                  visibility: hidden;
                  z-index: 1;

                  width: 240px;
                  padding: 10px;
                  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                  border: solid 1px $gray-2;
                  background-color: $white;

                  &:hover {
                    visibility: visible;
                  }

                  p {
                    line-height: 40px;
                    padding: 0 12px;
                    cursor: default;

                    &:hover {
                      transition: background-color 0.3s;
                      background: $gray-3;
                    }
                  }
                }
                &-list {
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px solid $gray-3;
                  &-plan {
                    width: 16%;
                    display: flex;
                    align-items: center;
                    &-name {
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      display: inline-block;
                    }
                  }
                  &-icon {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 6px;
                    border-radius: 20px;
                    margin-right: 20px;
                  }

                  span {
                    &:first-child {
                      width: 75%;
                      display: flex;
                      align-items: center;

                      img {
                        max-width: 20px;
                        max-height: 20px;
                        padding: 3px;
                        border-radius: 50%;
                        margin-right: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .freelancer-plans-tab {
          &-title {
            display: flex;
            align-items: center;
            height: 99px;
            border-top: 1px solid $gray-3;
            padding: 35px 50px;
            text-transform: lowercase;
            & > h3 {
              font-size: 22px;
              font-weight: 500;
              line-height: 1;
              margin-right: 24px;
            }
          }
          &-container {
            .freelancer-plans-tab-plans-list {
              margin-bottom: 30px;
              padding: 0 25px 25px;
              a {
                text-decoration: none;
              }
              &-item {
                background-color: red;
                &-list {
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px solid $gray-3;
                  border-top: 1px solid $gray-3;
                  font-family: $GTRegular;
                  font-size: 13px;
                  line-height: 4.62;
                  text-transform: lowercase;
                  span {
                    &:first-child,
                    &:nth-child(2) {
                      width: 35%;
                    }
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                      width: 16%;
                    }
                  }
                }
              }
              &-header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $gray-3;
                border-top: 1px solid $gray-3;
                font-family: $GTRegular;
                font-size: 13px;
                line-height: 4.62;
                text-transform: lowercase;
                padding: 0 25px;
                span {
                  &:first-child,
                  &:nth-child(2) {
                    width: 35%;
                  }

                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    width: 16%;
                  }

                  &:nth-child(7) {
                    flex-shrink: 0;
                    width: 40px;
                  }
                }
              }
              .freelancer-plans-tab-item {
                border-bottom: 1px solid $gray-3;
                font-family: $GTRegular;
                font-size: 15px;
                line-height: 4;
                color: #000;
                padding: 0 25px;
                &:hover {
                  background-color: $gray-3;
                }
                &-list {
                  display: flex;
                  justify-content: space-between;
                  &-icon {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 6px;
                    border-radius: 20px;
                    margin-right: 20px;
                  }
                  a {
                    display: flex;
                    color: #000000;
                    align-items: center;
                  }
                  span:not(.freelancer-plans-tab-item-list-default) {
                    &:first-child,
                    &:nth-child(2) {
                      width: 35%;
                      display: flex;
                      align-items: center;
                      .client-plan-name {
                        width: 85px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: inline-block;
                      }

                      img {
                        max-width: 20px;
                        max-height: 20px;
                        padding: 3px;
                        border-radius: 50%;
                        margin-right: 10px;
                      }
                    }

                    &:first-child {
                      column-gap: 10px;
                    }

                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                      width: 16%;
                      color: #6f6f6f;
                    }

                    &:nth-child(7) {
                      flex-shrink: 0;
                      width: 40px;
                    }
                  }
                  &-default {
                    padding: 4px 10px;
                    border-radius: 10px;
                    line-height: normal;
                    font-size: 11px;
                    color: $malibu;
                    background-color: $malibu-12;

                    &:first-letter {
                      text-transform: uppercase;
                    }
                  }

                  /*.option-tooltip-container {
                    flex-shrink: 0;
                    margin: 0;
                    align-self: center;

                    &:hover {
                      background-color: $white;
                    }

                    .option-item-tooltip-button {
                      span {
                        width: 14px;
                        display: block;
                      }
                    }
                  }*/

                  .dropdown-without-checkbox {
                    .multiselect-header .multiselect-header-text {
                      width: max-content;
                      max-width: 40px;
                      padding: 0;
                      display: flex;
                      justify-content: center;
                    }

                    align-self: center;
                    line-height: 16px;

                    &__list {
                      //width: max-content;

                      &-item {
                        &:first-letter {
                          text-transform: uppercase;
                        }

                        &__delete {
                          color: $red;

                          &-disable {
                            color: $azalea;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .change-plan-modal {
        .form-group {
          .error-input {
            text-align: left;
            font-family: $GTRegular;
            font-size: 14px;
            color: $red;
            margin-top: 10px;
          }
        }
        .month-group {
          margin-top: 20px;
        }
        /*.default-rate-item {
          margin-bottom: 20px;
        }*/
        .default-rate-item {
          width: 380px;
          //margin-right: 15px;
          margin-top: 10px;
          &-invalid {
            .multiselect-header {
              border-color: $red;
              color: $red;
            }
          }

          & > div:first-child {
            height: 40px;
          }
          & > div:last-child {
            width: 100%;
            max-width: unset;
            max-height: 280px;
            margin-top: 10px;
          }
        }
        .plan-options {
          display: flex;
          &-item {
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 40px;
            }
            &-label {
              margin-left: 10px;
            }
          }
        }
        .month-options {
          margin-bottom: 0;
        }
        .modal-buttons {
          margin-top: 30px;
        }

        .in-month-dropdown {
          width: 380px;
          height: 40px;
          margin-top: 10px;

          .multiselect-header {
            height: 100%;

            & > span:first-letter {
              text-transform: uppercase;
            }
          }

          .body {
            max-width: 100%;
          }
        }
      }
    }
  }
}
