.freelancer-body {

  .tab-pane {
    & > div {
      margin-bottom: 50px;
      border-radius: 6px;
      min-height: fit-content;

      &:last-child {
        margin-bottom: 0;
      }

      h2 {
        font-size: 19px;
        font-weight: 500;
        font-family: $GTMedium;
      }
    }

    .close-button-modal-overlay {
      width: 100%;

      .modal, .scroll-content {
        width: 500px;

        .content {
          margin: 40px 60px 40px 60px;
          align-items: flex-start;

          .modal-tabs {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $gray-2;
            margin-bottom: 9px;

            .tab {
              height: 100%;
              padding: 9px 29px 11px 30px;
              font-size: 15px;
              line-height: 2;
              color: $black;
              cursor: pointer;
            }

            .active {
              border: 1px solid $gray-2;
              border-bottom: none;
              outline: 2px solid $white;
              color: $gray-black;
              border-top-right-radius: 6px;
              border-top-left-radius: 6px;
            }
          }

          .title-modal {
            margin: 0 0 24px 0;
          }

          .sub-title-modal {
            font-size: 15px;
            line-height: 2;
            margin: 0 0 6px 0;
            color: $gray-black;

            &::placeholder {
              color: $gray-black;
            }

            &.black {
              color: $black;
            }
          }

          .spase {
            margin-top: 20px;
          }

          .input-error {
            color: $pink;
            font-size: 10px;
            margin-top: 5px;
          }

          input {
            width: 380px;
            height: 40px;
            border: solid 1px $gray-2;
            color: $black;
            padding-left: 16px;
          }

          .custom-dropdown, .multiselect-dropdown {
            width: 380px;
            height: 40px;
            border: solid 1px $gray-2;
            color: $black;
            margin: 0;
            padding-left: 0;

            .custom-header {
              padding-left: 16px;

              &::placeholder {
                color: $gray-black;
              }
            }

            .body {
              min-width: 380px;
              top: 40px;
              max-height: 120px;
              height: fit-content;
            }

            .custom-items {
              height: 174px;
            }

            .form-checkbox-wrap {
              height: 45px;
            }
          }

          .multiselect-header {
            border: none;
            height: 40px;
            text-transform: capitalize;
            padding-left: 16px;
          }

          .form-check {
            .input-checkbox {
              width: 20px;
              height: 20px;
            }

            label {
              font-size: 15px;
              line-height: 2;
              margin: 14px 0 20px 0;
              color: $black;
            }
          }

          .highlight {
            margin-top: 20px;

            label {
              color: $gray-black;
            }
          }

          .labels {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .filter-label {
              margin-top: 10px;
              height: 40px;
            }
          }

          .devider {
            width: 100%;
            border-top: 1px solid $gray-2;
            border-left: none;
            border-right: none;
            border-bottom: none;
            margin: 40px 0;
            height: 0;
            background-color: $white;
          }

          .space-between {
            justify-content: space-between;
          }

          .specialties-section-description {
            font-size: 15px;
            color: $gray-black;
          }

          .modal-buttons {
            width: 100%;
            align-items: center;

            .cancel-button {
              &:first-child {
                margin-right: 20px;
              }
            }
          }

          .delete-button {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              font-size: 15px;
              line-height: 2;
              text-decoration: underline;
              color: $black;
              margin-left: 11px;
            }

            svg {
              width: 13px;
              height: 13px;
            }
          }

          .radio-buttons {
            margin-top: 20px;
          }

          .upload-button {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray-3;
            font-size: 15px;
            line-height: 2;
            color: $gray-black;
            border: none;
            margin-top: 20px;
            cursor: pointer;
            border-radius: 6px;

            input {
              width: 100%;
              height: 100%
            }
          }

          .uploaded-container {
            width: 100%;
            padding: 10px 30px 30px 30px;
            border-radius: 6px;
            background-color: $gray-3;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;

            .document {
              width: 100%;
              border: 1px solid $gray-2;
              height: 50px;
              margin: 20px 0 0 0;
              border-radius: 6px;
              padding: 12px 20px 12px 18px;

              .document-icon {
                width: 20px;

                path {
                  fill: $yellow-1;
                }
              }

              .document-name {
                max-width: 240px;
                font-size: 15px;
                line-height: 2;
                color: $black;
              }

              .download-btn {
                height: 40px;
              }

              .close-icon {
                cursor: pointer;
                height: 10px;

                path {
                  fill: $black;
                }
              }
            }

            .upload-cover {
              background-color: $white;
              border: none;
              color: $gray-black;
              height: 40px;
              width: 100%;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              cursor: pointer;
            }
          }


          .radio-buttons {
            width: 400px;
            height: 100%;
            display: flex;
            align-items: center;

            .radio-button {
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }

      &.ExperienceSection__modal {
        .isOpen {
          width: inherit;
          z-index: 1;
        }

        .custom-header {
          & > svg {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 6px;
            transform: translateY(-50%);
          }
        }

        .modal {
          .close-button {
            flex-shrink: 0;
          }

          .scroll-content {
            max-height: 800px;

            .isMyExpertiseCheckbox {
              .custom-checkbox {
                margin: 9px 0 0;
              }
            }

            .devider {
              margin: 30px 0;
            }

            .industry-specialties-checkboxes-block {
              margin-top: 16px;
              width: 100%;
              display: flex;
              column-gap: 20px;
              max-height: 300px;
              overflow-y: auto;

              &-column {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                width: 180px;
              }

              .form-check {
                .custom-checkbox {
                  margin: 0;
                  line-height: normal;
                }
              }
            }

            .ExperienceSection__modal-buttons {
              margin-top: 40px;
              justify-content: center;
              column-gap: 20px;

              .cancel-button:first-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .tabs-and-search {
    padding-top: 10px;
    background: $white;

    .tab-items {
      text-transform: lowercase;
      padding: 20px 0;
      margin-right: 30px;

      &.active {
        background: $white;

        &:before {
          background: $yellow-1;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  .tab-pane {
    display: none;

    &.active {
      display: block;
    }
  }

  .modal {
    .multiselect-dropdown {
      width: 380px;
      height: 40px;

      .multiselect-header {
        height: 40px;
      }

      .body {
        max-width: 380px;
        height: 150px;

        .multiselect-search {
          max-width: 340px;
        }

        &::-webkit-scrollbar {
          width: 16px;
          padding: 0 10px;
        }

        &::-webkit-scrollbar-track {
          background-color: $white;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background-color: $gray-2;
          max-height: 68px;
          min-height: 40px;
          width: 6px;
          border: 5px solid $white;
          cursor: pointer;
        }
      }

      .custom-items {
        max-height: 150px;
      }
    }

    .label {
      font-size: 15px;
      color: $gray-black;
      line-height: 30px;
      margin-bottom: 6px;
    }

    // TODO CHECK
    .cards-wrapper {
      margin: 10px 0;
      max-width: 380px;
      display: flex;
      flex-wrap: wrap;

      .card {
        background-color: $yellow-3;
        margin-right: 10px;
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 20px;

        svg {
          width: 6px;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
