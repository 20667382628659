@import './src/assets/styles/mixins/responsive';
.timeline {
  margin-left: auto;
  width: 470px;
  display: flex;
  position: relative;
  align-items: center;
  text-transform: lowercase;
  justify-content: flex-end;
  @include media(mobile){
    width: 100%;
    justify-content: start;
    column-gap: 10px;
    row-gap: 20px;
    padding-bottom: 20px;
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 2fr;
  }

  .complete{
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .step-container {
    position: relative;
    margin-left: 60px;
    @include media(mobile){
      margin-left: 0;
    }

    .step {
      width: 26px;
      height: 26px;
      z-index: 1;
      cursor: default;
      border-radius: 50%;
      position: relative;
      box-sizing: content-box;
      background-color: $gray-2;
      @include media(mobile){
        width: 30px;
        height: 30px;
      }
    }
    .step:hover .pop {
      visibility: visible;
      opacity: 1;
    }

    .name {
      left: 0;
      right: 0;
      display: none;
      font-size: 13px;
      text-align: center;
      position: absolute;
    }

    .deadline {
      position: absolute;
      color: $gray-7;
      font-size: 13px;
      bottom: -25px;
      display: none;
      left: 0;
      right: 0;
      text-align: center;

      &.expired {
        color: red;
      }
    }

    .pop {
      position: absolute;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      z-index: 9;
      white-space: nowrap;

      .pop-header {
        width: auto;
        height: 36px;
        text-align: center;
        background-color: white;
        display: flex;
        color: $black;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        font-size: 13px;
        line-height: 14px;
        padding: 0 15px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
      }
      p {
        margin: 0;
        padding: 16px;
        font-size: 13px;
      }
    }
    .pop:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $white;
      transform: rotate(45deg) translateX(-100%);
      z-index: 1;
      left: 50%;
      bottom: -11px;
    }

    .complete-btn {
      box-shadow: none;
      border: none;
      padding: 10px;
      border-radius: 6px;
      min-width: 150px;
      min-height: 40px;
      font-family: 'GTWalsheimMedium';
      text-transform: lowercase;
      cursor: pointer;
      transition: all .3s;
      @include media(mobile){
        width: 100%;
        min-height: 40px;
      }

      &.disabled {
        opacity: 0.3;
      }
    }

    .complete-btn-wrapper{
      text-transform: none;
      color: $white;
      background-color: $black;
      white-space: nowrap;
    }

    .tooltip-tail {
      //it takes a little more time to break styles with nesting in this place
      border-top-color: $black!important;
    }

  }
  .done {
    .step {
      background-color: $light-green;;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: "\2714";
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 12px;
        @include media(mobile){
          content: '';
          width: 10px;
          height: 4px;
          border-left: 1px solid $white;
          border-bottom: 1px solid $white;
          transform: rotateZ(-45deg) translateY(-2px) translateX(1px);
        }
      }
    }
  }
  .step-container:not(:last-child):before {
    content: '...';
    left: 90%;
    z-index: 1;
    width: 70px;
    color: $gray-2;
    font-size: 36px;
    line-height: 8px;
    text-align: center;
    position: absolute;
    letter-spacing: 4px;
    vertical-align: middle;
    @include media(mobile){
      content: '';
    }
  }

  .step-container{
    &.done {
      &:not(:last-child){
        &:before {
          border-color: $green;
        }
      }
    }
    &.doing {
      &:before {
        left: 98%;
        line-height: 10px;
      }
    }
  }

  .doing {
    &:hover {
      .pop {
        display: none;
      }
    }

    .step {
      width: 152px;
      height: 32px;
      position: relative;
      border-radius: 20px;
      @include media(mobile){
        background-color: $white;
        height: 30px;
        width: 135px;
      }
    }
    .name {
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }

    .deadline {
      display: block;
      @include media(mobile){
        display: none;
      }
    }
  }

  .finished {
    .step {
      background-color: $green;
      color: white;
    }
  }

  &.transparent-header {
    .step-container {
      &.doing {
        .step {
          background-color: #fff;
        }
      }
      &.done {
        .step {
          background-color:#82E190;
        }
      }
      .step {
        background-color: #dfdfdf;
      }
      &:not(:last-child):before {
        color: #dfdfdf;
      }
    }
  }
}
