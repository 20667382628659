.all-plans-container {
  background: $white;
  padding: 30px 50px 60px;
  margin-bottom: 50px;
  position: relative;
  &-client {
    padding: 30px 0 60px;
    &-header {
      padding: 0 50px;
    }
  }
  &-freelancer {
    padding: 30px 25px 60px;
    &-header {
      display: flex;
      align-items: center;
      padding-left: 25px;
      //border-bottom: 1px solid $gray-3;
    }
  }

  &>h2 {
    font-family: "GTWalsheimMedium", sans-serif;
    font-size: 22px;
    font-weight: 500;
  }

  .search-container {
    position: absolute;
    top: 30px;
    right: 50px;
    max-width: 230px;
    height: 40px;

    .search-wrap .search-input {
      height: 40px;
      font-size: 15px;
      padding-right: 40px;

      &::placeholder {
        color: $gray;
      }
    }

    .search-wrap.active .show-search {
      position: absolute;
    }
  }

  .plans-list-container {
    padding: 0;

    .plans-list-content-client {
      padding: 0 25px;
    }
    .plans-list-titles {
      grid-template-columns: 660px 150px 150px 150px 130px;
      border-top: 1px solid $gray-3;
      &-wrap {
        padding: 0 25px;
        margin-top: 30px;
      }
      &-freelancer {
        display: grid;
        grid-template-columns: 1020px 150px;
        border-top: 1px solid $gray-3;
        border-bottom: 1px solid $gray-3;
        &-wrap {
          margin-top: 30px;
          padding: 0 25px;
        }
      }
    }
    .plans-list-title, .plans-list-title-freelancer {
      font-family: $GTRegular, sans-serif;
      font-size: 13px;
      color: $black;
    }

    .plans-list-title-freelancer {
      line-height: 60px;
    }

    .plan-row {
      grid-template-columns: 1090px 130px;
      &-client, &-freelancers {
        padding: 0 25px;
        border: none;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          right: 25px;
          height: 1px;
          background-color: $gray-3;
        }
      }
      &-client {
        grid-template-columns: 1110px 130px;
        &:after {
          width: calc(100% - 50px);
        }
      }
      &-freelancers {
        &:after {
          width: calc(99% - 38px);
        }
      }
      &:hover {
        background: $gray-3;

        .dot-button {
          visibility: visible;
        }
      }

      .plan-row-data {
        display: grid;
        grid-template-columns: 660px 150px 150px 150px;
        &-freelancers {
          grid-template-columns: 1020px 110px;
        }
      }

      .plan-column {
        font-family: $GTRegular, sans-serif;
        font-size: 15px;
        color: $black;
        cursor: default;

        &:nth-child(n+2) {
          color: $gray-black;
        }
      }

      .hover-button {
        position: relative;
        display: flex;
        justify-content: flex-end;
      }

      .dot-button {
        cursor: pointer;
        min-width: 40px;
        min-height: 40px;
        transition: background-color .3s;
        background-color: $white;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        visibility: hidden;

        &:hover+.sub-menu {
          visibility: visible;
        }
      }

      .sub-menu {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        visibility: hidden;
        z-index: 1;

        width: 240px;
        padding: 10px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px $gray-2;
        background-color: $white;

        &:hover {
          visibility: visible;
        }

        p {
          line-height: 40px;
          padding: 0 12px;
          cursor: default;

          &:hover {
            transition: background-color .3s;
            background: $gray-3;
          }
        }
      }
    }
  }
}

.custom-plan-filter {
  margin-bottom: 10px;
  padding: 30px 50px;
  // border-bottom: 1px solid $gray-3;
  background-color: $white;
  width: 100%;

  .filters-area {
      display: flex;
      justify-content: space-between;
  }

  .filters-list {
      // padding-bottom: 10px;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
  }

  .filter-item {
      min-width: 280px;
      margin-right: 20px;
      // margin-top: 10px;

      & > div:first-child {
          height: 40px;
      }
      & > div:last-child {
          width: 100%;
          max-width: unset;
          max-height: 280px;
          margin-top: 10px;
    }
  }

  .filter-labels {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
}

.custom-rate {
  padding-bottom: 20px;
  background-color: $white;
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 10px;
    padding: 35px 40px;
    p {
      font-family: $GTMedium;
      font-size: 22px;
      color: $black;
    }

    &__rightPart {
      display: flex;
      align-items: center;
    }
  }
  &-icon {
    width: 22px;
    height: 22px;
    padding: 0 6px;
    border-radius: 20px;
    background-color: $gray;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-plan {
    &-header {
      width: 100%;
      background-color: $white;
      padding: 0 15px;
      padding-right: 100px;

      .width-container {
        @include width-container(1340px);
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-icon {
        width: 80px;
        height: 80px;
        padding: 30px;
        border-radius: 40px;
        //background-color: #ff9751;
        margin-right: 20px;
      }

      &-info {
        width: 100%;
        display: flex;
        align-items: center;
        &-wrap {
          width: 100%;
          display: flex;
          align-items: center;
          font-family: $GTMedium;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: $black;
          position: relative;
          .option-tooltip-container .option-item-tooltip {
            right: 0;
          }
        }
      }


      .link-back {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        text-decoration: none;
        background-color: $gray-3;
        margin-right: 30px;
        border: none;
        cursor: pointer;
      }

      .arrow-back-icon {
        width: 8px;
        fill: $black;
      }


      .link-back {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        text-decoration: none;
        background-color: $gray-3;
        margin-right: 30px;
        border: none;
        cursor: pointer;
      }

      .arrow-back-icon {
        width: 8px;
        fill: $black;
      }
    }
    &-border {
      border-top: 1px solid $gray-3;
    }
    &-description {
      font-family: $GTRegular;
      font-size: 15px;
      color: $gray-black;
      margin-top: 5px;
      span {
        margin-right: 15px;
      }
    }
  }

  .hover-button {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  .dot-button {
    cursor: pointer;
    min-width: 40px;
    min-height: 40px;
    transition: background-color .3s;
    background-color: $white;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    visibility: hidden;

    &:hover+.sub-menu {
      visibility: visible;
    }
  }

  .sub-menu {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    visibility: hidden;
    z-index: 1;

    width: 250px;
    padding: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $gray-2;
    background-color: $white;

    &:hover {
      visibility: visible;
    }

    p {
      line-height: 40px;
      padding: 0 12px;
      cursor: default;

      &:hover {
        transition: background-color .3s;
        background: $gray-3;
      }
    }
  }

  &-plans {
    &-list {
      padding: 0 35px;
      margin-bottom: 30px;
      a {
        text-decoration: none;
      }
      &-header {
        padding: 20px 25px 20px 25px;
        border-bottom: 1px solid $gray-3;
        border-top: 1px solid $gray-3;
        font-family: $GTRegular;
        font-size: 15px;
        color: $black;
        //font-weight: bold;
        text-transform: lowercase;
        display: grid;
        grid-template-columns: 95% 5%;

        &-content {
          display: grid;
          grid-template-columns: 25% 180px repeat(7, 1fr);
          column-gap: 4px;
        }
      }
      &-item {
        align-items: center;
        background-color: $white;
        padding: 10px 25px 10px 25px;
        border-bottom: 1px solid $gray-3;
        display: grid;
        grid-template-columns: 95% 5%;

        &:hover {
          background: $gray-3;

          .dot-button {
            visibility: visible;
          }
        }
        &-title {
          font-family: $GTRegular;
          font-size: 15px;
          color: $black;
        }
      }
    }

    &-left, &-right {
      display: flex;
      align-items: center;
    }
    &-link{
      display: grid;
      grid-template-columns: 25% 180px repeat(7, 1fr);
      column-gap: 4px;
    }
    &-left{
      width: 65%;
    }
    &-title {
      font-family: $GTMedium;
      font-size: 32px;
      color: $black;
    }
    &-description, &-clients {
      font-family: $GTRegular;
      font-size: 15px;
    }
    &-description {
      color: $gray-black;
    }
    &-clients {
      color: $black;
      width: 180px;
    }
    &-no-clients {
      color: $orange-2;
    }
  }

}
