.crowdin-draft-container {

  .no-accepted-crowdin-container {
    text-align: center;
    width: 100%;
    h1 {
      margin-bottom: 20px;
    }
    h3 {
      color: #a9a9a9;
    }
  }

  .crowdin-iframe {
    @include media(mobile){
      display: none;
    }
  }

  .something-went-wrong{
    background-color: $gray-14;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 0;
    border-radius: 8px;

    &__content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div{
        display: flex;
        align-items: flex-end;
      }

      & > img{
        max-width: 190px;
      }

      & > h3{
        font-family: "GTWalsheimMedium", sans-serif;
        font-size: 22px;
        line-height: 1.36;
        letter-spacing: -0.3px;
        text-align: center;
        color: $black;
      }

      .warn-message {
        margin: 18px 0 0 0;

        .icon {
          border-radius: 50%;
          width: 14px;
          height: 14px;
        }

        .title {
          font-size: 13px;
          line-height: 1.54em;
        }
      }
    }
  }

  .crowdin-draft-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include media(mobile){
      flex-direction: column;
    }

    .crowdin-sub-title{
      font-size: 13px;
      color: #ff9751;
      display: inline-flex;
      @include media(mobile){
        display: none;
      }
    }

    .v-center {
       font-size: 18px;
       font-weight: 500;
       font-stretch: normal;
       font-style: normal;
       letter-spacing: normal;
       color: #000;
       line-height: 2;
       vertical-align: inherit;
       @include media(mobile){
         font-family: $GTMedium;
       }
     }

    .hideFreelancerMobile{
      @include media(mobile) {
        display: none;
      }
    }

    .crowdin-notification{
      font-family: $GTRegular;
      font-size: 15px;
      line-height: 1.73;
      letter-spacing: -0.3px;
      color: $gray-black;
      display: none;
      margin-bottom: 20px;
      @include media(mobile){
        display: block;
      }
    }

    .finish-or-deliver-buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      @include media(mobile){
        flex-direction: column;
        gap: 10px;
        position: relative;
      }

      .vertical_divider {
        width: 1px;
        border-left: 1px solid #dfdfdf;
      }

      .crowdin-button {
        @include button(212px, 40px);
        border-radius: 6px;
        font-size: 15px;
        line-height: 2;
        text-decoration: none;
        text-align: center;
        text-transform: lowercase;
        display: inline-block;
        color: $black;
        @include media(mobile){
          width: 100%;
        }
      }

      .approve-btn {
        background-color: $yellow-1;
      }
      .crowdin-actions-buttons, {
        display: flex;
        gap: 10px;
        @include media(mobile){
          display: none;
        }
        & > span{
          margin-right: 10px;
        }
        .full-screen-tooltip, .link-tooltip{
          white-space: nowrap;
          color: $white;
          background-color: $black;
        }

        .full-screen-button, .link-button{

          & > button, a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: 0;
            background-color: $gray-3;
            border-radius: 6px;
            & > svg{
              width: 16px;
              height: 16px;
            }
          }
        }

        .full-screen-tooltip-arrow, .link-tooltip-arrow {
          border-top-color: $black;
        }

      }

      .feedback-delivered-btn{
        background-color: #ff9751;
      }
    }

  }
}
