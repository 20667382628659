@import "./src/assets/styles/mixins/index";

.default-rate {
  padding-bottom: 20px;
  background-color: $white;
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 10px;
    padding: 35px 40px;
    p {
      font-family: $GTMedium;
      font-size: 22px;
      color: $black;
    }

    &__rightPart {
      display: flex;
      align-items: center;
    }
  }
  &-icon {
    width: 22px;
    height: 22px;
    padding: 0 6px;
    border-radius: 20px;
    //background-color: #ff9751;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-plan {
    &-header {
      width: 100%;
      padding: 20px 15px;

      .width-container {
        @include width-container(1340px);
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-icon {
        width: 80px;
        height: 80px;
        padding: 30px;
        border-radius: 40px;
        //background-color: #ff9751;
        margin-right: 20px;
      }

      &-info {
        width: 100%;
        display: flex;
        align-items: center;
        &-wrap {
          width: 100%;
          display: flex;
          align-items: center;
          font-family: $GTMedium;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: $black;
          position: relative;
          .option-tooltip-container .option-item-tooltip {
            right: 0;
          }
        }
      }


      .link-back {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        text-decoration: none;
        background-color: $gray-3;
        margin-right: 30px;
        border: none;
        cursor: pointer;
      }

      .arrow-back-icon {
        width: 8px;
        fill: $black;
      }


      .link-back {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        text-decoration: none;
        background-color: $gray-3;
        margin-right: 30px;
        border: none;
        cursor: pointer;
      }

      .arrow-back-icon {
        width: 8px;
        fill: $black;
      }
    }
    &-border {
      margin-top: 0 !important;
    }
    &-description {
      font-family: $GTRegular;
      font-size: 15px;
      color: $gray-black;
      margin-top: 5px;
      &-price {
        margin-left: 10px;
        &-item {
          &:not(&:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &-plans {
    &-list {
      padding: 0 35px;
      margin-bottom: 30px;

      &-header {
        border-bottom: 1px solid $gray-3;
        border-top: 1px solid $gray-3;
        font-family: $GTRegular;
        font-size: 15px;
        color: $black;
        padding: 20px 25px;
        display: grid;
        grid-template-columns: 25% 180px repeat(7, 1fr);
        column-gap: 4px;
        text-transform: lowercase;
      }
      &-item {
        height: 59px;
        display: flex;
        align-items: center;
        background-color: $white;
        padding: 10px 25px;
        border-bottom: 1px solid $gray-3;
        &:hover {
          background-color: $gray-3;
        }

        &-link {
          width: 100%;
          display: grid;
          height: 40px;
          grid-template-columns: 25% 180px repeat(7, 1fr);
          column-gap: 4px;
          text-decoration: none;

          &>div, &>span {
            align-self: center;
          }
        }
        &-title {
          display: flex;
          font-family: $GTRegular;
          font-size: 15px;
          color: $black;
          &-text {
            font-family: $GTMedium;
            color: $black;
            @include textOverflowOneLine;
          }
        }

        &-description, &-clients {
          font-family: $GTRegular;
          font-size: 15px;
        }
        &-creation, &-translation, &-creative, &-consultancy {
          color: $gray-black;
        }
        &-clients {
          color: $black;
          width: 180px;
        }
        &-no-clients {
          color: $orange-2;
        }
      }
    }

  }
  &-top-wrap {
    height: 160px !important;
  }
}
