.modal-popover {
  display: block;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  margin-bottom: 50px;
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translateX(-50%) translateY(-100%);
  padding: 30px;
  z-index: 2;
  &_draft{
    top: 180px;
    z-index: 1;
  }

  .popover-content {
    text-align: center;

    h2 {
      line-height: 24px;
      font-size: 16px;
      color: $black;
      font-weight: bold;
      margin: 0 0 5px 0;
    }
  }

  .popover-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .confirm-button,
  .cancel-button {
    @include button(120px, 50px);
    color: $black;
    &:first-child {
      margin-right: 15px;
    }
  }
  .confirm-button {
    @extend .yellow-button;
    border-radius: 6px;
    box-shadow: none;
  }
  .cancel-button {
    background-color: $gray-3;
    border-radius: 6px;
  }
}

.modal-popover:after {
  position: absolute;
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-color: white;
  transform: rotate(45deg);
  left: 48%;
  bottom: -8px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(0, 0, 0, 0.2);
}

