.financials {
  .tab-items {
    padding: 19px 38px 20px 39px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    &:before {
      background-color: transparent;
    }
  }

  .tab-items {
    border-radius: 6px 6px 0 0;

    &:before {
      height: 2px;
      width: 53px;
      margin: 0 auto;
      top: unset;
      bottom: 0;
    }
  }

  &-body {
    .tab-pane:not(.active) {
      display: none;
    }
    &-overview {
      @include width-container(1340px);

      &-filters {
        background-color: $white;
        border-radius: 6px;
        border-top-left-radius: 0;
        padding: 30px 50px;
        margin-bottom: 10px;

          .preloader {
              height: 300px;
              display: flex;
              align-items: center;
              justify-content: center;
          }

          .projects-filter {
              width: 100%;

              .filter-label {
                  border-radius: 6px;
                  height: 40px;
              }

              .filters-area {
                display: flex;
                justify-content: space-between;
              }

            .filters-list {
                padding-bottom: 10px;
                display: flex;
                width: 945px;
                flex-wrap: wrap;
              }

              .multiselect-dropdown {
                  border-radius: 6px;
                  width: 295px;
                  border: none;
              }

              .filter-item {
                  min-width: 295px;
                  // margin-top: 10px;
                  border-radius: 6px;

                  .body {
                      border-radius: 6px;
                      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                      top: 100%;
                      width: 100%;
                      overflow-y: auto;
                      overflow: hidden;

                      &::-webkit-scrollbar {
                          width: 16px;
                          padding: 0 10px;
                      }

                      &::-webkit-scrollbar-track {
                          background-color: $white;
                      }

                      &::-webkit-scrollbar-thumb {
                          border-radius: 20px;
                          background-color: $gray-2;
                          max-height: 68px;
                          min-height: 40px;
                          width: 6px;
                          border: 5px solid $white;
                          cursor: pointer;
                      }

                      .custom-checkbox:hover {
                          border-radius: 6px;
                      }
                  }

                  .custom-checkbox {
                      overflow: hidden;
                      text-overflow: ellipsis;

                      &:hover {
                          border-radius: 6px;
                      }
                  }

                  &:not(:nth-child(5n)) {
                      margin-right: 20px;
                  }

                  & > div:first-child {
                      height: 40px;
                      border-radius: 6px;
                  }

                  & > div:last-child {
                      width: 100%;
                      max-width: unset;
                      max-height: 225px;
                      margin-top: 10px;
                      overflow: hidden;
                      overflow-y: auto;
                  }
            }

            .date-dropdown-open {
              width: 450px;
            }

            .filter-date-dropdown, .date-selector-container {
                min-width: 219px;
                margin-right: 20px;
                margin-top: 10px;
                border-radius: 6px;

              .multiselect-header {
                height: 40px;
              }

                & > div:last-child {
                    width: 450px;
                    max-width: unset;
                    overflow: unset;
                    overflow-y: unset;
                    margin-top: 10px;

                    &::before {
                        content: "";
                        display: block;
                        width: 232px;
                        height: 1px;
                        background-color: $gray-2;
                        margin-left: auto;
                    }
                }
            }

            .filter-labels-warnings {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
              min-height: 30px;

              .filter-labels {
                max-width: calc(100% - 200px);
              }

              .warning-message {
                margin-top: 0;

                .warning-title {
                  line-height: 1.54;
                }
              }
            }

            .green {
              .warning-message {
                .warning-icon {
                  background-color: $green;
                }

                .warning-title {
                  color: $green;
                }
              }
            }

            .filter-labels {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .filter-label {
                  height: 30px;
                }
              }

            .filter-close-month-button {
              width: 245px;
              height: 40px;
              background-color: $yellow-1;
              border-radius: 6px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              // margin-top: 10pxж
              margin-left: 10px;

            span {
              font-size: 15px;
              line-height: 2;
              color: $black;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .filter-closed-button {
            width: 245px;
              height: 40px;
              background-color: $gray-3;
              border-radius: 6px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;

            span {
              font-size: 15px;
              line-height: 2;
              color: $black;
              opacity: 0.5;
            }

            &:hover {
              cursor: default;
            }
          }

          .disabled {
            opacity: 0.3;

            &:hover {
              cursor: default;
            }
          }

            .menu-button {
                padding: 0;
                background-color: $gray-3;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                // margin-top: 10px;
                border-radius: 6px;
                position: relative;

                & > svg {
                    width: 14px;
                    height: 14px;
                }

                &:hover {
                    cursor: pointer;
                }

                .dot-button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 8px;
                }

                .sub-menu {
                  padding: 9px 10px 10px 10px;
                  border-radius: 6px;
                  background-color: $white;
                  width: 228px;
                  visibility: hidden;
                  position: absolute;
                  margin-top: 5px;
                  top: 90%;
                  right: calc(100% - 40px);
                  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

                  .sub-menu-item {
                    width: 208px;
                    height: 40px;
                    padding: 11px;
                    border-radius: 6px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    border: none;

                    &:hover {
                      cursor: pointer;
                    }

                    &:disabled {
                      cursor: not-allowed;

                      &.isLoading {
                        cursor: progress;
                      }
                    }

                    & > svg {
                      width: 11px;
                      height: 11px;
                      margin-right: 12px;
                    }

                    span {
                      font-size: 24px;
                      width: 11px;
                      margin-right: 12px;
                    }

                    p {
                      font-size: 15px;
                      color: $black;
                    }

                    &:hover{
                      background-color: $gray-3;
                    }
                  }

                }
              }

              .menu-button:hover {
                .sub-menu {
                  visibility: visible;
                }
              }
          }

          .project-graphic-container {
              align-items: center;
              padding: 30px;
              height: 200px;
              background-color: $gray-3;
              margin-top: 20px;
              border-radius: 6px;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 10px;

              .project-graphic-item {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  height: 138px;
                  background-color: $white;
                  border-radius: 6px;

                  .graphic-value {
                      font-size: 20px;
                      font-weight: bold;
                      overflow-x: hidden;
                      max-width: 180px;
                      max-height: 30px;
                      overflow-y: hidden;
                      white-space: nowrap;
                  }

                  .graphic-devider {
                      width: 20%;
                      border-top: 1px solid cyan;
                      margin: 13px 0;
                  }

                  .graphic-title-container {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .completion-icon {
                          width: 16px;
                          height: 16px;
                          border: 1px solid $gray-black;
                          border-radius: 50%;
                          display: flex;
                          align-items: center;
                          justify-content: center;

                          svg {
                              width: 7px;
                              height: 7px;

                              path {
                                  fill: $gray-black;
                              }
                          }
                      }

                      svg {
                          width: 15px;
                          height: 15px;
                          margin-left: 1px;

                          path {
                              fill: $gray-black;
                          }
                      }

                      .graphic-title {
                          color: $gray-black;
                          font-size: 14px;
                          margin-left: 7px;
                      }
                  }
              }
          }
      }

      &-container {
        background-color: $white;
        border-radius: 6px;
        padding: 40px 30px 50px 30px;

        .projects-list-container {
          .projects-list-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 30px;
              margin: 0 20px;

              & > p {
                  font-size: 22px;
                  line-height: 22px;
                  font-weight: 600;
              }

              & > div {
                  display: grid;
                  grid-template-columns: auto auto;
                  align-items: center;
                  column-gap: 15px;
              }

              .header-buttons {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: min-content;

                  .custom-dropdown {
                      width: 219px;
                      height: 40px;
                      border: solid 1px $gray-2;
                      border-radius: 6px;
                  }

                  .custom-items {
                      border-radius: 6px;
                      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                      top: 100%;
                      width: 100%;
                      overflow-y: auto;
                      overflow: hidden;
                      margin-top: 10px;
                  }

                  .button-title {
                      font-size: 16px;
                      color: $gray-black;
                      width: max-content
                  }
              }

              .save-button {
                  @extend .yellow-button;
                      border: none;
                      width: auto;
                      min-width: 148px;
                      padding: 8px 14px 10px 14px;
                      height: 40px;
                      cursor: pointer;
                      font-weight: bold;
                      color: $gray-black;
                      text-transform: lowercase;

                      &.active,
                          &:hover {
                          background-color: $yellow-2;
                          color: $black;
                      }
              }

              .filter-button {
                  padding: 0;
                  background-color: $gray-3;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  margin-top: 5px;
                  border: none;

                  & > svg {
                      width: 25px;
                      height: 25px;
                  }

                  &:hover {
                      cursor: pointer;
                  }
              }
          }

          .projects-list-table {
              display: flex;
              flex-direction: column;
              margin: 0 40px 15px 0;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 20px;
                height: 1px;
                background-color: $gray-3;
                width: 100%;
              }

              &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 20px;
                height: 1px;
                background-color: $gray-3;
              }

              & > div:last-child {
                  margin-bottom: 6px;
              }

              .projects-list-content {
                  width: 100%;
              }

              &::-webkit-scrollbar-track {
                  border-radius: 10px;
                  background-color: #f2f2f2;
              }

              &::-webkit-scrollbar {
                  height: 10px;
                  background-color: #f2f2f2;
              }

              &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  background-color: #c6c6c6;
                  cursor: pointer;
              }

              & > div:not(:first-child):not(:nth-child(2)) {
                margin-top: 12px;
              }
          }

          .projects-list-table-grid {
            align-items: center;
            position: relative;
            margin-bottom: 6px;
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            &:after {
              content: "";
              position: absolute;
              top: 100%;
              left: 20px;
              height: 1px;
              background-color: $gray-3;
              width: 100%;
            }

            .projects-list-titles {
              padding-left: 0;

              &:first-child {
                padding-left: 20px;
              }
            }
          }

          .pink {
            background-color: #fff0e5;
            border-radius: 6px;
          }
              .client-block {
                  display: flex;
                  align-items: center;
                  background-color: $white;
                  height: 100%;
                  padding-left: 0;

                  .projects-list-titles {
                    padding-left: 0 !important;
                  }

                  &:hover {
                      cursor: pointer;
                  }

                  .arrow {
                      width: 10px;
                      height: 6px;
                      margin-left: 7px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      transition: 0.3s;

                      svg {
                          width: 10px;
                          height: 6px;
                      }
                  }
                  .reversed {
                      transition: 0.3s;
                      transform: rotate(180deg);
                  }
              }

              .projects-list-titles {
                  padding: 14px 15px;
                  font-size: 13px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  text-transform: lowercase;
                  background-color: $white;
                  height: 100%;
                  justify-content: center;

                  &:first-child {
                      padding-left: 0;
                  }
              }

              .projects-list-sub-title {
                font-size: 13px;
                color: $gray-black;
              }

              .first-fixed {
                  position: sticky;
                  left: 0;
              }

              .second-fixed {
                  position: sticky;
                  left: 140px;
              }

              .projects-list-client-user-dropdown {
                  width: 159px;
              }
          }

          .project-item {
              position: relative;
              border-radius: 6px;
              margin-top: 0;

              // &:not(:first-child) {
              //   margin-top: 12px;
              // }

              &:not(:last-child) {
                &:after {
                  content: "";
                  position: absolute;
                  top: calc(100% + 6px);
                  left: 20px;
                  height: 1px;
                  background-color: $gray-3;
                }
              }

              .project-item-info {
                  min-height: 40px;
              }

              &:hover {
                  cursor: pointer;
                  background-color: $gray-3;
              }

              .projects-item-table-grid {
                color: $black;
                text-decoration: none;
                align-items: center;
                position: relative;
                display: grid;
                grid-template-columns: repeat(7, 1fr);

                .projects-list-item {
                  padding-left: 0;
                  border-bottom: 1px solid $gray-3;
                }
              }

                  .with-marker {
                      p, span {
                        padding-left: 23px;
                      }
                  }

                  .projects-list-item {
                      padding: 25px 5px 25px 0;
                      display: flex;
                      align-items: flex-start;
                      flex-direction: column;
                      font-size: 15px;
                      height: 100%;
                      overflow-wrap: anywhere;
                      position: relative;

                      a {
                          color: $black;
                          text-decoration: none;

                          &:hover {
                              text-decoration: underline;
                          }
                      }
                    &-code {
                      margin-left: 19px;
                    }
                    &-marker {
                      position: absolute;
                      width: 14px;
                      img {
                          border-radius: 2px;
                      }
                      &-tooltip {
                        position: relative;
                        visibility: hidden;
                        &-text {
                          position: absolute;
                          width: 200px;
                          top: -55px;
                          transform: translateX(-46.5%);
                          z-index: 9999;
                          text-align: center;
                          padding: 5px 0;
                          color: $white;
                          background-color: $black;
                          border-radius: 4px;
                          &::after{
                            content: '';
                            position: absolute;
                            top:100%;
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: black transparent transparent transparent;
                          }
                        }
                      }
                      &-fee {
                          min-width: 15px;
                      }
                      &-discount {
                      //   top: 50px;
                      }
                      img {
                        width: 15px;
                        height: 15px;
                      }
                    }

                    .warning-marker {
                      margin-left: 6px;

                      .warning-icon {
                          height: 13px;
                          width: 13px;
                          background-color: $orange-2;
                          color: $white;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-size: 9px;
                          border-radius: 2px;
                      }

                      .warning-tooltip {
                        position: relative;
                        visibility: hidden;

                        .warning-text {
                          position: absolute;
                          width: 208px;
                          top: -53px;
                          transform: translateX(-46.5%);
                          z-index: 1;
                          text-align: center;
                          padding: 5px;
                          color: $white;
                          background-color: $black;
                          font-size: 13px;
                          border-radius: 4px;
                          line-height: 1.54;

                          &::after{
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: black transparent transparent transparent;
                          }
                        }
                      }

                      &:hover {
                          .warning-tooltip {
                              visibility: visible;
                          }
                      }
                  }
                  }

                  .project-code-with-icon-block {
                    display: flex;
                    align-items: flex-start;
                    column-gap: 6px;

                    .minimum-fee-icon {
                      margin-top: 3px;
                     }
                  }

                  .projects-list-item-sub-title, a {
                    font-size: 15px;
                    color: $gray-black;

                    a {
                        color: $gray-black;
                    }
                    display: flex;
                    align-items: center;
                  //   position: relative;
                  }

                  .invalid {
                      color: $pink;
                  }

                  .completed {
                      color: $green;
                  }

                  .projects-list-input {
                      padding: 14px 10px;
                      font-size: 13px;
                      display: flex;
                      align-items: center;
                      //font-size: 15px;
                      color: $gray-black;
                      border: 1px solid $gray-2;
                      height: 40px;
                  }

                  .first-fixed {
                      position: sticky;
                      left: 0;
                      text-align: end;
                  }

                  .second-fixed {
                      position: sticky;
                      left: 140px;
                      text-align: end;
                  }
          }

          .project-tooltip-triger {
              height: 90%;
              width: 30px;
              background-color: $white;
              display: flex;
              justify-content: center;
              align-items: center;
              position: sticky;
              right: 5px;
              cursor: pointer;

              &:hover {
                  .project-tooltip {
                      display: flex;
                      flex-direction: column;
                  }
              }
          }

          .project-tooltip {
              display: none;
              width: 168px;
              height: 60px;
              position: absolute;
              right: 30px;
              top: -3px;
              padding: 10px;
              z-index: 100;
              background-color: $white;
              border: 1px solid $gray-2;

              .project-tooltip-button {
                  padding: 12px 14px;
                  border: none;
                  outline: none;
                  background-color: transparent;
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  font-size: 15px;

                  & > span {
                      width: 14px;
                      height: 16px;
                      margin-right: 12px;
                  }

                  &:hover {
                      cursor: pointer;
                      background-color: $gray-3;
                  }
              }
          }
      }
      border-radius: 6px;
      background-color: $white;
    }
  }

  &-clients {
    background-color: $gray-3;
    &-content-empty {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      padding: 30px 50px 40px 50px;
    }
    .modal-structure {
      &-fee-describe {
        width: 380px;
        text-align: center;
        span {
          color: $green;
        }
      }
    }
    .discounts-modal {
      &-title {
        margin-bottom: 0;
      }
      &-sub-title {
        span {
          color: $green;
        }
      }
      .modal-structure {
        &-body {
          margin-top: 30px;
          width: 380px;
          &-header {
            margin-bottom: 30px;
          }
          &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $gray-2;
            padding: 25px 0;
            font-size: 15px;
            font-family: $GTRegular;
            .green {
              color: $green;
            }
            &-title {
              color: $gray-black;
            }
          }
          &-bottom {
            padding: 25px 30px;
            background-color: $gray-3;
            &-row {
              font-size: 13px;
              padding: 10px 0;
              &:first-child {
                padding-top: 0;
                padding-bottom: 20px;
              }
              &:last-child {
                border-bottom: none;
              }
              .fixed-span {
                width: 20%;
                &:last-child {
                  text-align: end;
                  width: 25%;
                }
              }
              .math-operator {
                color: $gray;
                font-size: 16px;
                &:nth-child(4) {
                  text-align: center;
                }
              }
              .total {
                color: $gray-black;
                flex: 0.62;
                text-align: end;
              }
            }
          }
        }
      }
    }
    &-filter {
      background-color: $white;
    }
    &-filter {
      padding:30px 50px;

      .filter-item {
        // Added default dropdown style, which has been removed in 2b2bb8aa7157f39b1642a8a72e07c560f514e7a7
        width: 185px;
        min-width: 280px;
        & > div:first-child {
          height: 40px;
        }
        & > div:last-child {
          width: 100%;
          max-width: unset;
          max-height: 280px;
          margin-top: 10px;
        }
      }
      .filter-labels {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    &-list {
      margin-top: 10px;
      &-item {
        background-color: $white;
        padding: 50px 50px 25px;
        margin-bottom: 10px;
        display: flex;
        .left {
          width: 20%;
          margin-right: 50px;
          &-client {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: $GTMedium;
            color: $black;
            border-top: 1px solid $gray-3;
            border-bottom: 1px solid $gray-3;
            padding: 15px 0;
            margin-bottom: 20px;
            &-avatar {
              width: 30px;
              height: 30px;
              border: 1px solid $gray-2;
              border-radius: 50px;
              position: relative;
              margin-right: 15px;
              img {
                width: 16px;
                height: 16px;
                position: absolute;
                left: 50%;
                right: 50%;
                top: 5px;
                transform: translate(-50%, 0%);
              }
            }
          }
        }
        .right {
          width: 80%;
          &-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $gray-3;
            padding: 15px 40px 15px 0;
            font-size: 15px;
            height: 62px;
            &:first-child {
              border-top: 1px solid $gray-3;
            }
            &:hover {
              .more-item {
                visibility: visible;
              }
              .marker:not(.marker-disabled) {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          &-show-more {
            font-size: 15px;
            color: $gray-black;
            margin-top: 15px;
            cursor: pointer;
          }
          &-service {
            display: flex;
            align-items: center;
            width: 65%;
            span {
              font-family: $GTMedium;
              width: 160px;
            }
            &-price {
              display: flex;
              width: 35%;
              .item-start {
                margin-right: auto;
              }
              .item-end {
                margin-left: auto;
              }
              &-item {
                color: $gray-black;
                display: flex;
                align-items: center;
                font-family: $GTRegular;
                position: relative;
                &:not(:last-child) {
                  //margin-right: 60px;
                }
                .price-black {
                  color: $black;
                }
                .more-item {
                  width: 20px;
                  height: 20px;
                  padding: 0 5px;
                  font-size: 14px;
                  border: 1px solid $gray-2;
                  margin-left: 10px;
                  visibility: hidden;
                  &:hover {
                    .content-types-tooltip {
                      display: block;
                    }
                  }
                }
                .content-types-tooltip {
                  position: absolute;
                  right: -63px;
                  bottom: 150%;
                  width: 155px;
                  background: $black;
                  color: $white;
                  padding: 10px 30px 15px;
                  display: none;
                  transition: 0.3s all ease;
                  &-list {
                    text-align: center;
                    &-item {
                      font-family: $GTRegular;
                      font-size: 13px;
                      &:hover {
                        color: $gray-black;
                      }
                    }
                  }
                  &:after {
                    content: " ";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: black transparent transparent transparent;
                  }
                }
              }
            }
          }
        }
        .marker {
          display: inline-flex;
          align-items: center;
          font-size: 13px;
          color: $gray-black;
          font-family: $GTRegular;
          span {
            font-family: $GTMedium;
          }
          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.freelancer-financial-info-list {
  margin: 22px 50px 0;
}