.evaluation-tab {
  &_container {
    @include width-container(1340px);
    margin-bottom: 50px;
    padding: 50px 0;
    border-radius: 6px;
    display: flex;
    background-color: $white;
  }

  &_deadline {
    margin-right: 60px;
  }

  &_evaluation {
    width: 100%;
  }

  &_title {
    margin-bottom: 23px;
    font-family: $GTMedium;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: -0.285px;
  }
}