.default-freelancer-rate {
  &-link {
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 25% 180px repeat(7, 1fr);
    column-gap: 4px;
    /*grid-template-columns: 290px 200px 140px 121px 100px 110px;
    gap: 45px;*/
    align-items: center;
  }
  &-plans {
    &-list {
      padding: 0 25px;
      margin-bottom: 30px;
      a {
        text-decoration: none;
      }
      &-header {
        text-transform: lowercase;
        display: grid;
        /*grid-template-columns: 265px 175px 115px 95px 75px 80px;
        gap: 70px;*/
        padding: 20px 0;
        grid-template-columns: 25% 180px repeat(7, 1fr);
        column-gap: 4px;
        align-items: center;
        border-bottom: 1px solid $gray-3;
        border-top: 1px solid $gray-3;
        font-family: $GTRegular;
        font-size: 15px;
        color: $black;
        //font-weight: bold;
        &-wrap {
          padding: 0 25px;
        }
      }
      &-item {
        background-color: $white;
        padding: 10px 25px;
        position: relative;
        &:hover {
          background-color: $gray-3;
        }
        &-title {
          font-family: $GTRegular;
          font-size: 15px;
          color: $black;
        }
        &:after {
          content: '';
          position: absolute;
          display: block;
          background-color: $gray-3;
          bottom: 0;
          height: 1px;
          width: 96.3%;
        }
      }
    }
    &-title {
      font-family: $GTMedium;
      font-size: 32px;
      color: $black;
    }
    &-description, &-clients, &-grayClients {
      font-family: $GTRegular;
      font-size: 15px;
    }
    &-description {
      color: $gray-black;
    }
    &-clients {
      color: $black;
    }
    &-grayClients {
      color: $gray-black;
    }
    &-no-clients {
      color: $orange-2;
    }
    &-left {
      display: flex;
    }
  }
}