.modal-structure {
  &-header {
    .sub-title {
      font-family: $GTRegular;
      font-size: 15px;
      color: $gray-black;
      margin-top: 10px;
    }
  }
  &-body {
    background-color: $white;
    .confirmation-plan-modal {
      .content {
        margin: 0;
        .modal-structure {
          .modal-buttons {
            margin-top: 50px;
          }
        }
      }
    }
    .form {
      &-group {
        position: relative;
        width: 380px;
        &:not(:last-child) {
          margin-bottom: 25px;
        }
        .error-input {
          color: $red;
          font-size: 13px;
          margin-left: 5px;
        }
        .is-invalid {
          border: 1px solid $red;
        }
      }
      &-label {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        color: $gray-black;
        margin-bottom: 6px;
        display: block;
      }
      &-controls {
          border: 1px solid $gray-2;
          height: 40px;
          width: 100%;
          padding: 10px 15px;
          cursor: pointer;
          resize: none;
      }
      &-choosed-color {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 46px;
        cursor: pointer;
      }
    }
    .plan-options {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      &-item {
        display: flex;
        &:not(:last-child) {
          margin-right: 40px;
        }
        .radio-button {
          margin-right: 10px;
        }
      }
    }
    .default-rate-item {
      width: 100%;
      margin-right: 15px;
      margin-top: 10px;

      & > div:first-child {
        height: 40px;
        text-transform: initial;
      }
      & > div:last-child {
        width: 100%;
        max-width: unset;
        max-height: 280px;
        margin-top: 10px;
      }
    }

    .custom-dropdown {
      width: 100%;
      border: 1px solid $gray-2;
    }

    .chrome-picker {
      width: 100% !important;
    }
  }
  .modal-buttons {
    margin-top: 35px;
  }
}

.convert-modal {
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-modal {
    margin: 0;
  }

    &-header {
      .sub-title {
        font-family: $GTRegular;
        font-size: 15px;
        color: $gray-3;
        margin-top: 10px;
      }
    }
    &-body {
      .form {
        &-group {
          position: relative;
          margin-top: 30px;
          width: 380px;
          &:not(:last-child) {
            margin-bottom: 19px;
          }
          .error-input {
            color: $red;
            font-size: 13px;
            margin-left: 5px;
          }
          .is-invalid {
            border: 1px solid $red;
          }
        }
        &-span-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 7px;
          justify-content: center;
          flex-wrap: wrap;
          text-align: center;
          width: 90%;
          margin-left: 20px;
        }
        &-span {
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.6;
          letter-spacing: normal;
          color: $gray-black;
          overflow-wrap: break-word;
        }
        &-label {
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: normal;
          color: $gray-black;
          margin-bottom: 6px;
          display: block;
        }
        &-controls {
            border: 1px solid $gray-2;
            height: 40px;
            width: 100%;
            padding: 10px 15px;
            cursor: pointer;
            resize: none;
        }
        &-choosed-color {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 15px;
          top: 46px;
          cursor: pointer;
        }
      }
      .plan-options {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        &-item {
          display: flex;
          .radio-button {
            margin-right: 10px;
          }
        }
      }
      .default-rate-item {
        width: 100%;
        margin-right: 15px;
        margin-top: 10px;
  
        & > div:first-child {
          height: 40px;
        }
        & > div:last-child {
          width: 100%;
          max-width: unset;
          max-height: 280px;
          margin-top: 10px;
        }
      }
  
      .custom-dropdown {
        width: 100%;
        border: 1px solid $gray-2;
      }
  
      .chrome-picker {
        width: 100% !important;
      }
    }
    .modal-buttons {
      margin-top: 50px;
    }
  }