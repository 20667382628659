.overlay {
  position: fixed;
  inset: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  overflow-y: auto;

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  &_content {
      position: absolute;
      top: 0;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &_close-btn {
    position: fixed;
    top: 30px;
    right: 30px;
    border: none;
    border-radius: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    font-size: 20px;
    cursor: pointer;
  }
}