@import './src/assets/styles/mixins/responsive';
.deadlineblock {
  width: 170px;
  position: relative;
  padding: 21px 30px 30px;
  border-radius: 8px;
  background: #F4F4F4;
  @include media(mobile){
    width: 100%;
  }
  & .day-picker-container{
    position: absolute;
    background-color: $white;
    z-index: 2;
    right: -280px;
    top: 0;
  }

  &.active {
    background: rgba(130, 225, 144, .15);
  }

  &.warn {
    background: rgba(255, 151, 81, .12);
  }

  &.expired {
    background: rgba(231, 112, 116, .15);
  }

  &_date {
    margin-bottom: 2px;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.255px;
  }

  &_type {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #ABABAB;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
