.profile-page-wrap {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.financials {
  & > .tabs-and-search {
    border-top: 1px solid $gray-3;
  }
}

.profile,
.financials {
  & > .tabs-and-search {
    width: 100%;

    .tabs-wrap {
      .tab-items {
        &:before {
          bottom: 0;
          top: unset;
        }
      }
    }
  }
}
///HERE

.financials {
  & > .tabs-and-search {
    border-top: 0;
    background: $white;
    padding: 10px 60px 0;
    .tab-items {
      padding: 20px 0;
      margin-right: 30px;
      .active,
      &:before {
        width: 100%;
        left: 0;
        top: unset !important;
        bottom: 0 !important;
        background-color: $yellow-1;
      }
    }

    .tabs-wrap {
      .tab-items {
        &:before {
          top: 0;
          bottom: unset;
        }
      }
    }
  }

  &-content-wrap {
    @include width-container(1340px);
  }
}
