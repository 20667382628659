@import './src/assets/styles/mixins/responsive';
.row-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 50px 0;
  @include media(mobile){
    padding: 33px 0;
    display: block;
  }
  .participants {
    color: $gray;
    font-size: 15px;
    line-height: 40px;
    margin-bottom: 26px;
    margin-top: 4px;
    text-align: center;
  }

  .participants:first-letter {
    text-transform: uppercase;
  }

  .user-info {
    a {
      display: block;
      text-decoration: none;
    }

    margin-right: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .flex{
      display: flex;
      justify-content: space-around;

      a{
        flex: 1;
      }

      .user-avatar{
        height: 40px;
        width: 40px;
        object-fit: cover;
      }

      .user-name{
        font-size: 13px;
        line-height: 18px;
      }
    }

    .user-message {
      position: relative;
      width: 272px;
      height: 104px;
      border: solid 1px $gray-2;
      color: $gray-black;
      font-style: italic;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 27px;
      font-size: 15px;
      line-height: 22px;
      padding: 20px;
      text-align: center;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $white;
        transform: rotate(45deg) translateX(-100%);
        left: 50%;
        bottom: -15px;
        border: 1px solid;
        border-bottom-color: #dfdfdf;
        border-right-color: #dfdfdf;
        border-left-color: transparent;
        border-top-color: transparent;
      }
    }

    .user-avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #f0f0f0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .user-name {
      text-align: center;
      font-size: 17px;
      font-family: $GTMedium;
      line-height: 30px;
      color: $black;
      margin-top: 11px;
      display: block;
      text-decoration: none;
    }
  }
}

.google-doc-draft {
  .document-icon {
    width: 46px;
  }
  .document-container {
    background-color: $white;
    overflow: hidden;
  }
}

.generated-documents-download-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 0;
  padding: 15px 15px 15px 30px;
  height: 70px;
  border-radius: 8px;
  background-color: $blue-4;
  font-size: 15px;
  letter-spacing: -0.3px;
  .text {
    font-weight: normal;
    line-height: 1.33;
    color: $black;
    font-family: $GTRegular;
  }
  button {
    width: 148px;
    height: 40px;
    float: right;
    font-family: $GTMedium;
    padding: 9px 30px 12px;
    border-radius: 8px;
    background-color: $black;;
    color: $white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    svg {
      width: 12px;
      height: 13px;
      object-fit: contain;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.drafts-container {
  display: flex;
  padding-bottom: 30px;
  width: 100%;

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    height: 4px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c6c6c6;
  }

  .document-loader {
    padding: 39px 0;
    display: flex;
    justify-content: center;

    img {
      width: 100px;
    }
  }

  .show {
    display: block !important;
  }

  .drafts-item-wrap {
    position: relative;
  }

  .draft-item-title {
    font-size: 15px;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .draft-item-number {
      min-width: 40px;
    }

    .text {
      color: $black;
      align-items: flex-start;
    }
  }

  .draft-item-number {
    width: 40px;
    font-family: $GTMedium;
    height: 40px;
    border-radius: 50%;
    background-color: $gray-3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 19px;
    color: $gray;
  }

  .card-container {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  .design-wrapper {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-3;
  }

  .design-card-header {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .title-container {
      width: 272px;
    }

    .unread-count {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -30px;
      top: 0;
      font-size: 12px;
    }

    .draft-item-title {
      margin: 0;
      position: relative;
      display: inline-flex;

      .draft-item-number {
        background-color: $green;
        color: $white;

        &.in-progress {
          background-color: $yellow;
          color: $black;
        }
      }

    }

    .feedback-title {
      font-size: 15px;
      color: $black;
      margin-left: 25px;
      position: relative;
      height: 40px;
      line-height: 40px;
    }

    .toggle-button {
      width: 40px;
      height: 40px;
      border: 1px solid $gray-2;
      border-radius: 50%;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: $gray-2;
        transition: all 0.3s;
      }

      &.opened svg {
        transform: rotate(180deg);
      }
    }
  }

  .design-card-container {
    display: flex;
    width: 100%;
    margin-top: 30px;
    padding-top: 30px;

    &:last-of-type {
      margin-top: 0;
    }

    .draft-item-title {
      .in-progress {
        background-color: $yellow;
        color: $black;
      }
    }

    .finish-or-deliver-buttons {
      margin-top: 80px;

    }

    .feedback-delivered {
      color: $orange-3;
    }

    .actions-button {
      margin-top: 20px;

      .client-actions-buttons {
        margin-top: 70px;
        position: relative;
      }
      .copywriter-actions-buttons, .writer-actions-buttons {
        position: relative;
      }
    }

    .draft-item {
      min-height: 400px;

      .modal-popover {
        button {
          width: 90px;
        }
      }
    }

    .design-logo {
      justify-content: center;

      img {
        width: 85px;
        text-align: center;
      }
    }
  }

  .draft-item {
    width: 100%;
    background-color: $gray-3;
    padding: 30px;
  }

  .draft-container {
    width: 272px;
    min-width: 272px;

    .document-icon {
      margin: auto;
      width: 64px;

      path {
        fill: $gray-2;
      }
    }

    .upload-feedback-icon {
      width: 137px;
    }
  }

  .active-draft,
  &.isActiveGoogleDraft {

    .document-container {
      background-color: $white;
      overflow: hidden;
    }

    .remove-or-download-buttons {
      display: flex;
    }

    .draft-item-number {
      background-color: $green;
      color: $white;
    }
    .document-icon {
      width: 46px;
      path {
        fill: $green;
      }
    }
    .upload-feedback-icon {
      width: 137px;
    }
  }

  .draft-name {
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    margin-bottom: 5px;
    text-transform: lowercase;
    width: 100%;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
  }

  .finish-or-deliver-buttons {
    position: relative;
    margin-top: 30px;

    .feedback-modal-popover {
      top: 40px;
    }
  }

  .finish-btn {
    @include button(100%);
    @extend .yellow-button;
    margin-bottom: 10px;
    text-transform: lowercase;
  }

  .deliver-button {
    padding: 0;
    width: 100%;
    border: none;
    color: $black;
    display: block;
    cursor: pointer;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    text-transform: lowercase;
    text-decoration: underline;
    background-color: transparent;
  }

  .remove-or-download-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $gray-3;
  }

  .icon-button {
    width: 100%;
    border: none;
    background-color: $white;
    cursor: pointer;
    height: 40px;
    padding: 0;
    transition: background-color .3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(111, 111, 111, 0.7);
    }

    .icon-button-img {
      width: 14px;
      fill: $black;
    }
  }

  .delete {
    border-right: 1px solid $gray-3;
  }

  .upload {
    svg {
      transform: rotate(-180deg);
    }
  }

  .document-preview {
    width: 100%;
    height: 100%;
    background-color: rgba(111, 111, 111, 0.70);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .small-icon {
      width: 36px;
      height: 24px;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      path {
        fill: $white;
      }
    }
  }

  .document {
    position: relative;
    height: 136px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &:hover {
      .document-preview {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.project-container {
  .drafts-container {
      overflow-y: auto;
    @include media(mobile){
      overflow-y: initial;
    }
  }
}

.design-draft-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 950px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .drafts-container {
    flex-direction: column-reverse;
    padding-right: 10px;
    padding-bottom: 0;

    &.overflow-visible {
      overflow-y: visible;
    }
  }
  .document {
    .palette-icon {
      width: 50px;
    }
  }
}

.design-draft-container,
.drafts-container {
  &::-webkit-scrollbar {
    background-color: $gray-3;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $gray;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: $gray-3;
  }
}

.project-finished {
  margin-top: 30px;
  font-weight: bold;
  bottom: 0;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.no-accepted-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img{
    width: 360px;
  }

  & h1{
    font-family: $GTMedium;
    font-weight: 500;
    font-size: 19px;
    margin-top: 17px;
  }

  & h3{
    font-family: $GTRegular;
    font-weight: 400;
    font-size: 15px;
    color: #ABABAB;
    margin-top: 5px;
  }
}
