.deadline-wrapper {
  display: flex;
  align-items: center;

  .deadline {
    &_alarm {
      margin-right: 7px;
      width: 14px;
      height: 14px;
      padding: 4px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: white;
      background-color: #ff9751;
    }
  
    &_date {
      font-size: 15px;
      line-height: 22px;
      color: #ababab;
  
      &__alarm {
        color: #ff9751;
      }
    }
  }
}