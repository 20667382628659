.service-block-body {
    width: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    .service-setting-block-content {
        @include width-container(1240px);
        margin-top: 30px;
        margin-bottom: 60px;

        .service-setting-block-container {
            background-color: $gray-3;
            padding: 30px;
            height: max-content;
            margin-bottom: 30px;
            margin-top: 10px;
            &-header {
                font-size: 18px;
                font-family: $GTRegular;
                font-weight: bold;
                border-bottom: 1px solid $gray-2;
                padding-bottom: 20px;
                margin-bottom: 15px;
            }

            .multiselect-dropdown {
                width: 280px;
                height: 40px;
                
                .multiselect-header{
                    height: 40px;
                    text-transform: capitalize;
                    background-color: $white;
                    color: $black;
                }

                .body {
                    max-width: 280px;
                    margin-top: 10px;
                }
            }
        }
    }
    .service-setting-block-tabs {
        background-color: $white;
        display: flex;
        align-items: center;
        &+.service-setting-block-container {
            margin-top: 0;
        }
    
        a {
            text-decoration: none;
        }
    
        .setting-block-items {
            font-family: $GTMedium;
            cursor: pointer;
            font-size: 15px;
            padding: 0 30px;
            height: 60px;
            background-color: transparent;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border: none;
            color: $gray-black;
            transition: all .3s;
            z-index: 1;
        
            &:hover {
                background-color: $gray-3;
                color: $black;
            }
        
            &.active{   
                background-color: $gray-3;
                color: $black;
            }
        }
    }
}


.service-item {
    display: flex;
    flex-direction: column; 
    margin-right: 20px;  
    width: 280px;  


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
}

.settings-filter {
    grid-area: filter;
    width: 100%;
}

.settings-filters-list {
    width: 100%;
    display: flex;
}

.settings-filters-row {
    width: 100%;
    display: grid;
    grid-template-columns: 26% 48.1% 24%;
    grid-template-areas: 'filter prices buttons';
    align-items: center; 

    
    .settings-prices-list {
        grid-area: prices;
        width: 100%;
        display: flex;
    }
}

.prices-row {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'plan clientPlan';
}

.plans-container {
    width: 1200px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'plan clientPlan';
}

.plan-container {
    grid-area: plan;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.client-plan-container {
    grid-area: clientPlan;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.plan-title {
    color: $blue-light;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 15px;
}

.price-type {
    display: flex;
    align-items: center;

    .price-type-label {
        color: $black;
        font-size: 15px;
        font-weight: normal;

        &:hover {
            text-decoration: underline;
        }
    }
    
    .price {
        color: $black;
        margin-left: 30px;
        cursor: grab;
    }

    .different-price-block {
        display: flex;
        align-items: center;
        margin-left: 30px;  
        
        .different-price {
            color: $orange-2;
            cursor: grab;
        }

        .different-price:hover {
            text-decoration: underline;
        }
    }

    .difference {
        width: 30px;
        height: 12px;
        background-color: $orange-2;
        color: $white;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        cursor: grab;
    }

    .caret{
        path {
            fill: $white;
        }
    }

    .caret-up {
        transform: rotate(180deg);

        path {
            fill: $white;
        }
    }

    .price:before,
    .different-price-block:before {
            content: "\20AC";
            margin-right: 5px;
            height: 20px;
            color: $gray;
        }
}

.settings-filters-labels {
    width: 100%;
    display: flex;
    margin-top: 5px;
}

.minimum-fee-input-wrapper {
    position: relative;
    .service-setting-input {
        padding-left: 25px;
    }
    &::before {
        content: "\20AC";
        position: absolute;
        top: 21px;
        left: 10px;
        color: #dfdfdf;
        z-index: 1;
        font-size: 13px;
    }
}

.service-setting-input {
    height: 40px;
    width: 280px;
    border: solid 1px $gray-2;
    position: relative;
    background-color: $white;
    color: $black;
    font-size: 13px;
    padding-left: 15px;
    margin-top: 10px;
    &-readonly {
        color: $gray-7;
    }
}

.service-item-input, .service-item-input-after {
    height: 40px;
    width: 280px;
    border: solid 1px $gray-2;
    position: relative;
    background-color: $white;
    color: $gray-black;
    font-size: 13px;
    margin-top: 10px;

    input {
        width: 245px;
        height: 100%;
        border: none;
        margin-left: 25px;
        color: $black;
    }

    &-readonly {
        & > input {
            color: $gray-7;
        }
    }


    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }

    &:before {
        position: absolute;
        content: "\20AC";
        top: 10px;
        left: 10px;
        color: $gray-2;
    }
    &-perPrice {
        margin-top: 0;
    }
}

.service-item-label {
    color: $gray-black;
    font-size: 15px;
    margin-bottom: 16px;
    font-weight: normal;
}

.overall-service-checkbox {
    grid-area: buttons;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 45px;
    margin-right: 30px;
    line-height: 40px;

    .form-check {
        display: flex;
    }

    .service-item-label {
        margin-bottom: 0;
    }
}

.service-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .service-item-label {
        margin-bottom: 5px;
    }
}

.discount-label-row {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-areas: 'category from to price';
}

.discount-row {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-areas: 'category from to price';
    margin-bottom: 10px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
}

.discount-from {
    grid-area: 'from';
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.discount-to {
    grid-area: 'to';
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.discount-price {
    grid-area: 'price';
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.discount-percent {
    grid-area: 'percent';
    width: 100%;
    display: flex;
    align-items: flex-end;
}