@import './colors';
@import './fonts';
/**
global style for button
 */
@mixin button($width : 160px, $height : 40px,$border : 0, $fontSize : 15px, $font : $GTMedium, $textAlign : center, $padding: 0, $border-radius: 0) {
  padding: $padding;
  outline: none;
  width: $width;
  display: flex;
  height: $height;
  border: $border;
  cursor: pointer;
  font-family: $font;
  align-items: center;
  font-size: $fontSize;
  text-decoration: none;
  text-align: $textAlign;
  justify-content: center;
  text-transform: lowercase;
  transition: background-color .3s;
  border-radius: $border-radius,
}

.yellow-button {
  color: $black;
  background-color: $yellow-1;
  box-shadow: inset 0 -1px 0 0 $black;
  @include button();

  &.active,
  &:hover {
    background-color: $yellow-2;
  }

  &.rounded {
    padding: 6px 14px;
    color: #000000;
    height: fit-content;
    width: fit-content;
    border-radius: 8px;
    box-shadow: none;
    font-size: 13px;
    line-height: 20px;
    background-color: #ffe403;
    opacity: 1;
  }
}

.black-button {
  color: $white;
  background-color: $black;

  &.active,
  &:hover {
    background-color: $black-2;
  }

}

.gray-button {
  color: $black;
  background-color: $gray-3;

  &.active,
  &:hover {
    background-color: $gray-2;
  }

  &.rounded {
    padding: 6px 14px;
    border: none;
    width: fit-content;
    font-size: 13px;
    line-height: 20px;
    color: #6f6f6f;
    background-color: #f2f2f2;
    transition: all .3s;
    opacity: 1;
    cursor: pointer;
    border-radius: 8px;
  }
}

.orange-button {
  color: $black;
  background-color: $orange-2;

  &.active,
  &:hover {
    background-color: $orange-3;
  }
}

.green-button{
  background-color: $light-green;
}

.transparent-button {
  background-color: transparent;
  color: #6f6f6f;
}

.black-link {
  border: none;
  color: $black;
  transition: color .3s;
  text-decoration: underline;
  background-color: transparent;

  &:hover {
    color: $gray-3;
  }
}

.orange-link {
  color: $orange-2;
  transition: color .3s;
  text-decoration: underline;

  &:hover {
    color: $orange-3;
  }
}
/**
mixin for width-container
 */
@mixin width-container($max-width : 1340px, $margin: 0 auto){
  width: 100%;
  margin: $margin;
  max-width: $max-width;
}
/**
mixin for avatar
 */
@mixin avatar($width : 57px, $height : 57px) {
  width: $width;
  height: $height;
  overflow: hidden;
  border-radius: 50%;
}
