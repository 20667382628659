@import './src/assets/styles/colors';
@import './src/assets/styles/mixins/responsive';

.reg-form-page {
  @include width-container(1340px);
  position: relative;
}

.steps-form {
  .reg-form-wrap {
    margin: 0;

    .form-container {
      margin: 0;
      padding: 0;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.login-form-wrap,
.reg-form-wrap {
  @include width-container(500px, 0 0 100px 140px);

  @include media(mobile){
    margin: 0 30px 50px 30px;
    max-width: 100%;
    width: initial;
  }
  form {
    position: relative;
  }

  .logo-form {
    width: 27.6px;
    margin-bottom: 27px;
  }

  .title-form {
    margin-bottom: 25px;
    line-height: 50px;
    font-size: 50px;
    letter-spacing: -2px;
    color: $black;
    font-family: $GTMedium;
    @include media(mobile){
      font-size: 30px;
      margin-bottom: 0;
    }
  }

  .description-form {
    margin-top: -10px;
    font-size: 17px;
    margin-bottom: 36px;
    line-height: 2.67;
    color: $gray-black;
    &.change-pass {
      margin-top: 7px;
      margin-bottom: 41px;
      line-height: 30px;
    }
    @include media(mobile){
      font-size: 15px;
      margin-bottom: 10px;
      color: $black;
    }
  }

  .form-input {
    margin-top: 5px;
    border: 1px solid $gray-2;
    height: 50px;
    width: 100%;
    padding: 5px 15px;
    outline: none;
    font-size: 15px;
    border-radius: 8px;
  }

  .hour-sign-up-input {
    padding-right: 60px;

    &_wrapper {
      position: relative;
    }

    &_hour-label {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .custom-dropdown {
    .custom-header {
      span::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .price-input {
    height: 50px;
    width: 100%;
    // padding: 0 10px;
    color: $black;
    border: solid 1px $gray-2;
    position: relative;
    border-radius: 8px;

    .form-input {
        height: 100%;
        border: none;
        margin-left: 16px;
        margin-top: 0;
        width: 480px;
    }

    &:before {
        position: absolute;
        content: "€";
        //height: 5px;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        color: $gray-black;
    }

  }
  .custom-items {
    min-width: 100%;
  }

  .form-group {
    .phone-input {
      width: 100%;
      height: 50px;
      border: 1px solid $gray-2;
      border-radius: 8px;
      font-size: 15px;
      font-family: $GTMedium;
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 21px;
    @include media(mobile){
      margin-bottom: 10px;
    }

    .label {
      font-size: 15px;
      line-height: 30px;
      color: $black;
      font-family: $GTMedium;
      display: flex;
      align-items: center;
      gap: 10px;
      .optionalText{
        color: $gray-16;
        font-family: $GTRegular;
        text-transform: lowercase;
      }
    }

    .sub-label {
      font-size: 15px;
      line-height: 30px;
      color: $gray-black;
      font-family: $GTMedium;
      display: inline-block;
    }

    &:nth-child(2) {
      margin-bottom: 40px;
      @include media(mobile){
        margin-bottom: 20px;
      }
    }

  }
  .form-check {
    display: flex;
    align-items: center;
    // padding-bottom: 37px;
    // border-bottom: 1px solid $gray-3;

    .custom-checkbox::before {
      margin-right: 14px;
    }
  }

  .custom-items {
    top: 50px;
    position: absolute;
    background-color: white;
    z-index: 1;
  }

  .services-area {
    height: 120px;
    width: 100%;

    .services-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 120px;
    }
  }

  .content-type-area {
    height: 240px;
    width: 100%;

    .content-type-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 240px;
    }
  }

  .industries-area {
    width: 100%;
    margin-top: 22px;

    .industries-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-width: 250px;

      .checkbox-label {
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 0;
        .experience-industry-icon{
          height: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  .service-type-checkbox {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;

    .form-check,
    .input-checkbox {
      margin: 0;
      width: 24px;
      height: 24px;
    }

    .checkbox-label {
      font-size: 15px;
      line-height: 2.67;
      margin-left: 14px;
      white-space: nowrap;
    }
  }

  .translation-area {
    width: 100%;
    padding: 20px 30px 30px 30px;
    background-color: $gray-3;

    .form-input {
      background-color: $white;
      width: 440px;
    }

    .form-group {
      margin-bottom: 0;
      .multiselect-dropdown {
        .multiselect-header {
          border: none;
          font-size: 15px;
          padding: 0;
          height: 40px;
          text-transform: none;
          .arrow-down {
            right: 15px;
          }
        }
        .isOpen{
          max-width: none;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &:last-of-type
      &:only-child{
        margin-bottom: 20px;
      }

      .form-input {
        background-color: $white;
        width: 260px;
        height: 40px;
        margin-right: 20px;
        margin-top: 0;
        padding: 0 15px;
      }
    }


    .language-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }


    .language-label {
      font-size: 15px;
      line-height: 2;

      span {
        color: $gray-black;
        margin-right: 20px;
      }
    }

    .remove-language {
      visibility: hidden;
    }

    .visible {
      visibility: visible;
    }

    .remove-icon {
      width: 10px;
      height: 10px;
    }

    .add-btn {
      height: 30px;
      background-color: $white;
      padding: 4px 12px 6px 11px;
      text-transform: lowercase;
      color: $gray-black;
      font-size: 13px;
      line-height: 1.54;
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .devider {
      width: 100%;
      border-top: 1px solid $gray-2;
      margin-top: 30px;
      margin-bottom: 19px;
    }
  }

  .remember-me-text {
    font-size: 15px;
    color: $black;
  }

  .buttons-container {
    margin-top: 40px;
    display: flex;
    flex-flow: row;
    justify-content: initial;
    // align-items: center;
    @include media(mobile){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .submit-button {
    @include button(135px, 60px);
    @extend .yellow-button;
    border-radius: 8px;
    background-color: #ffe403;
    margin-right: 40px;
    box-shadow: none;
    text-transform: capitalize;

    &.disabled {
      opacity: 0.3;
    }
  }

  .forgot-password {
    font-size: 15px;
    line-height: 2.67;
    color: $gray-black;
    transition: color .3s;
    @include media(mobile){
      margin-top: 15px;
    }
    &:hover {
      color: $black;
    }
  }

  .form-container {
    position: relative;
    z-index: 2;
    // box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    // padding: 50px;
    box-sizing: border-box;
    margin-top: 30px;
    padding-top: 20px;
    @include media(mobile){
      padding-top: 0;
    }
  }

  .label-with-infobutton {
    width: 100%;
    display: flex;
    align-items: center;

    .black-info-tooltip-content{
      white-space: pre-wrap
    }
  }

  .sign-up-text,
  .found-password {
    margin-top: 15px;
    margin-bottom: 103px;
    font-family: $GTMedium;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0;
    font-weight: 500;
    letter-spacing: .1px;
  }

  .link-sign-up {
    color: $black;
    font-size: 15px;
    // line-height: 2.67;
    // display: block;
    // text-align: center;
    transition: color .3s;
    &:hover {
      color: $gray-black;
    }
  }

  .is-invalid {
    border-color: $pink;
  }

  .input-error {
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
    color: $pink;
  }
  .bottom-input-error {
    font-size: 14px;
    color: $pink;
    margin-top: 3px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .divider {
    margin: 40px 0;
    height: 1px;
    width: 100%;
    background-color: $gray-3;
    border: none;
    @include media(mobile){
      margin: 30px 0;
    }
  }
  .tooltip {
    width: 200px;
  }
}

.reg-form-page {
  .title-form {
    margin-bottom: 45px;
  }

  .divider {
    margin: 40px 0 30px;
  }

  .input-tip {
    margin-top: 10px;
    font-size: 13px;
    line-height: 30px;
    color: $gray-black;
  }

  .reg-check {
    display: flex;
    align-items: flex-start;

    span {
      a {
        text-decoration: none;
        color: $malibu;
      }
    }
  }

  .reg-check.form-check .custom-checkbox {
    &:before {
      align-self: baseline;
      flex-shrink: 0;
    }

    &:after {
      top: 22%;
    }
  }

  .form-group ~ .form-checkbox-wrap {
    margin-bottom: 21px;
  }

  .form-group + .form-checkbox-wrap{
    margin-top: 42px;
  }

  .buttons-container {
    margin-top: 36px;

    .submit-button {
      @include button(153px, 60px);
      border-radius: 8px;
    }
  }
  .input-error {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
