@import './src/assets/styles/colors';

// used in Brainstorms
.new-project-page {
  position: relative;
  border-top: 3px solid $yellow;

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ol, ul {
    list-style: none;
  }
  .option-capitalize{
    text-transform: capitalize;
  }
  .width-container {
    @include width-container();
  }

  .close-add-new-project {
    border: none;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 90px;
    right: 30px;
    background-color: $gray-15;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
  }

  .step-back-new-project {
    border: 1px solid $gray-2;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 90px;
    left: 30px;
    background-color: $gray-15;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;
  }

  .cross-icon {
    width: 10px;
    height: 10px;
    fill: $black;
  }

  .step-progress {
    padding: 30px 0 158px;
  }

  .step,
  .progtrckr {
    @include width-container(500px);
  }

  .new-project-title {
    font-family: $GTMedium;
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 43px;
    text-align: center;
  }

  .progtrckr {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin-bottom: 60px;
    height: 60px;

    em {
      width: 32px;
      height: 32px;
      display: flex;
      border-radius: 100%;
      background-color: #9af08f;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      z-index: 2;
      font-size: 0;

      &:before {
        content: "";
        width: 14px;
        height: 14px;
        font-size: 15px;
        border-radius: 100%;
        background-color: transparent;
      }

    }

    span {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
      text-align: center;
      font-size: 15px;
      top: 100%;
      width: 145px;
      line-height: 20px;
      margin-top: 8px;
      text-transform: lowercase;
    }

    span:first-letter {
      text-transform: uppercase;
    }

    li {
      position: relative;

      & + li {
        margin-left: 120px;
      }

      &:not(:last-child) {
        &:before {
          content: '......';
          position: absolute;
          z-index: 1;
          text-align: center;
          vertical-align: middle;
          letter-spacing: 4px;
          left: 45px;
          width: 102px;
          font-size: 36px;
          line-height: 14px;
          color: $gray-3;
        }
      }
    }
  }

  .progtrckr-done {

    &:not(:last-child) {
      &:before {
        color: $green;
      }
    }

    em {
      background-color: $green;

      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        content: '✔';
      }
    }
    span {
      color: $black;
    }
  }
  .progtrckr-doing {
    em {
      background-color: $yellow;

      &:before {
        background-color: $white;
      }

    }
    span {
      color: $black;
    }
  }

  .progtrckr-todo {
    em {
      background-color: $gray-3;
    }
    span {
      color: $gray-3;
    }
  }

  .step {
    border-top: 1px solid $gray-3;
    padding-top: 40px;
  }

  .DayPickerInput {
    width: 100%;
    input {
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: $gray-black;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }
  }

  .form-group {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 19px;
    }

    .ql-editor {
      height: 70px;
    }

    .max-word-count {
      margin-top: 10px;
    }
  }

  .tooltip {
    left: 130px;
    .info-tooltip-content {
      top: -22px;
      left: 130px;
    }
  }

  .invalid {
    .react-month-picker{
      input {
        color: $pink;
        border-color: $pink;
      }
    }
  }

  .doc-wrap {
    margin-bottom: 15px;
  }

  .form-label {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: $gray-black;
    margin-bottom: 6px;
    display: block;
  }
  .form-label-description{
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $gray-black;
    margin-bottom: 15px;
  }

  .translation-form-group-element {
    position: relative;
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;

    .form-label {
      width: 50%;
      margin-bottom: 0;
    }
  }

  .form-price-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .form-input-group {
      width: 150px;
      display: flex;
      flex-direction: column;
      margin: 0;
      flex-shrink: 0;

      &.enter-quantity {
        width: 144px;
      }

      &.enter-price {
        width: 176px;
      }

      &.total-in-euro {
        width: 100px;
      }
    }

    .invalid {
      span {
        color: $pink;
      }
    }

    p {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-black;
    }

    span {
      color: $gray-black;
    }
  }

  .form-group-element {
    position: relative;

    .form-label {
      width: max-content;
    }
  }

  .form-select-invalid {
    .multiselect-header {
      border: 1px solid $red;
      color: $pink;
    }
  }

  .multiselect-dropdown {
    width: 100%;
  }

  .translation-form-select {
    width: 50%;

    .multiselect-header {
      height: 40px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      span {
        max-width: 200px;
        overflow: hidden;
      }
    }

    .body {
      margin-top: 10px;
      max-height: 180px;
      min-width: 250px;
    }
  }

  .form-select {
    width: 100%;

    .multiselect-header {
      height: 40px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }

    .body {
      margin-top: 10px;
      max-height: 180px;
      min-width: 500px;
    }
  }

  .form-control {
    border: 1px solid $gray-2;
    height: 40px;
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    resize: none;
  }

  textarea.form-control {
    height: 80px;
    &.textarea-smaller{
      height: 40px;
    }
  }

  textarea.form-control.small-textarea {
    height: 40px;
  }

  .footer-buttons {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .doc-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .remove-file {
    padding: 0;
    border: none;
    cursor: pointer;
    margin-left: 19px;
    background-color: transparent;
  }
  .add-btn {
    margin-right: 19px;
    @extend .gray-button;
    @include button(132px, 40px);

    &.disabled {
      background-color: $gray-2;
      cursor: not-allowed;
    }
  }
  .add-language {
    width: 90px;
    right: -160px;
    top: 36px;
    position: absolute;
  }
  .without-delete-button {
    right: -130px;
  }
  .remove-language {
    right: -35px;
    top: 45px;
    position: absolute;
    cursor: pointer;

    svg {
      width: 15px;

      path {
        fill: $gray-black;
      }
    }
  }
  .remove-file-icon {
    width: 12px;
  }

  .no-file-choosen {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: $gray;
  }

  .file-name {
    @include button(auto, 40px);
    color: $gray;
    text-align: left;
    width: 100%;
    justify-content: left;
  }

  .position-left,
  .position-right {
    @include button(141px, 44px);
    &:disabled{
      cursor: not-allowed;
      opacity: .3;
    }
  }

  .position-left {
    @extend .gray-button;
  }

  .position-right {
    @extend .yellow-button;
  }

  .is-invalid {
    border-color: $pink;
    color: $pink;

    .quill {
      border: 1px solid $pink;
    }
  }

  .error-input {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.31;
    letter-spacing: normal;
    color: $pink-light;
    margin-bottom: -10px;
  }
  .file-error {
    font-size: 13px;
    color: $pink-light;

  }

  // TODO Remove this styles block
  /*.please-give-access-block {
    margin-top: 13px;
    padding: 20px 30px;
    color: $dark-gray;
    font-size: 15px;
    line-height: 22px;
    border-radius: 8px;
    background-color: $gray-12;

    &__info {
      margin-bottom: 7px;
    }

    &__tooltip {
      display: inline-flex;
      margin-left: 3px;

      &-content {
        width: 300px;
      }
    }

    &__input {
      border-radius: 8px;
    }

    &__black {
      color: $black;
    }
    &__gray {
      color: $gray;
    }
    &__underline {
      text-decoration: underline;
    }

    &__warning-block {
      color: $orange-2;
      font-size: 13px;
      display: flex;
      column-gap: 8px;
      align-items: center;
      margin-top: 10px;
    }
  }*/
}

// TODO check
.checkbox-buttons-container {
  display: flex;
  margin-top: 20px;

  .checkbox-button {
    max-width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    label {
      margin-bottom: 6px;
      color: $gray-black;
      font-size: 15px;
      cursor: pointer;
    }

    input {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}
.tooltip-container {
  position: absolute;
  right: 22px;
  top: -11px;

  .tooltip-button {
    border: 1px solid $gray-black;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    font-size: 10px;
    text-align: center;
    line-height: 12px;
    color: $gray-black;
    cursor: pointer;
  }

  .tooltip-button:hover + .tooltip-content {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-content {
    font-size: 10px;
    color: $gray-black;
    position: absolute;
    width: 195px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 3px 1px rgba(0,0,0,0.25);
    z-index: 99;
    border-radius: 3px;
    margin-top: 10px;
    transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
  }
}
