.multiselect-dropdown{
  // This style has been removed in 2b2bb8aa7157f39b1642a8a72e07c560f514e7a7
  // width: 185px;
  position: relative;
  cursor: pointer;

  .multiselect-header{
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    font-size: 13px;
    border: solid 1px $gray-2;
    width: 100%;
    color: $black;
    height: 50px;
    text-transform: lowercase;
    border-radius: 6px;

    .multiselect-header-text{
      @include textOverflowOneLine;
      padding-right: 8px;
    }

    .arrow-down{
      position: absolute;
      top: 50%;
      right: 10px;
      width: 8px;
      transform: translateY(-50%);
      fill: $black;
    }

    &.withRoundCounter {
      justify-content: space-between;
    }

    .round-counter {
      margin: 0 10px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-family: $GTMedium;
      background-color: #fffecf;
    }
  }

  .multiselect-search {
    box-sizing: border-box;
    width: calc(100% - 28px);
    margin: 10px 14px;
    padding-left: 13px;
    height: 40px;
    border: 1px solid $gray-2;
    color: $black;
    font-size: 15px;
    border-radius: 6px;
  }

  .change-all {
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }

  .multiselect-search+.change-all {
    margin-top: 0;
  }

  .body{
    background-color: $white;
    position: absolute;
    z-index: 9999;
    width: auto;
    top: 30px;
    overflow: hidden;
    left: 0;
    right: 0;
    // This style has been removed in 2b2bb8aa7157f39b1642a8a72e07c560f514e7a7
    //max-width: 196px;
    display: none;
    border: 1px solid $gray-13;
    border-top: 0;
    border-radius: 8px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
      width: 16px;
      padding: 0 10px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: $gray-2;
        max-height: 68px;
        min-height: 40px;
        width: 6px;
        border: 5px solid $white;
        cursor: pointer;
    }

    .form-check {
      .custom-checkbox {
        &:before,
        &:after {
          flex-shrink: 0;
          min-width: 24px;
          min-height: 24px;
        }
        width: 100%;
        border-radius: 6px;

        &.checkbox-svg {
          position: relative;
          &:after {
            content: none;
          }

          svg {
            position: absolute;
            width: 10px;
            height: 7px;
            left: calc(24px / 2);
            transform: translateX(-50%);

            // &.with-text {
            //   right: unset;
            //   left: 4px;
            // }
          }
        }
      }
    }

    .form-all-check {
      height: 60px;
    }

    .divider {
      border-bottom: 1px solid $gray-3;
      margin: 0 15px 5px 15px;
      opacity: .5;
    }

  }
  .isOpen {
    display: block;
    overflow-y: auto;
    max-height: 200px;
  }

  .spinner {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 30px 10px 40px;
    line-height: 1.5;
    color: $gray-black;
  }
}
