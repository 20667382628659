.platform-update-container {
    width: 100%;
    background-color: #e5ffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 50px 16px 50px;

    .title-container {
        display: flex;
        align-items: center;
        height: fit-content;
        max-width: calc(100% - 100px);
        
        .icon {
            height: 13px;
            width: 13px;
            background-color: $black;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
            border-radius: 2px;
            margin-right: 12px;
        }

        .title {
            font-family: $GTRegular;
            font-size: 15px;
            color: $black;
            width: 100%;
        }
    }

    .close-icon {
        width: 8px;
        height: 8px;
        cursor: pointer;
    }

    .read-more-link {
        color: $black;
    }
}

.warning-bar {
    width: 100%;
    background-color: #fff0e5;
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 50px 16px 50px;

    .title-container {
        display: flex;
        align-items: center;
        height: fit-content;
        max-width: calc(100% - 100px);
        
        .icon {
            height: 13px;
            width: 13px;
            background-color: $black;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9px;
            border-radius: 2px;
            margin-right: 12px;
        }

        .title {
            font-family: $GTRegular;
            font-size: 15px;
            color: $black;
            width: 100%;

            span {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.impersonated {
    top: 60px;
}