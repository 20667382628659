.loading-screen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255,255,255,.8);
    // Max modal z-index is 9999999999, so we add 1
    // TODO Check z-index style across the platform
    z-index: 10000000000;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;

  &:not(.side-modal-loader) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

    &hide {
        display: none;
    }

    .spinner {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }

  &.side-modal-loader {
    width: 100%;
    position: relative;
  }
}