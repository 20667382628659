.freelancer-body {
	.tab-items {
		padding: 19px 38px 20px 39px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;

		&:after {
		  content: "";
		  position: absolute;
		  top: 100%;
		  left: 38px;
		  right: 0;
		  height: 2px;
		  background-color: $yellow-1;
		  opacity: 0;
		  transition: opacity .3s;
		  width: calc(100% - 77px);
		}

		&:before {
		  background-color: transparent;
		}
	  }

	  .tab-items {
		border-radius: 6px 6px 0 0;

		&:before {
		  height: 2px;
		  width: 53px;
		  margin: 0 auto;
		  top: unset;
		  bottom: 0;
		}
	  }

	.card {
		display: inline-block;
		padding: 10px 20px;
		border-radius: 6px;
		font-size: 15px;
		line-height: 1.33;
		color: $black;
		background-color: $gray-11;
		white-space: nowrap;

		display: flex;
		align-items: center;

		&.one {
			border: solid 1px $gray-2;
			background-color: $white;
			cursor: pointer;

		}
		img {
			margin-right: 9px;
			width: 17px;
			max-height: 12px;
			border-radius: 3px;
		}

		&:last-child {
			margin-right: 0;
		}

		.svg {
			width: 16px;
			height: 16px;
			margin-right: 11px;

			&.delete {
				width: 9px;
				margin-right: 0;
				margin-left: 15px;
				display: flex;

				svg {
					cursor: pointer;

					path {
						fill: $gray-black;
					}
				}
			}

			svg {
				width: 100%;
				height: auto;
			}
		}
	}

	.blue-link {
		display: inline-block;
		color: $blue-light;
		text-decoration: none;
	}

	.btn-block {
		// margin-left: 30px;
		display: flex;
	}

	.save-btn {
		@include button(55px, 30px);
		@extend .yellow-button;
		margin-right: 13px;
		font-size: 13px;
		border-radius: 6px;
		box-shadow: none;
		font-family: unset;
	  }

	  .cancel-btn {
		@include button(55px, 30px);
		@extend .transparent-button;
		font-family: unset;
		font-size: 13px;
	  }

	  .edit-btn {
		@include button(53px, 30px, 0, 13px, $GTRegular);
		border-radius: 6px;
		display: inline-block;
		color: $gray-black;
		opacity: 1;
		transition: all .3s;
	}

	.reviews-container {
		padding: 25px 50px 60px 50px;
		border-radius: 6px;
		background-color: #ffffff;
		width: 100%;
		margin: 0 auto;
		max-width: 1340px;

		.reviews-filter__filters-list {
			grid-template-columns: repeat(3, 290px) 40px;
		}

		.reviews-filter {
			position: relative;
		}

		.reviews-filter__download-button {
			position: absolute;
			right: 0;
		}
	}
}

.user-profile {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.info-item.bottom-mines {
		border-bottom: none
	}

	.full-width {
		width: 100%
	}

	.full-width>div {
		max-width: 100%;
	}

	.profile-information,
	.briefing-documents {
		flex-grow: 1;
		max-width: 595px;
	}

	.profile-information {
		margin-right: 50px;

		&-header {
			margin-bottom: 10px;
			padding-bottom: 9px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid $gray-3;
		}

		h2 {
			width: fit-content;
			display: inline-block;
		}

		span {
			text-transform: initial;
		}

		.warning-message {
			margin-top: 0;
			margin-left: 20px;
			align-items: center;

			.warning-title {
				line-height: normal
			}
		}

		.edit-btn {
			@include button(53px, 30px, 0, 13px, $GTRegular);
			display: inline-block;
			opacity: 1;
			border-radius: 6px;
			transition: all .3s;
		}

		&:hover {
			.edit-btn {
			  opacity: 1;
			}
		}

		.info-item {
			.label {
				width: 300px;
			}
		}

	}


	.DayPicker-Day
		:not(.DayPicker-Day--disabled)
		:not(.DayPicker-Day--selected)
		:not(.DayPicker-Day--today) {
		background-color: #89ee98;
		color: #000;
		border-radius: 0 !important;
	}

	.designer-about {
		width: 100%;
		max-width: 100%;
		padding: 15px 0;

		.label {
			width: auto;
			line-height: initial;
		}
	}

	.border-top {
		border-top: 1px solid $gray-3;
	}

	.briefing-documents-wrap {
		background-color: $gray-3;
		padding: 20px 30px;

		.document-name {
			width: 100%;
		}
	}

	.add-file {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 97px;
		height: 40px;
		background-color: $gray-2;
		color: $gray-black;
		cursor: pointer;

		&:not(:nth-child(2)) {
			margin-top: 10px;
		}

		svg {
			width: 10px;
			height: 10px;
			margin-right: 13px;
		}

		path {
			fill: $gray-black;
		}
	}

	.info-item {
		border: none;
	}

	.availability-container {
		height: 460px;

		.availability-buttons-wrapper {
			display: flex;
			gap: 13px;
		}

		&-header {
			display: flex;
			align-items: flex-start;
			margin-bottom: 20px;
			justify-content: space-between;
			.user-profile-title {
				margin: 0;
				display: inline;
				flex: 1;
			}

			.user-profile-edit-btn {
				width: 53px;
				height: 30px;
				text-transform: lowercase;
				border-radius: 6px;
				transition: all .3s;
				box-shadow: none;
				display: flex;
				align-items: center;

				&:hover {
					background-color: #dfdfdf;
				}

				&.active {
					width: auto;
					min-width: 55px;
					// border-radius: 0;

					&:hover {
						background-color: #f1ed36;
					}
				}
			}

			.user-profile-cancel-btn {
				width: 55px;
				height: 30px;
			}

			.warning-message {
				width: fit-content;
				display: inline-flex;
				margin-top: 0;
				margin-right: 20px;
			}

			&:hover {
				.user-profile-edit-btn {
					opacity: 1;
				}
			}
		}

		.info-item {
			max-width: 100%;
			margin-top: 0;
			border-radius: 6px;
		}

		.DayPicker {
			font-size: 14px;
			width: 100%;

			&-wrapper {
				padding: 0;
				width: min-content;
			}

			&-NavButton {
				background-color: $gray-3;
				background-size: 8px;
				margin: 0;
				border-radius: 6px;
				width: 40px;
				height: 40px;
				top: 0;

				&--next {
					right: 0;
				}

				&--prev {
					left: 0;
				}
			}

			&-Caption {
				&>div {
					font-size: 15px;
					line-height: 40px;
					text-align: center;
				}
			}

			&-Weekday {
				line-height: 20px;
				color: $gray;
			}

			&-Month {
				margin: 0;
				width: 100%;
				border-spacing: 1px;
				border-collapse: unset;
			}

			&-Week {
				height: 40px;
			}

			&-Day {
				width: 56px;
				line-height: 40px;
				padding: 0;
				border-radius: 0;
				max-width: 56px;
				min-width: 56px;
				background-color: $white;
				font-size: 13px;

				&--today {
					background-color: $green !important;
				}

				&--selected {
					background-color: rgba($red-2, 0.12) !important;
				}

				&--outside {
					background-color: transparent !important;
					border-color: $gray-3 !important;
				}

				&--disabled {
					color: $gray !important;
					cursor: not-allowed;
				}
			}
			//Select all workdays
			.DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(:nth-last-child(-n+2)) {
				background-color: rgba($light-green, 0.15);
			}
			//Select all weekends
			.DayPicker-Day:not(.DayPicker-Day--outside):nth-last-child(-n+2) {
				background-color: rgba($light-green, 0.15);
				border-radius: 0 !important;
			}
			//Select all weekends
			//.DayPicker-Day:not(.DayPicker-Day--outside):nth-last-child(-n+2) {
			//	background-color: $gray-14 !important;
			//	border-radius: 0 !important;
			//}

			// first not empty element in first row
			.DayPicker-Week:first-child .DayPicker-Day:not(:empty):first-child,
			.DayPicker-Week:first-child .DayPicker-Day:empty + .DayPicker-Day:not(:empty) {
				border-top-left-radius: 8px!important;
			}

			.DayPicker-Week:first-of-type .DayPicker-Day:last-of-type {
				border-top-right-radius: 8px!important;
			}

			.DayPicker-Week:nth-of-type(2) .DayPicker-Day:first-child {
				border-top-left-radius: 8px!important;
			}

			.DayPicker-Week:nth-last-of-type(2) .DayPicker-Day:last-child {
				border-bottom-right-radius: 8px!important;
			}

			.DayPicker-Week:last-of-type  .DayPicker-Day:first-of-type{
				border-bottom-left-radius: 8px!important;
			}

			// reset border radius if body has last row with 7th not empty element
			.DayPicker-Body:has(> .DayPicker-Week:last-of-type > :nth-child(7):last-child:not(:empty)) {
				.DayPicker-Week:nth-last-of-type(2) {
					.DayPicker-Day:last-child {
						border-bottom-right-radius: 0 !important;
					}
				}
			}

			// reset border radius if body has first row with 1th not empty element
			.DayPicker-Body:has(> .DayPicker-Week:first-of-type > :first-child:not(:empty)) {
				.DayPicker-Week:nth-of-type(2) {
					.DayPicker-Day:first-child {
						border-top-left-radius: 0 !important;
					}
				}
			}

			// last not empty element in last row
			.DayPicker-Week:last-child .DayPicker-Day:not(:empty):last-child,
			.DayPicker-Week:last-child .DayPicker-Day:not(:empty):has(+ .DayPicker-Day:empty) {
				border-bottom-right-radius: 8px!important;
			}

			&.DayPicker--interactionDisabled {
				.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):not(:nth-last-child(-n+2)):hover {
					background-color: $green !important;
				}
			}
		}


		.full-availability {
			margin-top: 25px;
		}
	}

	.document-title {
		color: $gray-black;
		font-size: 15px;
		line-height: 2;
		margin-bottom: 6px;
	}

	.download-icon,
	.document-icon {
		flex-shrink: 0;
	}


	.document-icon {
		width: 20px;
	}

	.download-icon,
	.delete-icon {
		width: 14px;

		path {
			fill: $gray-black;
		}
	}

	.document-icon {
		margin-right: 15px;

		path {
			fill: $green;
		}
	}

	.document {
		display: flex;
		line-height: 56px;
		padding-left: 17px;
		align-items: center;
		background-color: $white;
		justify-content: flex-start;
		border-bottom: 1px solid $gray-3;
	}

	.document-name {
		color: $black;
		font-size: 15px;
		max-width: 350px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-transform: lowercase;
	}

	.buttons-container {
		margin-left: auto;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-end;

		.download-btn,
		.delete-project {
			padding: 0;
			width: 50px;
			border: none;
			height: 56px;
			display: flex;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			background-color: transparent;

			&:first-child {
				border-left: 1px solid $gray-3;
				border-right: 1px solid $gray-3;
			}
		}
	}
}

.copywriter-profile {
	flex-wrap: wrap;
	padding: 26px 50px 50px 50px;
	column-gap: 60px;

	.user-profile-title {
		width: 100%;
	}

	.multiselect-dropdown {
		.body {
			max-height: 135px;
		}
	}

	.info-item {
		max-width: 550px;
		display: flex;
		flex-direction: column;
		line-height: 65px;
		justify-content: flex-start;
		padding: 0;
		margin-top: 20px;

		.DayPicker-Months {
			width: 400px;
		}

		.label {
			flex-shrink: 0;
			font-size: 15px;
			line-height: 2;
			margin-bottom: 6px;
			color: $gray-black;
			text-transform: capitalize;
		}

		.value {
			color: $black;
			font-size: 15px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			grid-gap: 10px;
			line-height: 24px;
		}
	}
}

.add-button {
	border-radius: 6px;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $gray-11;
	color: $gray-black;
	font-size: 19px;
	cursor: pointer;
}

.title-wrapper {
	display: flex;
	flex-direction: column;
}
