.tabs-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab-items {
    font-family: $GTMedium;
    cursor: pointer;
    font-size: 15px;
    padding: 30px;
    height: 30px;
    color: $gray-black;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    background-color: transparent;
    transition: all .3s;
    z-index: 1;
    border-radius: 6px 6px 0 0;

    &:hover {
      color: $black;
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 60px);
      bottom: 0;
      left: 30px;
      height: 2px;
      background-color: $yellow-1;
      opacity: 0;
      transition: opacity .3s;
    }

    &.active{
      &:before, &:after {
        opacity: 1;
      }

      background-color: $white;
      color: $black;
    }

    .tab-text-and-counter {
      position: relative;
      z-index: -1;
    }

    .count-messages {
      background-color: $yellow;
      min-width: 17px;
      height: 17px;
      border-radius: 8px;
      position: absolute;
      top: -8px;
      right: -10px;
      font-size: 11px;
      color: $black;
      padding: 2px 4px;
      text-align: center;
    }

    .count-badge {
      top: 10px;
      right: 10px;
    }
  }
}
