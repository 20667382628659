.search-select-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 8px;
  border: solid 1px $gray-13;
  background: $white;

  &.active {
    width: 100%;
    min-width: 320px;
    max-width: 350px;
    margin-right: 0;

    .show-search {
      position: absolute;
      margin: auto;
    }
    .search-icon {
      fill: $black;
    }
    .search-input {
      display: block;
    }
  }

  .show-search {
    background-color: transparent;
    cursor: pointer;
    border: none;
    width: 15px;
    height: 15px;
    z-index: 2;
    right: 16px;
    top: 0;
    bottom: 0;
    padding: 0;
    outline: none;
    flex-shrink: 0;

    &:hover {
      .search-icon {
        fill: $black;
      }
    }
  }

  .search-button-wrapper {
    display: flex;
    margin: 4px;
    height: 38px;
    padding: 6px 8px;
    border-radius: 5px;

    .freelancer-search-choice-right {
      border-radius: 0 5px 5px 0;
    }

    .freelancer-search-choice-left {
      border-radius: 5px 0 0 5px;
    }

    .freelancer-search-choice {
      cursor: pointer;
      width: max-content;
      border: none;
      font-size: 11px;
      color: $dark-gray;
      background-color: $white-smoke;

      &.search-active {
        color: $white;
        background-color: $black;
      }
    }
  }

  .search-input {
    display: none;
    height: 30px;
    width: 100%;
    padding: 6px 16px;
    letter-spacing: 1px;
    outline: none;
    border: none;
    background-color: transparent;

    &::-webkit-input-placeholder {
      color: $gray;
      letter-spacing: -0.3px;
    }
    &::-moz-placeholder {
      color: $gray;
      letter-spacing: -0.3px;
    }
    &:-ms-input-placeholder {
      color: $gray;
      letter-spacing: -0.3px;
    }
    &:-moz-placeholder {
      color: $gray;
      letter-spacing: -0.3px;
    }
  }

  .search-icon {
    fill: $black-3;
  }

  .select-wrap {
    width: 100%;
    min-width: 230px;
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    border: solid 1px $gray-13;
    background-color: $white;
    padding: 10px;
    z-index: 9999;
    letter-spacing: -0.3px;

    .subtitle {
      padding: 12px;
      font-size: 11px;
      color: $gray;
    }

    .select-list {
      width: 100%;
      max-height: 130px;
      overflow-y: auto;

      .element {
        padding: 12px;
        border-radius: 8px;
        font-size: 15px;
        display: flex;

        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
        }

        &:hover {
          background-color: $gray-14;
        }
      }
    }
  }
}
