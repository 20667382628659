.radio-button {
    cursor: pointer;

    input:checked + span {
        background-color: #ffe403;
    }

    input:checked + span:after {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 3px;
        background-color: $black;
        border-radius: 50%;
        border: 1px solid $black;
        top: 9px;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
    }

    span {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $gray-2;
        position: relative;
        padding: 8px;
    }
}