.briefing-tab {
  .briefing-tab-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 25px;
  }

  .error-text {
    color: $pink;
    font-size: 14px;
    margin-top: 15px;
  }

  .left-block {
    margin-right: 50px;
  }

  .left-block,
  .right-block {
    max-width: 595px;
    flex-grow: 1;
  }

  .content {
    border-radius: 6px;

    .modal-buttons > button,
    .input-field {
      border-radius: 6px;
      box-shadow: none;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    .deadline-title,
    .briefing-details-title,
    .outline-title,
    .participants-title,
    .documents-title {
      margin-bottom: 0;
      margin-right: 20px;
    }

    .edit-btn {
      margin-right: 6px;
      padding: 6px 14px;
      border: none;
      width: 55px;
      font-size: 13px;
      line-height: 20px;
      color: $gray-black;
      background-color: $gray-3;
      transition: all .3s;
      opacity: 1;
      cursor: pointer;
      border-radius: 6px;
    }

    .active {
      padding: 6px 14px;
      color: $black;
      background-color: $yellow-1;
      box-shadow: inset 0 -1px 0 0 $black;
      opacity: 1;
      box-shadow: none;
    }

    .cancel-btn {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .deadline-title,
  .documents-title,
  .participants-title,
  .outline-title {
    font-size: 22px;
    line-height: 2.73;
    color: $black;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .briefing-title,
  .briefing-description {
    font-size: 15px;
  }

  .briefing-title {
    color: $gray-black;
    width: 100%;
    flex-shrink: 0;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .briefing-description {
    color: $black;
    line-height: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow-wrap: anywhere;

    .summary-value {
      color: inherit;
      display: inline-block;
    }

    button {
      @include button();
      @extend .yellow-button;
      height: 38px;
      border-radius: 6px;
      box-shadow: none;
    }

    &.jcsb {
      button {
        border-radius: 6px;
        box-shadow: none;
      }
    }

    .hide-button {
      button {
        display: none;
      }
    }

    .day-picker-container {
      position: absolute;
      background-color: $white;
      z-index: 2;
      right: -199px;
      bottom: 0;
    }

    .DayPicker {
      font-size: 12px;
      -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    }

    .DayPicker-Day {
      padding: 3px 5px;
      line-height: 16px;
      cursor: pointer;
    }
  }

  .assign-new-copywriter {
    margin-left: 15px;
  }

  .border-top {
    border-top: 1px solid $gray-3;
  }

  .left-block {
    .briefing-title {
      margin-right: 25px;
      max-width: 163px;
    }
  }

  .right-block {
    .briefing-title {
      margin-right: 54px;
      max-width: 196px;
    }
  }

  .briefing-summary {
    border-bottom: 1px solid $gray-3;
    min-height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 0;

    &:hover {
      .summary-icon-files {
        visibility: visible;
      }
    }
  }

  .deadline-wrap,
  .documents-wrap,
  .participants-wrap {
    margin-bottom: 29px;
  }

  .documents-files-wrap {
    background-color: $gray-3;
    padding: 30px;
    border-radius: 6px;
  }

  .documents-files {
    background-color: $white;

    .project-icon {
      width: 20px;
      margin-right: 20px;

      path {
        fill: $green;
      }
    }

    .delete-icon,
    .cross-out {
      width: 10px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 56px;
      padding-left: 17px;
      border-bottom: 1px solid $gray-3;
    }
  }

  .delete-project,
  .change-project {
    padding: 0;
    background-color: transparent;
    width: 56px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .b-l-sm {
    border-left: 1px solid $gray-3;
  }

  .editing-field {
    border: none;
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border-radius: 0;
    margin-right: 10px;
    background-color: rgb(245, 245, 245);   
    border-radius: 6px;
  }

  .editing-field[disabled] {
    background-color: white;
    padding: 0;
    color: #000000;
    -webkit-text-fill-color: #000000;
  }

  .outline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pencil-icon {
      cursor: pointer;
      visibility: visible;
      width: 20px;
    }
  }

  .outline-description {
    .editing-field[disabled] {
      padding: 10px;
    }

    .edit-item {
      textarea {
        height: 250px;
        width: 565px;
      }

      a {
        position: unset;
      }
    }

    .quill {
      height: 250px;
      width: 565px;
    }

    .ql-tooltip {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ql-container {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .ql-toolbar {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .ql-action {
      position: inherit;
    }

    .ql-hidden {
      display: none;
    }
  }

  .edit-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 5px 0;
    overflow-wrap: anywhere;

    a {
      position: absolute;
      right: 40px;
      bottom: -10px;
      color: #6f6f6f;
      cursor: pointer;
    }

    pre {
      width: 100%;
      white-space: pre-line;
      line-height: 24px;
      font-size: 15px;
      padding: 2px 0;
      margin-right: 10px;
    }

    ol, ul {
      margin-left: 20px;
    }

    textarea {
      resize: none;
      height: 28px;
      line-height: 24px;
    }

    &:hover {
      .pencil-icon {
        visibility: visible;
      }
    }

    .pencil-icon {
      cursor: pointer;
      visibility: hidden;
      width: 20px;
    }
  }

  .editable {
    textarea {
      height: max-content;
    }
  }

  .briefing-buttons {
    display: flex;
    margin-top: 25px;
    align-items: center;

    .save-btn,
    .remove-btn {
      &:first-child {
        margin-right: 10px;
        border-radius: 6px;
      }
    }

    .save-btn {
      margin-right: 5px;
      @include button();
      @extend .yellow-button;
    }

    .finish-btn {
      margin-right: 10px;
      @include button();
      @extend .yellow-button;
    }

    .remove-btn {
      @include button();
      @extend .orange-button;
    }
  }
}

.documents-files-wrap {
  .summary-value-files {
    max-width: 350px;
    display: flex;
    align-items: center;

    svg {
      min-width: 20px;
    }
  }

  .project-link {
    color: $black;
    font-size: 15px;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.briefing-details-wap {
  .summary-value-files {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    a {
      color: $black;
      text-decoration: none;
    }
  }

  .warning-message {
    margin-top: 0;
    margin-left: auto;
  }
}